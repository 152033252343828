import jwt from "jwt-decode";
import { isUserAuthenticated, getUserToken } from "./auth_helper";
const getHeaders = () => {
  let headers = {
    "Content-Type": "application/json",
  };
  if (isUserAuthenticated) {
    const token = getUserToken();
    let jwtDecodeToken = jwt(token);
    headers["Authorization"] = "Bearer " + token;
    if (process.env.REACT_APP_CLIENT === "3_BAXTER") {
      localStorage.setItem("tenants", JSON.stringify(jwtDecodeToken.tenants));
      headers["tenantId"] = localStorage.getItem("AppTenantId");
    }
    console.log(jwtDecodeToken.tenants, "tenant");
  }

  return headers;
};
export { getHeaders };
