import React from "react";
// import {
//   Map,
//   InfoWindow,
//   Polyline,
//   Marker,
//   GoogleApiWrapper,
// } from "google-maps-react";

import { fetchLocationById, fetchTripById } from "../store/trip/actions";
import { connect } from "react-redux";

import { SecondaryProcessMapLocations } from "./SecondaryTrackerProcessor";
import { SecondaryMapCore } from "./SecondaryMapsNew/SecondaryMapCore";
import { SecondaryHereMapCore } from "./SecondaryMapsNew/SecondaryHereMapCore";
const googleMapType = process.env.REACT_APP_MAPS_GOOGLE;
var darkMode;

export class SecondaryTrackingMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentLocation: {},
      coordinateArray: [],
      sourceCoordinates: {},
      destinationCoordinates: {},
      mapsId: "",
      resetProp: false,
      destinationList: [],
      directions: null,
      subStatus: "",
      delayedStatus: "",
      latestKnownLocation: "",
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.id === "RouteOptimization") {
      if (this.props.resetFlag) {
        this.setState(
          {
            sourceCoordinates: {},
            destinationList: [],
          },
          () => {
            this.props.changeResetFlag();
          }
        );
      }
      if (this.props.srcChange) {
        if (this.props.source.srcLat !== this.state.sourceCoordinates?.lat)
          this.getAllData();
      }
      if (this.props.destChange) {
        if (
          this.props.destinationList?.length !==
          this.state.destinationList.length
        ) {
          this.getAllData();
        }
      }
    } else {
      if (prevProps.id !== this.props.id) {
        this.getAllData();
      }
    }
  }
  componentDidMount = () => {
    this.getAllData();
  };
  getAllData = () => {
    if (this.props.id === "RouteOptimization") {
      console.log(this.props);
      let data = this.props;
      let source = {},
        destinationList = [];
      if (data.source.srcLat !== "" && data.source.srcLng !== "") {
        source = {
          lat: data.source.srcLat,
          lng: data.source.srcLng,
        };
      }
      if (data.destinationList) {
        destinationList = data.destinationList.map((item) => {
          return {
            lat: item.destLat,
            lng: item.destLng,
          };
        });
      }

      this.setState(
        {
          sourceCoordinates: source,
          destinationList: destinationList,
        },
        () => {}
      );
    } else {
      this.setState(
        {
          mapsId: this.props.id,
        },
        () => {
          if (this.props.id !== undefined) {
            let source;
            let destination;
            this.props.fetchTripById(this.props.id, (res) => {
              let data = res.data[0];
              console.log(res);
              this.setState(
                {
                  sourceCoordinates: {
                    coordinates: {
                      lat: parseFloat(data.source.location.latitude),
                      lng: parseFloat(data.source.location.longitude),
                    },
                    sourceDescription: data?.source?.label?.includes(
                      data?.source?.hopCode
                    )
                      ? data?.source?.label
                      : `${data?.source?.label}-${data?.source?.hopCode}`,
                  },
                  destinationList: [
                    data.customers.map((item) => {
                      return {
                        coordinates: {
                          lat: parseFloat(item.source.location.latitude),
                          lng: parseFloat(item.source.location.longitude),
                        },
                        destinationDescription: item?.customerName,
                      };
                    }),
                  ],
                  subStatus: res?.data[0]?.subStatus,
                  delayedStatus: res?.data[0]?.delayedStatus,
                  currentLocation: res?.data[0]?.latestKnownLocation
                    ? {
                        lat: res?.data[0]?.latestKnownLocation?.coordinates
                          ?.lng,
                        lng: res?.data[0]?.latestKnownLocation?.coordinates
                          ?.lat,
                      }
                    : {},
                  latestKnownLocation:
                    res?.data[0]?.latestKnownLocation?.locationDescription ??
                    "",
                },
                () => {
                  console.log(this.state.destinationCoordinates);
                }
              );
            });

            this.props.fetchLocationById(this.props.id, (res) => {
              console.log(this.props.id);
              console.log(res);
              if (res.data.length) {
                let locationObject = SecondaryProcessMapLocations(res);
                let coordArray = [];
                locationObject.coordinateArray.map((item, index) => {
                  coordArray.push({
                    lat: parseFloat(item.lng),
                    lng: parseFloat(item.lat),
                  });
                  return true;
                });
                this.setState(
                  {
                    coordinateArray: [coordArray],
                  },
                  () => {
                    this.props.handleVehicleHistory &&
                      this.props.handleVehicleHistory(
                        locationObject.recentLocations
                      );
                  }
                );
              } else {
              }
            });
          } else {
            this.setState({
              sourceCoordinates: {},
              destinationCoordinates: {},
              coordinateArray: [],
              currentLocation: {},
              subStatus: "",
              delayedStatus: "",
              latestKnownLocation: "",
            });
          }
        }
      );
    }
  };
  render() {
    console.log(this.props.source);
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    let vehicleCoordinate = [];
    vehicleCoordinate = this.props.vehicleCoordinates;
    // if (this.state.resetProp !== this.props.resetProp) {
    //   this.setState({ resetProp: this.props.resetProp });
    // }

    let coordinateOrder = [];
    if (
      Array.isArray(this.props.tripTrackId) &&
      this.props?.tripTrackId?.length > 0
    ) {
      const coordinateOrders = this.props.tripTrackId[0]?.map((test) => {
        return test;
      });
      coordinateOrder.push(coordinateOrders);
    }

    return (
      <div>
        {console.log(this.state.directions)}
        {googleMapType === "true" ? (
          <SecondaryMapCore
            mode={darkMode}
            sourceCoordinates={this.state.sourceCoordinates}
            destinationCoordinates={this.state.destinationCoordinates}
            coordinateArray={this.state.coordinateArray}
            currentLocation={this.state.currentLocation}
            latestKnownLocation={this.state.latestKnownLocation}
            destinationList={this.state.destinationList[0]}
            directions={this.state.directions}
            lineCoordinates={this.props.lineCoordinates}
            subStatus={this.state.subStatus}
            delayedStatus={this.state.delayedStatus}
            vehicleCoordinate={vehicleCoordinate}
            sourceCoordinatesOrder={this.props.sourceCoordinatesOrder}
            destinationCoordinatesOrder={this.props.destinationCoordinates}
            currentLocationOrder={
              this.props.currentLocation?.length > 0
                ? this.props.currentLocation
                : ""
            }
            singleFetchTrips={this.props.singleFetchTrips}
            coordinateArrayOrder={
              this.props.coordArr?.length > 0 ? this.props.coordArr : ""
            }
          />
        ) : (
          <SecondaryHereMapCore
            mode={darkMode}
            sourceCoordinates={this.state.sourceCoordinates}
            destinationCoordinates={this.state.destinationCoordinates}
            coordinateArray={this.state.coordinateArray}
            currentLocation={this.state.currentLocation}
            destinationList={this.state.destinationList}
            directions={this.state.directions}
            lineCoordinates={this.props.lineCoordinates}
            subStatus={this.state.subStatus}
            delayedStatus={this.state.delayedStatus}
            vehicleCoordinate={vehicleCoordinate}
            sourceCoordinatesOrder={this.props.sourceCoordinatesOrder}
            destinationCoordinatesOrder={this.props.destinationCoordinates}
            currentLocationOrder={
              this.props.currentLocation?.length > 0
                ? this.props.currentLocation
                : ""
            }
            coordinateArrayOrder={
              this.props.coordArr?.length > 0 ? this.props.coordArr : ""
            }
            singleFetchTrips={this.props.singleFetchTrips}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.Trip.trips.trips,
  };
};

export default connect(mapStateToProps, {
  fetchLocationById,
  fetchTripById,
})(SecondaryTrackingMap);
