import { types } from "../actionTypes";
const initialState = {
  secondaryTripFetchId: "",
  secondaryTrips: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TRIPS_SECONDARY_SUCCESS:
      return {
        ...state,
        secondaryTrips: action.payload,
        secondaryTripFetchId: "id" + new Date().getTime(),
      };

    case types.CHANGE_ARRIVEDATSOURCE_STATUS_SECONDARY:
      // Copy the current state and delete the property with the specified key
      return { ...state, arrive: action.payload };

    case types.CHANGE_MARKASARRIVED_INTRANSIT_STATUS_SECONDARY:
      return { ...state, arriveAtCustomer: action.payload };

    case types.SET_TRIP_CUSTOMER_DROPDOWN_SUCCESS:
      const trips = state.secondaryTrips.data.docs.map((e) => {
        return { ...e, isDropdownOpen: false };
      });

      trips[action.payload].isDropdownOpen =
        !state.secondaryTrips.data.docs[action.payload].isDropdownOpen;
      return {
        ...state,
        secondaryTrips: {
          ...state.secondaryTrips,
          data: {
            ...state.secondaryTrips.data,
            docs: trips,
          },
        },
      };

    case types.SET_TRIP_CUSTOMER_DROPDOWN_FALSE_SUCCESS:
      const tripsFalse = state?.secondaryTrips?.data?.docs?.map((e) => {
        return { ...e, isDropdownOpen: false };
      });

      return {
        ...state,
        secondaryTrips: {
          ...state.secondaryTrips,
          data: {
            ...state.secondaryTrips.data,
            docs: tripsFalse,
          },
        },
      };

    case types.TRIP_QUALITY_CHECK_SECONDARY_SUCCESS:
      return { ...state, quality: action.payload };

    case types.SUBSCRIBE_CONSENT_SECONDARY_SUCCESS:
      console.log(action.payload, "DRIVER PAYLOAD");
      if (action.payload?.data?.data?.status === "ALLOWED")
        return { ...state, consentStatus: true };
      else return { ...state, consentStatus: false };

    case types.SHIPMENT_VERIFY_SECONDARY_SUCCESS:
      return { ...state, verify: action.payload };

    case types.ADD_SHIPMENT_VERIFY_SECONDARY_SUCCESS:
      return { ...state, verifyData: action.payload };

    case types.FINISH_LOADING_SECONDARY_SUCCESS: {
      const { tripID } = action.payload;
      //consolelog(state.trips);
      const trips = [...state.trips?.data?.docs];
      const trip = trips.find((e) => e._id === tripID);
      trip.subStatus = "in_transit";

      return {
        ...state,
        trips: { ...state.trips, data: { ...state.trips.data, docs: trips } },
      };
    }

    default:
      state = { ...state };
      break;
  }
  return state;
};
