import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import SecondaryDriverDetails from "./SecondaryDriverDetails";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class SecondaryDriverDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      buttonContent: "",
      chevronVehicle: "down",
      chevronDriver: "down",
      vehicleDropDown: false,
      driverDropDown: false,
    };
  }

  vehicleDropDownChange = () => {
    let direction = "";
    if (this.state.chevronVehicle === "down") {
      direction = "up";
    } else {
      direction = "down";
    }
    this.setState({
      vehicleDropDown: !this.state.vehicleDropDown,
      chevronVehicle: direction,
    });
  };
  driverDropDownChange = () => {
    let direction = "";
    if (this.state.chevronDriver === "down") {
      direction = "up";
    } else {
      direction = "down";
    }
    this.setState({
      driverDropDown: !this.state.driverDropDown,
      chevronDriver: direction,
    });
  };
  handleSubmit = () => {
    // this.setState({ submitDisable: true, buttonContent: "Processing..." });
    // let finalValues = {
    //   indent: this.props.indentIndex,
    //   vehicle: this.props.vehicle.value,
    //   driver: this.props.driver.value,
    // };
    // this.props.finalizeIndent({
    //   values: finalValues,
    //   callback: (res) => {
    //     if (res.success) {
    //       this.props.refreshData();
    //       this.props.toggle();
    //       NotificationManager.success("Finalized Successfully", "Success");
    //     } else {
    //       NotificationManager.error("Please Check the Data Again", "Error");
    //     }
    //     this.setState({
    //       isSubmitting: !this.state.isSubmitting,
    //       buttonContent: "Submit",
    //     });
    //   },
    // });
  };

  render() {
    // console.log("this.props.optionsdriver",this.props.optionsdriver)
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    var darkMode = this.props?.mode;
    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "transparent" : "#8e9af8",
          width: "54px",
          height: "37px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "0px 4px 4px 0px",
        };
      },
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      control: (style) => {
        return {
          ...style,
          height: "50px",
        };
      },
    };
    return (
      <React.Fragment>
        <MDBContainer className="text-capitalize">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggle()}
            centered
            className="TranspoterModal"
          >
            <i
              className="fas fa-times closemodal p-3 closeDark"
              onClick={() => this.props.toggle()}
            ></i>
            <MDBModalHeader className="modal-head">
              {userLang?.tracking?.SELECT_DRIVER}
            </MDBModalHeader>
            <MDBModalBody>
              <Row>
                <Col lg="12">
                  <FormGroup className="select2-container darkReportIncident">
                    <Label className="control-label light__span">
                      {" "}
                      {userLang?.tracking?.DRIVER_NAME}
                    </Label>
                    <CreatableSelect
                      value={this.props.driver}
                      onInputChange={this.props.filterDriverList}
                      styles={customStyles}
                      isMulti={false}
                      onChange={(e) => {
                        this.props.onChange({
                          target: {
                            name: "driver",
                            value: e,
                          },
                        });
                      }}
                      options={this.props.optionsdriver}
                      classNamePrefix="select2-selection"
                    />
                    {this.props?.driverMsgErr?.driver && (
                      <div className="error">
                        {this.props.driverMsgErr?.driver}{" "}
                      </div>
                    )}
                  </FormGroup>

                  <div className="AddNewSpan addNewBg">
                    <Row>
                      <Col xs={11}>
                        <span
                          className="textHead ptL10 "
                          style={{
                            color: "#2800FC",
                          }}
                        >
                          {userLang?.common?.ADD_NEW}{" "}
                        </span>
                      </Col>
                      <Col xs={1}>
                        <i
                          className={`fa fa-chevron-${this.state.chevronDriver} bt AddNewSpanIcon actionDspan`}
                          onClick={() => this.driverDropDownChange()}
                        ></i>
                      </Col>
                    </Row>
                  </div>
                  {this.state.driverDropDown ? (
                    <SecondaryDriverDetails
                      submitDriverForm={this.props.submitDriverForm}
                      onDriverChange={this.props.onDriverChange}
                      driverForm={this.props.driverForm}
                      isPhoneNoValid={this.props.isPhoneNoValid}
                      mode={darkMode}
                    /> // <Col lg="12" className="jumbo">
                  ) : //   <Row>
                  //     <Col lg="6">
                  //       <FormGroup>
                  //         <Label className="control-label">
                  //           Full Name <span className="req">*</span>
                  //         </Label>
                  //         <Input
                  //           name="fullName"
                  //           placeholder="Type here..."
                  //         //   onChange={(e) =>
                  //         //     this.props.onDriverChange({
                  //         //       target: {
                  //         //         name: "fullName",
                  //         //         value: e.target.value,
                  //         //       },
                  //         //     })
                  //         //   }
                  //         />
                  //       </FormGroup>
                  //     </Col>
                  //     <Col lg="6">
                  //       <FormGroup>
                  //         <Label className="control-label">
                  //           phone <span className="req">*</span>
                  //         </Label>
                  //         <Input
                  //           name="phone"
                  //           placeholder="Type here..."
                  //         //   onChange={(e) =>
                  //         //     this.props.onDriverChange({
                  //         //       target: {
                  //         //         name: "phone",
                  //         //         value: e.target.value,
                  //         //       },
                  //         //     })
                  //         //   }
                  //         />
                  //       </FormGroup>
                  //     </Col>
                  //   </Row>
                  //   <Row>
                  //     <Col lg="6">
                  //       <FormGroup>
                  //         <Label className="control-label">
                  //           Driver License No <span className="req">*</span>
                  //         </Label>
                  //         <Input
                  //           name="driverLicenseNo"
                  //           placeholder="Type here..."
                  //         //   onChange={(e) =>
                  //         //     this.props.onDriverChange({
                  //         //       target: {
                  //         //         name: "driverLicenseNo",
                  //         //         value: e.target.value,
                  //         //       },
                  //         //     })
                  //         //   }
                  //         />
                  //       </FormGroup>
                  //     </Col>
                  //     <Col lg="6">
                  //       <FormGroup>
                  //         <Button
                  //           color="primary"
                  //           className="jumboButton"
                  //         //   onClick={() => this.props.submitDriverForm()}
                  //         //   disabled={this.props.disabled}
                  //         >
                  //           {" "}
                  //           save
                  //         </Button>
                  //       </FormGroup>
                  //     </Col>
                  //   </Row>
                  // </Col>
                  null}
                </Col>
              </Row>
              {/* <Row>
               <Col lg="12">
                          <FormGroup>
                            <Label className="control-label">
                              Contact No <span className="req">*</span>
                            </Label>
                            <Input
                              name="fullName"
                              placeholder="Type here..."
                              style={{backgroundColor:"#F1F5F7"}}
                            //   onChange={(e) =>
                            //     this.props.onDriverChange({
                            //       target: {
                            //         name: "fullName",
                            //         value: e.target.value,
                            //       },
                            //     })
                            //   }
                            />
                          </FormGroup>
                        </Col>
               </Row> */}
            </MDBModalBody>
            <Row
              style={{
                position: "relative",
                left: "17px",
                paddingTop: "10px",
              }}
            >
              <Col xs="3">
                <Button
                  className="fontRoboto1541"
                  style={{
                    width: "100%",
                    height: "50px",
                    backgroundColor: "#0AB38C",
                    color: "#FFFFFF",
                  }}
                  color="secondary"
                  onClick={() => this.props.handleResetEdit()}
                >
                  {userLang?.common?.RESET}
                </Button>
              </Col>
              <Col xs="3">
                <Button
                  className="fontRoboto1541"
                  style={{
                    width: "100%",
                    height: "50px",
                    backgroundColor: this.props.mode ? "#5E40FF" : "#05284E",
                    color: "#FFFFFF",
                  }}
                  color="primary"
                  onClick={this.props.handleSubmitUpdateDriver}
                  disabled={this.state.isSubmitting}
                >
                  {this.state.buttonContent.length > 0
                    ? this.state.buttonContent
                    : userLang?.common?.SUBMIT}
                </Button>
              </Col>
              <Col xs="6"></Col>
            </Row>
            {/* <MDBModalFooter></MDBModalFooter> */}
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

export default SecondaryDriverDetailsModal;
