import React, { Component } from "react";
import { Card, Grid } from "@material-ui/core";
import { Row, Col } from "reactstrap";
import { CloseIcon } from "../components/Common/CommonIcons";
import "./indentData.css";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class IndentData extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      role: "",
    };
  }
  componentDidMount = () => {
    let data = JSON.parse(window.localStorage.getItem("authUser"));
    let role = data.data.role.label;
    this.setState({ role: role });
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const modalStyle = {
      // height: "674px",
      width: "100%",
      backgroundColor: "#ffffff",
      // padding: "25px",
      paddingLeft: "25px",
      paddingTop: "40px",
      position: "relative",
    };
    const headerStyle = {
      // position: "relative",
      fontFamily: "Roboto",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "23px",
      color: this.props.mode ? "#9D9D9D" : "#0C0C0C",
      // left: "10px",
    };

    const rowsTopSpaceStyle = { marginTop: "40px" };
    const dataBoxStyle = {
      height: "auto",
      // overflowY: "scroll",
      // overflowX: "clip",
    };

    const oddRowStyle = {
      minHeight: "57px",
      width: "95%",
      backgroundColor: this.props.mode ? "#24243C" : "#ffffff",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",
      //position:'relative',
    };
    const evenRowStyle = {
      minHeight: "57px",
      width: "95%",
      backgroundColor: this.props.mode ? "#2E2E46" : "#F1F5F7",
      border: "0.5px solid rgba(185, 185, 185, .5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",
    };
    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: this.props.mode ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "2.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "100%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: this.props.mode ? "#FFFFFF" : "#0C0C0C",
      margin: "auto",
    };

    let { data, formatDate, routeConvert, indentType } = this.props;
    console.log("reach 1", data);
    let indentID = data?.indentId ? data?.indentId : "";
    let createdBy = data?.createdBy?.name ? data.createdBy.name : "";
    let updatedBy = data?.updatedBy?.name ? data.updatedBy.name : "";
    let source = data?.source?.label ? data.source?.label : "N/A";
    let destination = data?.destination?.label
      ? data.destination?.label
      : "N/A";
    let customers =
      data?.customers?.length > 0
        ? data.customers.map((item, index) => (
            <div key={index}>
              {item.customerName} - {item.customerNumber} - {item.shipToCode},
            </div>
          ))
        : "N/A";
    let vehicle = data?.vehicle?.type?.label;
    let transporter = data?.transporter?.name ? data.transporter?.name : "N/A";
    let route = data?.route?.via?.length
      ? routeConvert(data?.route?.via)
      : "DIRECT";
    let description = data?.description ? data.description : "N/A";
    let startTime = data?.startTime ? formatDate(data.startTime) : "N/A";
    let expiryTime = data?.expiryTime ? formatDate(data.expiryTime) : "N/A";
    let status = data?.status;
    let rejectionHistory = data?.rejectionHistory || [];
    let driver = data?.driver
      ? data.driver.name + "(" + data.driver.phone + ")"
      : "";
    let vehicleNo = data?.vehicle?.regNo;
    return (
      <>
        <Card style={modalStyle} className="IndentDetailModal">
          <Row
            style={{
              position: "relative",
              left: "24px",
            }}
          >
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={headerStyle}>
                  {userLang?.dashboard?.INDENT_DETAILS}
                </span>

                <i
                  className="closeIcon closeDark"
                  onClick={() => {
                    this.props.closeModal();
                  }}
                >
                  <CloseIcon color={this.props.mode ? "#B7A9FF" : "#2800fc"} />
                </i>
              </div>
            </Col>
            <Col xs={12}>
              <div
                className="fontRoboto1541 pt-2 "
                style={{
                  color: "#05284E",
                }}
              >
                <div className="dark__span">
                  {userLang?.planning?.INDENT_ID} :{indentID}
                </div>
              </div>
            </Col>
          </Row>

          <Grid style={rowsTopSpaceStyle}></Grid>
          <div style={dataBoxStyle}>
            <div
              // style={{ marginRight: "2vw" }}
              style={{
                marginRight: "2vw",
                height: "400px",
                overflowY: "scroll",
                paddingBottom: "22px",
              }}
            >
              <Grid
                className="shadow indentData-hover"
                container
                style={oddRowStyle}
              >
                <Grid
                  // className=""
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.planning?.INDENT_ID}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {indentID}
                </Grid>
              </Grid>

              <Grid
                className="  shadow indentData-hover"
                container
                style={evenRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.common?.SOURCE}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {source}
                </Grid>
              </Grid>

              {indentType === "primary" && (
                <Grid
                  className="shadow indentData-hover"
                  container
                  style={oddRowStyle}
                >
                  <Grid
                    item
                    xs={3}
                    md={6}
                    style={rowHeaderStyle}
                    className="MobilefontRoboto127"
                  >
                    {userLang?.common?.DESTINATION}:
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      padding: "2vh 0px",
                    }}
                  >
                    <div style={rowSeparaterStyle}></div>
                  </Grid>

                  <Grid
                    item
                    xs={5}
                    md={5}
                    style={rowValueStyle}
                    className="MobilefontRoboto124"
                  >
                    {destination}
                  </Grid>
                </Grid>
              )}

              {indentType === "secondary" && (
                <Grid
                  className="shadow indentData-hover"
                  container
                  style={oddRowStyle}
                >
                  <Grid
                    item
                    xs={3}
                    md={6}
                    style={rowHeaderStyle}
                    className="MobilefontRoboto127"
                  >
                    {userLang?.common?.CUSTOMERS}:
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      padding: "2vh 0px",
                    }}
                  >
                    <div style={rowSeparaterStyle}></div>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={5}
                    style={rowValueStyle}
                    className="MobilefontRoboto124"
                  >
                    {customers}
                  </Grid>
                </Grid>
              )}

              <Grid
                className="shadow indentData-hover"
                container
                style={evenRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.common?.ROUTE}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {route}
                </Grid>
              </Grid>
              <Grid
                className="shadow indentData-hover"
                container
                style={oddRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.common?.TRANSPORTER}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {transporter}
                </Grid>
              </Grid>
              <Grid
                className="shadow indentData-hover"
                container
                style={evenRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.common?.VEHICLE_TYPE}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {vehicle}
                </Grid>
              </Grid>
              <Grid
                className="shadow indentData-hover"
                container
                style={oddRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.planning?.VEHICLE_REG}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {vehicleNo}
                </Grid>
              </Grid>
              <Grid
                className="shadow indentData-hover"
                container
                style={evenRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.planning?.CREATED_BY}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {createdBy}
                </Grid>
              </Grid>
              <Grid
                className="shadow indentData-hover"
                container
                style={oddRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.planning?.UPDATED_BY}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {updatedBy}
                </Grid>
              </Grid>
              <Grid
                className="shadow indentData-hover"
                container
                style={evenRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.trip?.DRIVER}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {driver}
                </Grid>
              </Grid>
              <Grid
                className="shadow indentData-hover"
                container
                style={oddRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.tracking?.DESCRIPTION}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {description}
                </Grid>
              </Grid>
              <Grid
                className="shadow indentData-hover"
                container
                style={evenRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.common?.START_TIME}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {startTime}
                </Grid>
              </Grid>
              <Grid
                className="shadow indentData-hover"
                container
                style={oddRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.common?.EXPIRY_TIME}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {expiryTime}
                </Grid>
              </Grid>
              <Grid
                className="shadow indentData-hover"
                container
                style={evenRowStyle}
              >
                <Grid
                  item
                  xs={3}
                  md={6}
                  style={rowHeaderStyle}
                  className="MobilefontRoboto127"
                >
                  {userLang?.common?.STATUS}:
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    padding: "2vh 0px",
                  }}
                >
                  <div style={rowSeparaterStyle}></div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {status}
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid
                className={`shadow indentData-hover pad rejectionHistoryBox ${
                  this.props.mode
                    ? "darkIndentModalRejection"
                    : "rejection-history"
                }`}
                container
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "15px",
                    color: this.props.mode ? "#9D9D9D" : "#05284E",
                    lineHeight: "18px",
                    margin: "auto",
                  }}
                  className="pdL"
                >
                  {userLang?.planning?.REJECTION_HISTORY}:
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  style={rowValueStyle}
                  className="MobilefontRoboto124"
                >
                  {rejectionHistory.map((rejection, i) => (
                    <div
                      key={i}
                      style={{ paddingTop: "1vh", paddingLeft: "30px" }}
                    >
                      <h6
                        style={{
                          color: this.props.mode ? "#ffffff" : "#343a40",
                        }}
                      >
                        {rejection.transporter.name}
                      </h6>
                      <p>
                        <span>
                          <b>{userLang?.planning?.REASON}:</b>
                          {rejection.reason}
                        </span>
                      </p>
                      <p>
                        <span>
                          <b>Comments:</b>
                          {rejection.remark}
                        </span>
                      </p>
                      <p>{formatDate(rejection.time)}</p>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </div>
          </div>
        </Card>
      </>
    );
  }
}

export default IndentData;
