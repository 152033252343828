import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import exportIcon from "../Trips/assets/external-link.png";
import exportDarkIcon from "../Trips/assets/external-linkDark.png";
import plusIcon from "./assets/plus.png";
import plusDarkIcon from "../Trips/assets/plusDark.png";
import refreshIcon from "./assets/refresh-cw.png";
import { Grid } from "@material-ui/core";
import { InputGroup } from "rsuite";
import Datepicker from "../../components/Common/DatePicker/DatePicker";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
      fromDate: null,
      toDate: null,
    };
  }
  handleClickAway = () => {
    if (this.state.showCalender === true) {
      this.setState({ showCalender: false });
    }
  };
  handleOpen = () => {
    this.setState({ showCalender: true });
  };
  handleClose = () => {
    this.setState({ showCalender: false });
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  onChangeDatePicker = (value, label) => {
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.quickFilters("date", [
            this.state.fromDate,
            this.state.toDate,
          ]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.quickFilters("date", [
            this.state.fromDate,
            this.state.toDate,
          ]);
        }
      });
    }
  };
  render() {
    darkMode = this.props?.mode;
    return (
      <React.Fragment>
        <Card className={`${darkMode ? "darkBgQuickFilter mb-2" : "mb-2"}`}>
          <div
            className={`quickStylesBar indent-quick-filters ${
              darkMode ? "darkBgQuickFilter" : ""
            }`}
          >
            <Row>
              <Col xs={8} sm={10} md={9}>
                <Button
                  onClick={() => this.props.quickFilters("rejected")}
                  color=""
                  className={
                    darkMode
                      ? `${
                          this.props.activeState === true
                            ? "activeDarkStyle mr-2"
                            : "delayDarkStyle mr-2"
                        }`
                      : `${
                          this.props.activeState === true
                            ? "activeStyle mr-2"
                            : "delayStyle mr-2"
                        }`
                  }
                >
                  {this.state.userLangs?.common.REJECTED}
                </Button>
                <Button
                  className="desktop-reset reset"
                  active="true"
                  onClick={() => {
                    this.setState(
                      {
                        dateValue: [null, null],
                        fromDate: null,
                        toDate: null,
                      },
                      () => {
                        this.props.reset();
                      }
                    );
                  }}
                >
                  {this.state.userLangs?.common.RESET}
                </Button>
                <Button
                  className="mobile-reset reset"
                  type="button"
                  onClick={() =>
                    this.setState({ dateValue: [null, null],  fromDate: null,
                      toDate: null}, () => {
                      this.props.reset();
                    })
                  }
                >
                  <img
                    className="mobile-rest-img"
                    src={refreshIcon}
                    alt=""
                  ></img>
                </Button>
              </Col>
              <Col
                xs={4}
                sm={2}
                md={3}
                // style={{ textAlignLast: "end" }}
                className="indentMobileHeader"
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    className="datePickerGrid"
                  >
                    <div
                      className="date-picker-div RsuiteDateRange dashboardDateRange"
                      style={{
                        width: "100%",
                        marginLeft:"20px"
                      }}
                    >
                      <div className="datepicker-selector">
                        <InputGroup>
                          <Datepicker
                            mode={darkMode}
                            value={this.state.fromDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            placeholder={"Start Date"}
                            dashboard={true}
                          />
                          <Datepicker
                            mode={darkMode}
                            value={this.state.toDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            placeholder={"End Date"}
                            dashboard={true}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <div style={{marginLeft:"22px"}}>
                      <div className="tooltipIcon">
                        <Button
                         outline
                         color=""
                          className={`iconButtons ${
                            darkMode ? "iconDarkButtons" : ""
                          }`}
                          onClick={() => this.props?.onClickAddIndent()}
                        >
                          <img
                            style={{ margin: "-4px" }}
                            src={darkMode ? plusDarkIcon : plusIcon}
                            alt="exp"
                          />
                        </Button>
                        <span
                          className="tooltipIcontext"
                          style={{ width: "80px" }}
                        >
                          &nbsp;{this.state.userLangs?.planning?.ADD_INDENT}
                          &nbsp;
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs>
                  <div>
                  <div>

</div>
                  <div className="tooltipIcon">
                      <Button
                        outline
                        color=""
                        disabled={!navigator.onLine}
                        onClick={() => this.props.toggleExcel()}
                        className={`iconButtons ${
                          darkMode ? "iconDarkButtons" : ""
                        }`}
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={darkMode ? exportDarkIcon : exportIcon}
                          alt="exp"
                        />
                      </Button>
                      <span
                        className="tooltipIcontext"
                        style={{ left:'10%' }}
                      >
                        &nbsp;{this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ?? 'Download Excel'}&nbsp;
                      </span>
                    </div>
                  </div>
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
