import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { Button } from "reactstrap";
import InvoiceList from "../InvoiceList/InvoiceList";

class AddInvoiceBillsToDraft extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <MDBContainer fluid={true} className="invoice addInvoice">
          <MDBModal
            isOpen={this.props.addBillOpen}
            toggle={() => {
              this.props.handleAddBillclose();
            }}
            centered={false}
            size="fluid"
            className={` ${
              this.props.mode
                ? "invoiceDarkMode modal-fullscreen"
                : "modal-fullscreen"
            }`}
          >
            <i
              className="fas fa-times closemodal2 p-3 close"
              onClick={() => {
                this.props.handleAddBillclose();
              }}
            ></i>

            <MDBModalHeader
              toggle={this.toggle}
              className={`${this.props.mode ? "headersModalColor" : ""}`}
            >
              Invoice Draft Details
            </MDBModalHeader>
            <MDBModalBody>
              <InvoiceList
                name="addInvoice"
                handleAddBillOpen={this.props.handleAddBillOpen}
                handleAddBillclose={this.props.handleAddBillclose}
                billsArrayForAdd={this.props.billsArrayForAdd}
                getAllDataInvoiceGen={this.props.getAllDataInvoiceGen}
              />
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

export default AddInvoiceBillsToDraft;
