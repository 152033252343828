import React from "react";

class BaxterEnv extends React.Component {
  render() {
    // console.log(process.env.ENVIRONMENT,"env")
    if (
      process.env.REACT_APP_REDIRECT_URL === "https://baxteruat.spinciq.com"
    ) {
      return <div className="bold-red">User Acceptance Testing (UAT)</div>;
    } else if (
      process.env.REACT_APP_REDIRECT_URL === "https://baxter.spinciq.com/"
    ) {
      return <div className="bold-red">PRODUCTION</div>;
    } else if (
      process.env.REACT_APP_REDIRECT_URL === "http://3.108.69.106:4004/"
    ) {
      return (
        <div className="bold-red" style={{ marginTop: "7px" }}>
          DEV
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default BaxterEnv;
