import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { Row, Col, FormGroup, Button } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { IndentStatusReject } from "../store/indent/actions";
import { SecondaryIndentStatusReject } from "../store/secondaryIndentPlanning/actions";
import { connect } from "react-redux";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class RejectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      remark: "",
      confirm: false,
      isSubmitting: false,
      buttonContent: "",
      submitDisable: true,
    };
  }
  handleReset = () => {
    this.setState({
      submitDisable: true,
      remark: "",
      reason: "",
    });
  };
  handleSubmit = () => {
    this.setState({ confirm: !this.state.confirm });
  };

  handleConfirm = () => {
    this.setState({
      submitDisable: true,
      buttonContent: userLang?.planning?.PROCESSING ?? "Processing",
    });
    let values = {
      indentID: this.props.indentIndex,
      reason: this.state.reason,
      remark: this.state.remark,
    };
    if (this.props.indentType === "Primary") {
      this.props.IndentStatusReject({
        values,
        callback: (res) => {
          this.setState({
            submitDisable: false,
            buttonContent: userLang?.common?.OK ?? "Ok",
          });
          if (res.success) {
            NotificationManager.success("Rejected Successfully", "Success");
            this.props.refreshData();
            this.closeToogle();
            this.history.push("/indent-planning");
          } else {
            NotificationManager.error(res["message"], "Error");
            this.setState(
              {
                confirm: !this.state.confirm,
                isSubmitting: !this.state.isSubmitting,
              },
              () => {
                this.closeToogle();
              }
            );
          }
          this.setState({
            isSubmitting: !this.state.isSubmitting,
            buttonContent: userLang?.common?.OK ?? "Ok",
          });
        },
      });
    } else if (this.props.indentType === "Secondary") {
      this.props.SecondaryIndentStatusReject({
        values,
        callback: (res) => {
          this.setState({
            submitDisable: false,
            buttonContent: userLang?.common?.OK ?? "Ok",
          });
          if (res.success) {
            NotificationManager.success("Rejected Successfully", "Success");
            this.props.refreshData();
            this.closeToogle();
            this.history.push("/secondary-indent-planning");
          } else {
            NotificationManager.error(res["message"], "Error");
            this.setState(
              {
                confirm: !this.state.confirm,
                isSubmitting: !this.state.isSubmitting,
              },
              () => {
                this.closeToogle();
              }
            );
          }
          this.setState({
            isSubmitting: !this.state.isSubmitting,
            buttonContent: userLang?.common?.OK ?? "Ok",
          });
        },
      });
    }
  };
  handleChangeReason = (e) => {
    this.setState({ reason: e.target.value }, () => {
      if (this.state.remark.length > 0 && this.state.reason.length > 0) {
        this.setState({ submitDisable: false });
      } else {
        this.setState({ submitDisable: true });
      }
    });
  };
  handleChangeRemark = (e) => {
    this.setState({ remark: e.target.value }, () => {
      if (this.state.remark.length > 0 && this.state.reason.length > 0) {
        this.setState({ submitDisable: false });
      } else {
        this.setState({ submitDisable: true });
      }
    });
  };
  closeToogle = () => {
    this.setState({ confirm: false, isSubmitting: false }, () => {
      this.handleReset();
      this.props.toggle();
    });
  };

  render() {
    // console.log("this.props.indnet", this.props.indent_id)
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    return (
      <React.Fragment>
        <MDBContainer>
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggle()}
            centered
            className="RejectIndentModal modelDark"
          >
            <i
              className="closemodal  p-2 closeDark close"
              onClick={() => this.closeToogle()}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 1L1 13"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1L13 13"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </i>

            <MDBModalHeader
              toggle={this.toggle}
              style={{
                borderBottom: "0 none",
              }}
            >
              <div>
                <span className="modalTitle fontRoboto">
                  {userLang?.planning?.REJECT_INDENT}
                </span>
              </div>
              <div
                className="fontRoboto1541 pt-2"
                style={{
                  color: "#05284E",
                }}
              >
                <div className="dark__span">
                  {userLang?.planning?.INDENT_ID} :{this.props?.indent_id}
                </div>
              </div>
            </MDBModalHeader>
            <MDBModalBody>
              {this.state.confirm === true ? (
                <b className="text-danger">
                  {userLang?.planning?.REJECT_INDENT_WARNING}
                </b>
              ) : (
                <Row>
                  <Col lg="12 ">
                    <div className="radio-inline fontRoboto12">
                      <label className="mr-4">
                        <input
                          className="mr-2"
                          type="radio"
                          value="cost"
                          checked={this.state.reason === "cost"}
                          onChange={this.handleChangeReason}
                        />
                        <span className="light__span">
                          {userLang?.planning?.REJECT_INDENT_COST}
                        </span>
                      </label>

                      <label className="mr-4">
                        <input
                          className="mr-2"
                          type="radio"
                          value="route"
                          checked={this.state.reason === "route"}
                          onChange={this.handleChangeReason}
                        />
                        <span className="light__span">
                          {userLang?.planning?.REJECT_INDENT_ROUTE}
                        </span>
                      </label>

                      <label className="mr-4">
                        <input
                          className="mr-2"
                          type="radio"
                          value="vehicle Unavailability"
                          checked={
                            this.state.reason === "vehicle Unavailability"
                          }
                          onChange={this.handleChangeReason}
                        />
                        <span className="light__span">
                          {" "}
                          {
                            userLang?.planning
                              ?.REJECT_INDENT_VEHICLEUNAVAILABILITY
                          }
                        </span>
                      </label>
                    </div>
                  </Col>

                  <Col lg="12">
                    <FormGroup className="select2-container">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="fontRoboto15 light__span"
                        style={{ color: "#05284E", marginTop: "10px" }}
                      >
                        {userLang?.planning?.REJECT_INDENT_REMARK}
                      </label>
                      <textarea
                        className="form-control fontRoboto12 darkTexBox"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        placeholder="Type Reason Here"
                        value={this.state.remark ? this.state.remark : ""}
                        onChange={this.handleChangeRemark}
                      />
                    </FormGroup>
                    <div className="rejectModalWarning driverConsentDarkdiv">
                      <div>
                        <span style={{ padding: "6px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                              stroke="#E53D2C"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.5 10.1V7.5"
                              stroke="#E53D2C"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.5 4.90015H7.505"
                              stroke="#E53D2C"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span
                          className="fontRoboto12 dark__span"
                          style={{
                            color: "#0D172A !important",
                            padding: "3px",
                          }}
                        >
                          {userLang?.planning?.REJECT_INDENT_WARNING}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </MDBModalBody>
            {this.state.confirm === true ? (
              <MDBModalFooter className="modalFooterStyle">
                <Button
                  style={{
                    backgroundColor: "#0AB38C",
                    color: "white",
                    width: "20%",
                  }}
                  onClick={() => this.handleSubmit()}
                >
                  {userLang?.common?.BACK}
                </Button>
                <Button
                  style={{
                    backgroundColor: this.props.mode ? "#5E40FF" : "#05284E",
                    color: "white",
                    width: "20%",
                  }}
                  color="primary"
                  onClick={this.handleConfirm}
                  disabled={this.state.isSubmitting}
                >
                  {this.state.buttonContent.length > 0
                    ? this.state.buttonContent
                    : userLang?.common?.OK}
                </Button>
              </MDBModalFooter>
            ) : (
              <MDBModalFooter className="modalFooterStyle">
                <Button
                  style={{
                    backgroundColor: "#0AB38C",
                    color: "white",
                    width: "25%",
                    height: "50px",
                  }}
                  onClick={() => this.handleReset()}
                >
                  {userLang?.common?.RESET}
                </Button>
                <Button
                  style={{
                    backgroundColor: this.props.mode ? "#5E40FF" : "#05284E",
                    color: "white",
                    width: "25%",
                    height: "50px",
                  }}
                  color="primary"
                  onClick={this.handleSubmit}
                  disabled={this.state.submitDisable}
                >
                  {userLang?.common?.SUBMIT}
                </Button>
              </MDBModalFooter>
            )}
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    indents: state.Indent.indents.assignedIndents,
  };
};

export default connect(mapStateToProps, {
  IndentStatusReject,
  SecondaryIndentStatusReject,
})(RejectModal);
