let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  HeaderData = {
    columns: [
      {
        label: userLang?.trip?.TRIP_ID,
        field: "tripId",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.planning?.INDENT_ID,
        field: "indentId",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.SOURCE,
        field: "source",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.CUSTOMERS,
        field: "customers",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.TRANSPORTER,
        field: "transporter",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.trip?.DRIVER,
        field: "driver",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.trip?.VEHICLE,
        field: "vehicle",
        sort: "asc",
        width: 3,
      },

      {
        label: userLang?.trip?.TIME,
        field: "startTime",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.STATUS,
        field: "subStatus",
        sort: "asc",
        width: 2,
      },
    ],
    // rows: trips,
  };
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.trip?.TRIP_ID,
        field: "tripId",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.planning?.INDENT_ID,
        field: "indentId",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.SOURCE,
        field: "source",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.CUSTOMERS,
        field: "customers",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.TRANSPORTER,
        field: "transporter",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.trip?.DRIVER,
        field: "driver",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.trip?.VEHICLE,
        field: "vehicle",
        sort: "asc",
        width: 3,
      },

      {
        label: userLang?.trip?.TIME,
        field: "startTime",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.STATUS,
        field: "subStatus",
        sort: "asc",
        width: 2,
      },
    ],
    // rows: trips,
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeaderData = {
  columns: [
    {
      label: userLang?.trip?.TRIP_ID,
      field: "tripId",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.planning?.INDENT_ID,
      field: "indentId",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.CUSTOMERS,
      field: "customers",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.trip?.DRIVER,
      field: "driver",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.trip?.VEHICLE,
      field: "vehicle",
      sort: "asc",
      width: 3,
    },

    {
      label: userLang?.trip?.TIME,
      field: "startTime",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.STATUS,
      field: "subStatus",
      sort: "asc",
      width: 2,
    },
  ],
  // rows: trips,
};
export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.trip?.TRIP_ID,
      field: "tripId",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.planning?.INDENT_ID,
      field: "indentId",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.CUSTOMERS,
      field: "customers",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.trip?.DRIVER,
      field: "driver",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.trip?.VEHICLE,
      field: "vehicle",
      sort: "asc",
      width: 3,
    },

    {
      label: userLang?.trip?.TIME,
      field: "startTime",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.STATUS,
      field: "subStatus",
      sort: "asc",
      width: 2,
    },
  ],
  // rows: trips,
};
export const searchBar = {
  searchBarData: [
    {
      label: userLang?.trip?.TRIP_ID,
      field: "tripId",
      type: "text",
    },
    {
      label: userLang?.planning?.INDENT_ID,
      field: "indentId",
      type: "text",
    },
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      type: "select",
    },
    {
      label: userLang?.common?.CUSTOMERS,
      field: "customers",
      type: "select",
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      type: "text",
    },
    {
      label: userLang?.trip?.DRIVER,
      field: "driver",
      type: "text",
    },
    {
      label: userLang?.trip?.VEHICLE,
      field: "vehicle",
      type: "select",
    },

    {
      label: userLang?.trip?.TIME,
      field: "startTime",
      type: "date",
    },
    {
      label: userLang?.common?.STATUS,
      field: "subStatus",
      type: "select",
    },
  ],
  // rows: trips,
};
