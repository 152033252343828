import React, { Component } from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import Select from "react-select";
import VehicleDetails from "./VehicalDetails";
import DriverDetails from "./DriverDetails";
import CreatableSelect from "react-select/creatable";

class ReportIncidentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chevronVehicle: "down",
      chevronDriver: "down",
      vehicleDropDown: false,
      driverDropDown: false,
    };
  }
  componentDidMount = () => {};
  componentWillUnmount = () => {
    this.props.handleReset();
    this.props.handleResetEdit();
  };
  vehicleDropDownChange = () => {
    let direction = "";
    if (this.state.chevronVehicle === "down") {
      direction = "up";
    } else {
      direction = "down";
    }
    this.setState({
      vehicleDropDown: !this.state.vehicleDropDown,
      driverDropDown: false,
      chevronVehicle: direction,
      chevronDriver: "down",
    });
  };
  driverDropDownChange = () => {
    let direction = "";
    if (this.state.chevronDriver === "down") {
      direction = "up";
    } else {
      direction = "down";
    }
    this.setState({
      vehicleDropDown: false,
      driverDropDown: !this.state.driverDropDown,
      chevronVehicle: "down",
      chevronDriver: direction,
    });
  };
  render() {
    let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    var darkMode = this.props?.mode;
    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      control: (style) => {
        return {
          ...style,
          height: "50px",
        };
      },
    };
    return (
      <React.Fragment>
        <MDBContainer className="text-capitalize">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggle()}
            centered
            className="ReportIncidentModal"
          >
            <i
              className="fas fa-times closemodal p-3 closeDark"
              onClick={() => this.props.toggle()}
            ></i>
            {!this.props.detailchange ? (
              <MDBModalHeader className="modal-head">
                <span
                  className="fontRoboto light__span"
                  style={{
                    color: "#0C0C0C",
                  }}
                >
                  {userLang?.tracking?.REPORT_INCIDENT}
                </span>
              </MDBModalHeader>
            ) : (
              <MDBModalHeader className="modal-head">
                <span
                  className="fontRoboto light__span"
                  style={{
                    color: "#0C0C0C",
                  }}
                >
                  {userLang?.tracking?.ENTER}{" "}
                  {userLang?.tracking?.DRIVER_VEHICLE}
                </span>
                <div className="fontRoboto15 mt-2 dark__span">
                  {userLang?.tracking?.TRACK_TRIP_ID} :{this.props.id}
                  &nbsp;
                  <span className="vehicleChangeIncident fontRoboto12">
                    (
                    {userLang?.tracking?.VEHICLE_CHANGE ??
                      "Vehicle Change Due To An Incident"}
                    )
                  </span>
                </div>
              </MDBModalHeader>
            )}
            <MDBModalBody>
              {!this.props.detailchange ? (
                <div>
                  <Row className="pb-3">
                    <Col xs="6">
                      <div className="fontRoboto154 light__span">
                        <div>
                          <span style={{ fontWeight: "bolder" }}>
                            {" "}
                            {userLang?.tracking?.LAST_LOCATION} :{" "}
                          </span>
                        </div>
                        <span className="dark__span"> COIMBATORE-C&FA</span>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="fontRoboto154 light__span">
                        <div>
                          <span style={{ fontWeight: "bolder" }}>
                            {" "}
                            {userLang?.tracking?.LAST_REPORT_TIME}:{" "}
                          </span>
                        </div>
                        <span className="dark__span">24-06-2021,12:00 AM</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup className="darkReportIncident">
                        <Label className="control-label fontRoboto15 light__span">
                          {userLang?.tracking?.TYPE_OF_INCIDENT}
                          <span className="req">*</span>
                        </Label>
                        <Select
                          value={this.props.incidentType}
                          isMulti={false}
                          // onInputChange={this.props.vehicleType}
                          styles={customStyles}
                          onChange={(e) => {
                            this.props.onReportIncident({
                              target: {
                                name: "incidentType",
                                value: e,
                              },
                            });
                          }}
                          options={this.props.incidents}
                          classNamePrefix="select2-selection"
                          placeholder="select..."
                        />
                        {this.props?.errorMessage?.incidentType && (
                          <div className="error">
                            {this.props.errorMessage?.incidentType}{" "}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label className="control-label fontRoboto15 light__span">
                          {userLang?.tracking?.DESCRIPTION}
                          <span className="req">*</span>
                        </Label>
                        <Input
                          value={this.props.incidentdescription}
                          className="form-control darkTexBox fontRoboto17"
                          onChange={(e) => {
                            this.props.onReportIncident({
                              target: {
                                name: "incidentdescription",
                                value: e.target.value,
                              },
                            });
                          }}
                          type="textarea"
                          placeholder="Type Reason Here"
                        />
                        {this.props?.errorMessage?.incidentdescription && (
                          <div className="error">
                            {" "}
                            {this.props.errorMessage?.incidentdescription}{" "}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg="12"
                      // className="text-center"
                      // style={{ alignItems: "center" }}
                    >
                      <Row>
                        <Col xs="3">
                          <Button
                            className="fontRoboto1541"
                            style={{
                              width: "100%",
                              height: "50px",
                              backgroundColor: "#0AB38C",
                              color: "#FFFFFF",
                            }}
                            color="secondary"
                            onClick={() => {
                              this.props.handleReset();
                            }}
                          >
                            {userLang?.common?.RESET}
                          </Button>
                        </Col>
                        <Col xs="3">
                          <Button
                            className="fontRoboto1541"
                            style={{
                              width: "100%",
                              height: "50px",
                              backgroundColor: this.props.mode
                                ? "#5E40FF"
                                : "#05284E",
                              color: "#FFFFFF",
                            }}
                            color="primary"
                            onClick={() => {
                              this.props.detailchangefunc();
                            }}
                          >
                            {userLang?.common?.SUBMIT}
                          </Button>
                        </Col>
                        <Col xs="6"></Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Row>
                  <Col lg="12">
                    <span style={{ fontWeight: "bolder" }}>
                      {userLang?.trackingWARNING}
                    </span>
                  </Col>
                  <Col lg="12" className="a-jumbo">
                    <FormGroup className="select2-container darkReportIncident">
                      <Label className="control-label fontRoboto15 light__span">
                        {userLang?.tracking?.VEHICLE_PLATE}
                      </Label>
                      <Select
                        value={this.props?.vehicle}
                        isMulti={false}
                        onInputChange={this.props.filterVehicleList}
                        styles={customStyles}
                        onChange={(e) => {
                          this.props.onChange({
                            target: {
                              name: "vehicle",
                              value: e,
                            },
                          });
                        }}
                        options={this.props.options}
                        classNamePrefix="select2-selection"
                      />
                      {this.props?.errorMessageEdit?.vehicle && (
                        <div className="error">
                          {" "}
                          {this.props.errorMessageEdit?.vehicle}{" "}
                        </div>
                      )}
                    </FormGroup>
                    <div className="AddNewSpan addNewBg">
                      <Row>
                        <Col xs={11}>
                          <span
                            className="textHead ptL10"
                            style={{
                              color: "#2800FC",
                            }}
                          >
                            {userLang?.common?.ADD_NEW}{" "}
                          </span>
                        </Col>
                        <Col xs={1}>
                          <i
                            className={`fa fa-chevron-${this.state.chevronVehicle} bt AddNewSpanIcon`}
                            onClick={() => this.vehicleDropDownChange()}
                          ></i>
                        </Col>
                      </Row>
                    </div>
                    {this.state.vehicleDropDown ? (
                      <VehicleDetails
                        submitVehicleForm={this.props.submitVehicleForm}
                        onVehicleChange={this.props.onVehicleChange}
                        vehicleForm={this.props.vehicleForm}
                        type={this.props.type}
                        isVehicleNoValid={this.props.isVehicleNoValid}
                        isVehicleTypeValid={this.props.isVehicleTypeValid}
                        toBeSelectedVehicleType={
                          this.props.toBeSelectedVehicleType
                        }
                        mode={this.props.mode}
                      />
                    ) : null}
                  </Col>
                  <Col lg="12">
                    <FormGroup className="select2-container darkReportIncident">
                      <Label className="control-label fontRoboto15 light__span">
                        {userLang?.trip?.DRIVER ?? "Driver"}
                      </Label>
                      <CreatableSelect
                        value={this.props?.driver}
                        onInputChange={this.props.filterDriverList}
                        styles={customStyles}
                        isMulti={false}
                        onChange={(e) => {
                          this.props.onChange({
                            target: {
                              name: "driver",
                              value: e,
                            },
                          });
                        }}
                        options={this.props.optionsdriver}
                        classNamePrefix="select2-selection"
                      />
                      {this.props?.errorMessageEdit?.driver && (
                        <div className="error">
                          {" "}
                          {this.props.errorMessageEdit?.driver}{" "}
                        </div>
                      )}
                    </FormGroup>
                    <div className="AddNewSpan addNewBg">
                      <Row>
                        <Col xs={11}>
                          <span
                            className=" ptL10 textHead"
                            style={{
                              color: "#2800FC",
                            }}
                          >
                            {userLang?.common?.ADD_NEW}{" "}
                          </span>
                        </Col>
                        <Col xs={1}>
                          <i
                            className={`fa fa-chevron-${this.state.chevronDriver} bt AddNewSpanIcon`}
                            onClick={() => this.driverDropDownChange()}
                          ></i>
                        </Col>
                      </Row>
                    </div>
                    {this.state.driverDropDown ? (
                      <DriverDetails
                        submitDriverForm={this.props.submitDriverForm}
                        onDriverChange={this.props.onDriverChange}
                        driverForm={this.props.driverForm}
                        isPhoneNoValid={this.props.isPhoneNoValid}
                        mode={this.props.mode}
                      />
                    ) : null}
                  </Col>
                  <Col
                    lg="12"
                    // className="text-center"
                    // style={{ marginTop: "2rem" }}
                  >
                    <Row
                      style={{
                        paddingTop: "10px",
                      }}
                    >
                      <Col xs="3">
                        <Button
                          className="fontRoboto1541"
                          style={{
                            width: "100%",
                            height: "50px",
                            backgroundColor: "#0AB38C",
                            color: "#FFFFFF",
                          }}
                          color="secondary"
                          onClick={() => this.props.handleResetEdit()}
                        >
                          {userLang?.common?.RESET}
                        </Button>
                      </Col>
                      <Col xs="3">
                        <Button
                          className="fontRoboto1541"
                          style={{
                            width: "100%",
                            height: "50px",
                            backgroundColor: this.props.mode
                              ? "#5E40FF"
                              : "#05284E",
                            color: "#FFFFFF",
                          }}
                          color="primary"
                          onClick={() => {
                            this.props.onReportInsidentSubmit();
                            // this.props.toggle();
                          }}
                        >
                          {userLang?.common?.SUBMIT}
                        </Button>
                      </Col>
                      <Col xs="6"></Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}
export default ReportIncidentModal;
