import * as React from "react";
import { useRef, useEffect } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Map } from "./Map";
import { Polyline } from "./Polyline";
import "./MapCore.css";
// import
import completed from "../../../src/assets/images/Complete truck 1.svg";
import delay from "../../../src/assets/images/Delayed truck 1.svg";
import intransit from "../../../src/assets/images/Intransit truck 1.svg";
const render = (status) => {
  return <h1>{status}</h1>;
};
var darkMode;
const MapCore = (props) => {
  // [START maps_react_map_component_app_state]
  const [clicks, setClicks] = React.useState([]);
  const [zoom, setZoom] = React.useState(5); // initial zoom
  const [center, setCenter] = React.useState({ lat: 21.1458, lng: 79.0882 });
  const [vehicleIcon, setVehicleIcon] = React.useState(completed);

  const onClick = (e) => {
    // avoid directly mutating state
    setClicks([...clicks, e.latLng]);
  };
  useEffect(() => {
    if (props.subStatus === "in_transit" && props.delayedStatus === false) {
      setVehicleIcon(intransit);
    } else if (
      props.subStatus === "in_transit" &&
      props.delayedStatus === true
    ) {
      setVehicleIcon(delay);
    }
  }, [props.subStatus]);
  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  const randomColor = () => {
    {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  };
  let mode = localStorage.getItem("Theme")
    ? localStorage.getItem("Theme")
    : null;
  darkMode = mode === "dark__mode" ? true : false;

  return (
    <div
      style={{ display: "flex", height: "100vh", margin: "0", padding: "0" }}
    >
      <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY} render={render}>
        <Map
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          style={{ flexGrow: "1", height: "100vh" }}
          options={
            darkMode
              ? {
                  styles: [
                    {
                      elementType: "geometry",
                      stylers: [{ color: "#242f3e" }],
                    },
                    {
                      elementType: "labels.text.stroke",
                      stylers: [{ color: "#242f3e" }],
                    },
                    {
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#746855" }],
                    },
                    {
                      featureType: "administrative.locality",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#d59563" }],
                    },
                    {
                      featureType: "poi",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#d59563" }],
                    },
                    {
                      featureType: "poi.park",
                      elementType: "geometry",
                      stylers: [{ color: "#263c3f" }],
                    },
                    {
                      featureType: "poi.park",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#6b9a76" }],
                    },
                    {
                      featureType: "road",
                      elementType: "geometry",
                      stylers: [{ color: "#38414e" }],
                    },
                    {
                      featureType: "road",
                      elementType: "geometry.stroke",
                      stylers: [{ color: "#212a37" }],
                    },
                    {
                      featureType: "road",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#9ca5b3" }],
                    },
                    {
                      featureType: "road.highway",
                      elementType: "geometry",
                      stylers: [{ color: "#746855" }],
                    },
                    {
                      featureType: "road.highway",
                      elementType: "geometry.stroke",
                      stylers: [{ color: "#1f2835" }],
                    },
                    {
                      featureType: "road.highway",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#f3d19c" }],
                    },
                    {
                      featureType: "transit",
                      elementType: "geometry",
                      stylers: [{ color: "#2f3948" }],
                    },
                    {
                      featureType: "transit.station",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#d59563" }],
                    },
                    {
                      featureType: "water",
                      elementType: "geometry",
                      stylers: [{ color: "#17263c" }],
                    },
                    {
                      featureType: "water",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#515c6d" }],
                    },
                    {
                      featureType: "water",
                      elementType: "labels.text.stroke",
                      stylers: [{ color: "#17263c" }],
                    },
                  ],
                }
              : { styles: [] }
          }
        >
          {/* {clicks.map((latLng, i) => {
            return <Marker key={i} position={latLng} />;
          })} */}
          {props.destinationCoordinates &&
          Object.keys(props.destinationCoordinates).length === 0 &&
          Object.getPrototypeOf(props.destinationCoordinates) ===
            Object.prototype ? null : (
            <Marker
              key="destination_marker"
              icon={{
                url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              position={props?.destinationCoordinates?.coordinates}
              title={props?.destinationCoordinates?.destinationDescription}
            />
          )}
          {props.destinationList &&
            props.destinationList.map((item, index) => {
              return <Marker key="destination_marker" position={item} />;
            })}

          {props.sourceCoordinates &&
          Object.keys(props.sourceCoordinates).length === 0 &&
          Object.getPrototypeOf(props.sourceCoordinates) ===
            Object.prototype ? null : (
            <Marker
              key="source_marker"
              icon={{
                url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              position={props?.sourceCoordinates?.coordinates}
              title={props?.sourceCoordinates?.sourceDescription}
            />
          )}

          {process.env.REACT_APP_CLIENT === "3_BAXTER" ? (
            props.sourceCoordinatesOrder &&
            Object.keys(props.sourceCoordinatesOrder).length === 0 &&
            Object.getPrototypeOf(props.sourceCoordinatesOrder) ===
              Object.prototype ? null : (
              <Marker
                key="source_marker"
                icon={{
                  url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                  scaledSize:
                    props.sourceCoordinatesOrder &&
                    new window.google.maps.Size(30, 30),
                }}
                position={props.sourceCoordinatesOrder}
              />
            )
          ) : (
            ""
          )}

          {process.env.REACT_APP_CLIENT === "3_BAXTER" ? (
            props.destinationCoordinatesOrder &&
            Object.keys(props.destinationCoordinatesOrder).length === 0 &&
            Object.getPrototypeOf(props.destinationCoordinatesOrder) ===
              Object.prototype ? null : (
              <Marker
                key="destination_marker"
                icon={{
                  url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                  scaledSize:
                    props.destinationCoordinatesOrder &&
                    new window.google.maps.Size(30, 30),
                }}
                position={props.destinationCoordinatesOrder}
              />
            )
          ) : (
            ""
          )}

          {process.env.REACT_APP_CLIENT === "3_BAXTER" ? (
            props.currentLocationOrder &&
            Object.keys(props.currentLocationOrder[0]).length === 0 &&
            Object.getPrototypeOf(props.currentLocationOrder[0]) ===
              Object.prototype ? null : (
              <Marker
                key="marker_3"
                icon={{
                  url: vehicleIcon,
                  scaledSize:
                    props.currentLocationOrder &&
                    new window.google.maps.Size(50, 50),
                }}
                position={
                  props.currentLocationOrder
                    ? props.currentLocationOrder[0]
                    : ""
                }
              />
            )
          ) : (
            ""
          )}

          {props.currentLocation &&
          Object.keys(props.currentLocation).length === 0 &&
          Object.getPrototypeOf(props.currentLocation) ===
            Object.prototype ? null : (
            <Marker
              key="marker_3"
              icon={{
                url: vehicleIcon,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              title={props?.latestKnownLocation}
              position={props?.currentLocation}
            />
          )}

          {props.coordinateArrayOrder &&
            props.coordinateArrayOrder.map((item, index) => {
              const r = randomColor();
              return (
                <Polyline
                  path={props.coordinateArrayOrder}
                  strokeColor={darkMode ? "#ff8629" : "#05284E"}
                />
              );
            })}
          {props.vehicleCoordinate &&
            props.vehicleCoordinate.map((item, index) => {
              if (item.status === "in_transit") {
                return (
                  <Marker
                    key="marker_4"
                    icon={{
                      url: item?.delayedStatus === false ? intransit : delay,
                      scaledSize: new window.google.maps.Size(40, 40),
                    }}
                    mapsId={item?.id}
                    singleFetchTrips={props.singleFetchTrips}
                    position={item?.coordinate}
                  />
                );
              }
            })}
          {props?.coordinateOrder &&
            props?.coordinateOrder.map((item, index) => {
              if (item?.status === "in_transit") {
                return (
                  <Marker
                    key="marker_4"
                    icon={{
                      url: item?.status === "in_transit" ? intransit : delay,
                      scaledSize: new window.google.maps.Size(50, 50),
                    }}
                    position={item?.coordinates}
                  />
                );
              } else if (item?.status === "completed") {
                return (
                  <Marker
                    key="marker_5"
                    icon={{
                      url: completed,
                      scaledSize: new window.google.maps.Size(50, 50),
                    }}
                    position={item?.coordinates}
                  />
                );
              }
            })}
          {props.coordinateArray &&
            props.coordinateArray.map((item, index) => {
              console.log(item);
              const r = randomColor();
              return (
                <Polyline
                  path={item}
                  strokeColor={darkMode ? "#ff8629" : "#05284E"}
                />
              );
            })}

          {props.lineCoordinates &&
            props.lineCoordinates.map((item, index) => {
              return (
                <Polyline
                  path={item.lines}
                  geodesic={false}
                  options={{
                    strokeColor: item.color,
                    strokeOpacity: 1,
                    strokeWeight: 3,
                  }}
                />
              );
            })}
        </Map>
      </Wrapper>
      {/* {form} */}
    </div>
  );
  // [END maps_react_map_component_app_return]
};

// [START maps_react_map_marker_component]
const Marker = (options) => {
  const [marker, setMarker] = React.useState();
  const markerRef = useRef(null);

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      marker.addListener("click", function () {
        if (options?.mapsId) options.singleFetchTrips(options.mapsId);
      });
    }
  }, [marker, options]);
  return null;
};

const AutoComplete = () => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {};
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      // console.log({ place });
    });
  }, []);
  return (
    <div>
      <label>enter address :</label>
      <input ref={inputRef} />
    </div>
  );
};
export default AutoComplete;
export { MapCore };
