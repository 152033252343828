import React, { Component } from "react";
import {
  InputField,
  // RenderNewDate2,
  RenderNewDate,
} from "./inputFields/Fields";
import { Row, Col, Card, Button, CardTitle } from "reactstrap";
import sorting_icon from "./assets/sorting_icon.png";
import sortingDark_icon from "./assets/sortDark.png";
import moment from "moment";
import { WaveLoader } from "../../Common/Loader/LoaderIndex";
import { NotificationManager } from "react-notifications";
import { searchBar } from "../../../pages/MastersDriver/RowHeaders/RowHeaders";

let dataSet = [];
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
let AllFilterObj = {};
let statusFilter = {};
let locName = window.location.pathname;
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      emptyFlag: false,
      //data
      headers: [],
      columnWidthArray: [],
      data: [],
      dataLength: 0,
      processedData: [],
      //search
      searchToggle: true,
      searchBar: [],
      //date
      date: [null, null],
      startDate: [null, null],
      endDate: [null, null],
      // reset
      resetBar: true,
      fetchId: "",
      specialCharError: "",
      checkChanged: false,
    };
  }

  componentDidMount = () => {
    let isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER";
    if (isBaxter) {
      let pathName = window.location.pathname;
      let keys = `myStatusFilter_${pathName}`;
      let keys1 = `mySearchFilter_${pathName}`;
      let mountFilterData = window.localStorage.getItem(keys)
        ? JSON.parse(window.localStorage.getItem(keys))
        : "";
      let mountFilterData1 = window.localStorage.getItem(keys1)
        ? JSON.parse(window.localStorage.getItem(keys1))
        : "";
      // console.log(mountFilterData1,"99")
      statusFilter = mountFilterData || {};
      AllFilterObj = mountFilterData1 || {};
    }
    let headers = this.props?.headers?.columns;
    let searchBar = this.props?.searchBar?.searchBarData;
    let columnWidthArray = [];

    this.setState({ headers: headers, searchBar: searchBar }, () => {
      if (this.state.headers)
        this.state.headers.forEach((item) => {
          columnWidthArray.push(item.width);
        });
      this.setState({ columnWidthArray: columnWidthArray });
    });
  };

  componentDidUpdate = () => {
    let data = this.props.data;

    let fetchId = this.props.fetchId;
    // //console.log("componentDIdupdate", data);
    if (!data?.length && this.state?.emptyFlag === false)
      this.setState({
        processedData: [],
        emptyFlag: true,
      });
    else if (
      this.state?.data[0]?.id !== data[0]?.id ||
      this.state?.data?.length !== data?.length ||
      this.props?.dataLength !== this.state?.dataLength ||
      this.state?.fetchId !== fetchId ||
      this.props?.checkChanged != this.state?.checkChanged
    ) {
      this.setState(
        {
          data,
          emptyFlag: false,
          dataLength: this.props?.dataLength,
          fetchId: fetchId,
          checkChanged: this.props?.checkChanged,
        },
        () => {
          this.formatter(this.state.data);
        }
      );
      //console.log("HERE INSIDE INDEX");
    }
  };

  formatter = (data) => {
    dataSet = [];
    data.map((dataItem, index) => {
      this.binder(dataItem, index);
      return true;
    });
  };

  binder = (dataItem, index) => {
    let dataSetItem = [];
    let headers = this.props?.headers?.columns;
    Object.keys(dataItem).map((key, index) => {
      if (dataSet?.length <= 1000 || this.props?.limit > 0)
        if (headers)
          headers.forEach((headItem, headIndex) => {
            if (headItem.field === key) {
              dataSetItem[headIndex] = dataItem[key];
            }
          });
      return true;
    });
    dataSet[index] = dataSetItem;
    // if (dataSet.length === 10) {
    this.setState({ processedData: dataSet }, () => {
      // //console.log("DATASET", this.state.processedData);
    });
    // }
  };

  handleChange = (event, type) => {
    // console.log("--event", event);
    // console.log("..type", type);
    const inputText = event.target.value;
    const containsSpecialChars = /[!@#$%^&*+_+\[\]{};':"\\|<>\/?]+/.test(
      inputText
    );

    if (!containsSpecialChars || type === "select") {
      this.setState(
        {
          [event.target.name]:
            type === "select"
              ? { label: event.target.label, value: event.target.value }
              : event.target.value,
          specialCharError: "",
        },
        () => {
          //console.log(
          //   "state[",
          //   event.target.name,
          //   "]:",
          //   this.state[event.target.name]
          // );
          if (type === "select") {
            const pathName = window.location.pathname;
            const key = `myStatusFilter_${pathName}`;
            const innerKey = `${event.target.name}_${pathName}`;

            statusFilter[`${innerKey}`] = {
              label: event.target.label,
              value: event.target.value,
            };
            const updatedstatusFilterObj = {
              ...statusFilter,
              [`${innerKey}`]: {
                label: event.target.label,
                value: event.target.value,
              },
            };

            if (!this.props?.hasOwnProperty("filterMaintain"))
              window.localStorage.setItem(
                key,
                JSON.stringify(updatedstatusFilterObj)
              );
          } else if (type === "text") {
            let pathName = window.location.pathname;
            let key = `mySearchFilter_${pathName}`;
            if (pathName != "/tripList" && pathName != "/ordersList") {
              AllFilterObj[event.target.name] = event.target.value;
              // console.log(AllFilterObj,"99")
              const updatedAllFilterObj = {
                ...AllFilterObj,
                [event.target.name]: event.target.value,
              };
              if (!this.props?.hasOwnProperty("filterMaintain"))
                window.localStorage.setItem(
                  key,
                  JSON.stringify(updatedAllFilterObj)
                );
            }
          }

          this.props.searchValueAssignerFunction(
            event.target.name,
            event.target.value,
            type
          );
        }
      );
    } else {
      this.setState({
        specialCharError: "Special characters are not allowed.",
      });
      NotificationManager.error("Special Character Not allowed", "Input Error");
    }
    // //console.log("STATE:", this.state);
  };
  // handleInputChangeTest =(e)=>{
  // //console.log("e",e)
  // }

  handleDate = (e, name) => {
    let date = [];
    if (name === "startTime")
      this.setState({ startDate: e }, () => {
        date[0] = moment(this.state?.startDate[0])?.format("MM-DD-YYYY");
        date[1] = moment(this.state?.startDate[1])?.format("MM-DD-YYYY");
        this.props.searchValueAssignerFunction(name, date, "date");
      });
    else if (name === "expiryTime")
      this.setState({ endDate: e }, () => {
        date[0] = moment(this.state?.endDate[0])?.format("MM-DD-YYYY");
        date[1] = moment(this.state?.endDate[1])?.format("MM-DD-YYYY");
        this.props.searchValueAssignerFunction(name, date, "date");
      });
  };

  keyPress = (e) => {
    if (e.key === "Enter") this.props.getAllData();
  };

  // change = () => {
  //   //console.log("CHNAGED");
  //   this.props.getAllData();
  // };

  reset = () => {
    this.setState(
      { resetBar: false, startDate: [null, null], endDate: [null, null] },
      () => {
        this.setState({ resetBar: true });
      }
    );
    this.state.searchBar.forEach((item) => {
      // //console.log(this[item.field]?.value);
      if (this[item.field]?.value) this[item.field].value = "";
      // //console.log(item);
      this.setState({ [item.field]: "" }, () => {
        AllFilterObj = {};
        statusFilter = {};
        // //console.log(this.state[item.field]);
      });
    });
    let pathName = window.location.pathname;
    let key = `myStatusFilter_${pathName}`;
    let keyOffset = `myOffsetFilter_${pathName}`;
    let key1 = `mySearchFilter_${pathName}`;
    localStorage.removeItem(key);
    localStorage.removeItem(keyOffset);
    localStorage.removeItem(key1);
    this.props.reset();
  };

  handleDoubleClick = (dataItem, Item) => {
    // //console.log("double click captured", this.props, this.state, dataItem , Item)
    if (this.props?.location?.pathname === "/indent-planning") {
      const data = this.state.data.find((e) => e.indentId === Item[0]);
      this.props.toggle(data);
    }
  };

  render() {
    let pathName = window.location.pathname;
    let key = `myStatusFilter_${pathName}`;
    let key1 = `subStatus_${pathName}`;
    const storedStatusObjects = JSON.parse(localStorage.getItem(key)) || {};
    console.log(storedStatusObjects[key1]);
    //console.log("props", this.props?.computedMatch?.path);
    //console.log("this.props.data", this.props.data);
    //console.log("this.props.datahead", this.props.heads);
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let { headers, processedData, columnWidthArray } = this.state;
    console.log(processedData);
    const { REACT_APP_CLIENT } = process.env;
    const isNoBaxter = REACT_APP_CLIENT !== "3_BAXTER";
    let initStorage = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : "";
    if (initStorage === "dark__mode") {
      var darkTheme = true;
      var dataHeaderBgClr = "#222238";
      var dataHeaderTextClr = "#FFFFFF";
      var dataTableTextClr = "#FFFFFF";
      var dataCardBgClr = "#1B1C31";
      var dataProcessingClr = "#24243C";
      var dateBgClr = "#24243C";
      var dateClr = "";
    } else if (initStorage === "light__mode") {
      darkTheme = false;
      dataHeaderBgClr = "";
      dataHeaderTextClr = "#05284E";
      dataTableTextClr = "#000000";
      dataCardBgClr = "#eff2f7 ";
      dataProcessingClr = "";
      dateBgClr = "";
      dateClr = "";
    } else {
      darkTheme = false;
      dataHeaderBgClr = "";
      dataHeaderTextClr = "#05284E";
      dataTableTextClr = "#0C0C0C";
      dataCardBgClr = "#eff2f7 ";
      dataProcessingClr = "";
      dateBgClr = "";
      dateClr = "";
    }
    // //console.log(columnWidthArray, "columnWidthArray");
    return (
      <React.Fragment>
        {this.props.dataLoading && (
          <div className="ListingPages">
            <WaveLoader />
          </div>
        )}
        <div
          // className="IndexDataTableNeoParent"
          className={
            this.props?.headers?.columns?.length > 14
              ? "IndexDataTableNeoParentScroll"
              : "IndexDataTableNeoParent"
          }
          style={{
            textAlign: "center",
            justifyContent: "center",
            marginTop: darkTheme ? "0px" : "1px",
            backgroundColor: `${dataHeaderBgClr}`,
            paddingBottom: "0px",
            marginBottom: "0px !important",
            // display:"block",
            // width:"100%",
            // overflow:"auto"
            textTransform: "capitalize",
          }}
        >
          <div
            className={
              this.props?.headers?.columns?.length > 13
                ? "scroollDataTableNeoScroll"
                : "scroollDataTableNeo"
            }
          >
            <Card
              style={{
                minHeight: "190px",
                backgroundColor: `${dataCardBgClr}`,
                // maxHeight: "95vh",
                // overflowY: "auto",
              }}
              // className="CardDateTable"
              className={
                this.props?.headers?.columns?.length > 13
                  ? "CardDateTableScroll"
                  : "CardDateTable"
              }
            >
              {this.props?.searchable ? (
                this.state?.searchToggle ? (
                  <div
                    className="searchToggleDiv"
                    style={{ backgroundColor: darkTheme ? "" : "white" }}
                    // style={{
                    //   height: "150px",
                    //   display: "flex",
                    //   alignItems: "center",
                    //   marginTop: "-8px",
                    // }}
                  >
                    <Row
                      style={{ flex: "1", alignItems: "center" }}
                      className="IndexDatatableNeoRows"
                    >
                      {this.state?.resetBar
                        ? this.state?.searchBar?.map((item, index) => {
                            // console.log(">>>>>>>>>>>>>>>>>>>",searchBar)
                            // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>ITEM", item);
                            if (index !== this.state?.searchBar.length - 1) {
                              if (item.type !== "date") {
                                return (
                                  <Col
                                    xs={
                                      this.props.classList
                                        ? this.props.classList
                                        : "3"
                                    }
                                    className={
                                      this.props.textInput
                                        ? this.props.textInput
                                        : "IndexDatatableNeoCol1"
                                    }
                                    key={index}
                                    style={
                                      {
                                        // flex: columnWidthArray[index],
                                      }
                                    }
                                  >
                                    <InputField
                                      innerRef={(val) =>
                                        (this[item.field] = val)
                                      }
                                      inputName={item.field}
                                      controlType={item.type}
                                      value={storedStatusObjects[key1]}
                                      placeholderValue={
                                        item.field
                                        // === "driver"
                                        //   ? "Type Mobile No."
                                        //   : "Type"
                                      }
                                      onChangeMethod={(e) =>
                                        this.handleChange(e, item.type)
                                      }
                                      selectval={this.state[`${item?.field}`]}
                                      onInputChange={(e) =>
                                        this.props
                                          ?.filterDataTranspotersAndTrips
                                          ? this.props?.filterDataTranspotersAndTrips(
                                              e,
                                              item.field
                                            )
                                          : ""
                                      }
                                      optionList={item.options}
                                      handleKeyPress={this.keyPress}
                                      dateBg={dateBgClr}
                                      dateClr={dateClr}
                                      style={{
                                        border:
                                          item.type !== "date"
                                            ? "1px solid #E6E6E6"
                                            : "none",
                                      }}
                                    />
                                  </Col>
                                );
                              } else if (
                                item.type === "date" &&
                                item.field === "startTime"
                              ) {
                                return (
                                  <Col
                                    xs="3"
                                    className="dateSearch IndexDatatableNeoCol1"
                                    key={index}
                                    style={
                                      {
                                        // flex: columnWidthArray[index],
                                      }
                                    }
                                  >
                                    <RenderNewDate
                                      inputName={item.field}
                                      controlType={item.type}
                                      inputValue={this.state.startDate}
                                      placeholderValue={item.field}
                                      onChangeMethod={(e) =>
                                        this.handleDate(e, item.field)
                                      }
                                      // onInputChange={this.change}
                                      optionList={item.options}
                                      handleKeyPress={this.keyPress}
                                      dateBg={dateBgClr}
                                      dateClr={dateClr}
                                    />
                                  </Col>
                                );
                              } else if (
                                (item.type === "date" &&
                                  item.field === "expiryTime") ||
                                item.field === "createTime"
                              ) {
                                return (
                                  <Col
                                    xs="3"
                                    className="dateSearch IndexDatatableNeoCol1"
                                    key={index}
                                    style={
                                      {
                                        // flex: columnWidthArray[index],
                                      }
                                    }
                                  >
                                    <RenderNewDate
                                      inputName={item.field}
                                      controlType={item.type}
                                      inputValue={this.state.endDate}
                                      placeholderValue={item.field}
                                      onChangeMethod={(e) =>
                                        this.handleDate(e, item.field)
                                      }
                                      // onInputChange={this.change}
                                      optionList={item.options}
                                      handleKeyPress={this.keyPress}
                                      dateBg={dateBgClr}
                                      dateClr={dateClr}
                                    />
                                  </Col>
                                );
                              }
                            } else
                              return (
                                <>
                                  {!this.props.classList ? (
                                    <Col xs="3"></Col>
                                  ) : (
                                    ""
                                  )}
                                  {this.props?.computedMatch?.path ===
                                  "/indent-planning" ? (
                                    <Col xs="3"></Col>
                                  ) : (
                                    " "
                                  )}
                                  {this.props?.computedMatch?.path ===
                                  "/assigned-indent" ? (
                                    <>
                                      <Col xs="3"></Col>
                                      <Col xs="3"></Col>
                                    </>
                                  ) : (
                                    " "
                                  )}

                                  <Col
                                    xs={
                                      this.props.resetCol
                                        ? this.props.resetCol
                                        : "3"
                                    }
                                    className="IndexDatatableNeoCol1"
                                    key={index}
                                    style={{
                                      // flex: columnWidthArray[index],
                                      alignSelf: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        maxHeight: "22px",
                                        marginTop: "35px",
                                      }}
                                    >
                                      <Button
                                        className={
                                          this.props.classList
                                            ? "myResearSearchBar"
                                            : "resetSearchBar"
                                        }
                                        onClick={this.reset}
                                      >
                                        {userLang?.common?.RESET}
                                      </Button>
                                    </div>
                                  </Col>
                                </>
                              );
                            return true;
                          })
                        : null}

                      {/* <Col
                    style={{
                      maxHeight: "10px",
                      maxWidth: "10px",
                      alignSelf: "center",
                    }}
                    className="mb-2"
                    onClick={() => {
                      // this.setState({ searchToggle: false });
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </Col> */}
                    </Row>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#2800FC",
                      color: "#FFFFFF",
                      width: "24px",
                      height: "20px",
                      marginTop: "-12px",
                      position: "absolute",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      // this.setState({ searchToggle: true });
                    }}
                  >
                    <i className="fas fa-search"></i>
                  </div>
                )
              ) : null}
              <Card
                // className={
                //   this.props?.headers?.columns?.length > 13
                //     ? "CardDateTableScroll1"
                //     : "CardDateTable"
                // }
                className="CardDateTableScroll1"
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  height: "57px",
                  marginBottom: "-15px",
                  width: "100%",
                  marginTop: "17px",
                  backgroundColor: `${dataHeaderBgClr}`,
                }}
              >
                <div style={{ margin: "15px 0px 11px 0px" }}>
                  <CardTitle className="">
                    <Row
                      style={{
                        alignItems: "center",
                        marginLeft: "0px",
                        marginBottom: "22px",
                      }}
                      className={
                        this.props?.headers?.columns?.length > 13
                          ? "IndexDatatableNeoRowScroll"
                          : "IndexDatatableNeoRow"
                      }
                      // className="IndexDatatableNeoRow"
                    >
                      {headers?.map((headItem, index) => {
                        // console.log("headeIten", headItem);
                        return (
                          <Col
                            className="IndexDatatableNeoCol"
                            key={index}
                            style={{
                              flex: columnWidthArray[index],
                              color: `${dataHeaderTextClr}`,
                              fontSize: "15px",
                              fontWeight: "700",
                              lineHeight: "normal",
                              fontFamily: "Roboto",
                            }}
                            onClick={() => {
                              if (this.props.sortColumn) {
                                this.props.sortColumn(headItem?.field);
                              }
                            }}
                          >
                            {headItem.label}{" "}
                            {isNoBaxter ||
                            locName === "/optimization" ||
                            headItem.field === "lInCMS" ||
                            headItem.field === "wInCMS" ||
                            headItem.field === "hInCMS" ||
                            headItem.field === "casePerPallet" ||
                            headItem.field === "qvSubDivision" ? (
                              ""
                            ) : (
                              <span>
                                <img
                                  src={
                                    darkTheme ? sortingDark_icon : sorting_icon
                                  }
                                  alt={"v"}
                                />
                              </span>
                            )}
                          </Col>
                        );
                      })}
                    </Row>
                  </CardTitle>
                </div>
              </Card>
              <div
                style={
                  window.location.pathname.includes("/secondary-add-indent")
                    ? {
                        // marginTop: "22px",
                        overflowY: "auto",
                        height: "506px",
                      }
                    : window.location.pathname.includes("/secondary-trips") ||
                      window.location.pathname.includes("/trips")
                    ? {}
                    : // : { overflow: "auto" }
                      {}
                }
              >
                {!this.props.dataLoading && (
                  <>
                    {processedData?.length > 0 ? (
                      processedData.map((Item, index) => {
                        return (
                          <Card
                            className=""
                            style={{
                              height: this.props.rowHeight
                                ? this.props.rowHeight
                                : "fitContent",
                              textAlign: "center",
                              justifyContent: "center",
                              marginBottom: "4px",
                              padding: "1px 4px",
                              boxShadow: "0px 4px 20px -12px",
                              width: "100%",
                              backgroundColor: `${dataProcessingClr}`,
                            }}
                            key={index}
                          >
                            <Row
                              className={
                                this.props?.headers?.columns?.length > 13
                                  ? "IndexDatatableNeoRowScroll"
                                  : "IndexDatatableNeoRow"
                              }
                              style={{
                                border: "2px",
                                alignItems: "center",
                                textAlign: "center",
                                marginLeft: "0px",
                              }}
                            >
                              {Item.map((dataItem, index) => {
                                return (
                                  <>
                                    <Col
                                      onDoubleClick={() => {
                                        this.handleDoubleClick(dataItem, Item);
                                      }}
                                      className="IndexDatatableNeoCol"
                                      key={index}
                                      style={{
                                        cursor: "pointer",
                                        flex: columnWidthArray[index],
                                        // color: "#05284E",
                                        fontSize: "13px",
                                        fontWeight: "400",
                                        flexDirection: "row",
                                        whiteSpace: "wrap",
                                        color: `${dataTableTextClr}`,
                                        // textOverflow: "ellipsis",
                                        fontFamily: "Roboto",
                                        flexWrap: "nowrap !important",
                                        flexGrow: "0px !important",
                                      }}
                                    >
                                      {dataItem}
                                    </Col>
                                  </>
                                );
                              })}
                            </Row>
                          </Card>
                        );
                      })
                    ) : (
                      <Card
                        style={{
                          width: "100%",
                          height: "400px",
                          backgroundColor: `${dataProcessingClr}`,
                          // backgroundColor: "#F1F5F7",
                          justifyContent: "center",
                        }}
                      >
                        <div className="fontRoboto15 light__span">
                          No Matching Records Found
                        </div>
                      </Card>
                    )}
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Index;
