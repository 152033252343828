import React, { Component } from "react";
import { connect } from "react-redux";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Card,
  Container,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
import { fetchSignedURL_LRFront } from "../../../store/actions";
import {
  tripEpodSecondary,
  tripEpodCompleteSecondary,
  tripCompleteSecondary,
  acknowledgeLRSecondary,
} from "../../../store/secondaryTrip/actions";
import { fetchTripById } from "../../../store/actions";
import {
  fetchShipmentTripById,
  storeShipmentData,
} from "../../../store/actions";
import SecondaryQuickSearchFilters from "./SecondaryQickSeachFilter";
import plusIcon from "../assets/plus.png";
import minusIcon from "../assets/minus.png";
import playBtn from "../assets/play.svg";
import plusDarkIcon from "../assets/plusDark.png";
import SecondaryDamageShortageDetails from "./SecondaryDamageShortageDetails";
import SecondaryEPODModal from "./SecondaryEPODModal";
import { values } from "lodash";

var darkMode;
var fileObj = [];
var fileArray = [];

class SecondaryEPODIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tripID: "",
      shipmentData: [],
      shipmentDataDamaged: {},
      shipmentItemData: {},
      tripDetailsDisable: [],
      tripDetails: {},
      shipmentDataShow: false,
      damageShortageModalToggle: false,
      showEPODModal: false,
      frontImages: [],
      backImages: [],
      unloadingImages: [],
      itemDetailsData: {},
      unloadingCharge: "",
      contentShow: "",
      currentIndex: "",
      currentShipIndex: "",
      itemIndex: "",
      indexAcknowledgeLR: "",
      epodDetails: [],
      tripCompleteError: "",
    };
  }
  contentShow = (id) => {
    this.setState({ contentShow: !this.state.contentShow, currentIndex: id });
  };
  toggle_shipmentDataShow = () => {
    this.setState({ shipmentDataShow: !this.state.shipmentDataShow });
  };

  toggle_customersDataShow = () => {
    this.setState({ shipmentDataShow: !this.state.shipmentDataShow });
  };

  itemData = (index, data, shipmentData) => {
    console.log(shipmentData);
    this.setState(
      {
        shipmentItemData: data,
        shipmentDataDamaged: shipmentData,
        itemIndex: index,
      },
      () => {
        this.openDamageShortageModal();
      }
    );
  };
  EPODModalToggle = async (index) => {
    // this.props.fetchTripById(this.state.tripID, (res) => {
    //   console.log(res);
    //   this.setState(
    //     {
    //       epodDetails: res.data[0].epodDetails,
    //     },
    //     () => {}
    //   );
    // });
    this.setState(
      { showEPODModal: !this.state.showEPODModal, indexAcknowledgeLR: index },
      () => {
        console.log(this.state.indexAcknowledgeLR);
        return this.state.showEPODModal;
      }
    );
  };
  openDamageShortageModal = () => {
    this.setState({
      damageShortageModalToggle: !this.state.damageShortageModalToggle,
    });
  };

  openDamageShortageModalClose = () => {
    this.setState({ damageShortageModalToggle: false });
  };
  handleChangeCharge = (e) => {
    this.setState({
      unloadingCharge: e.target.value,
    });
  };

  disableEpodCompleteFunction = () => {
    this.props.fetchShipmentTripById(this.state.tripID, (res) => {
      if (res.success === true) {
        const updatedRes = res?.data.map((item) => {
          item.disableCompleteEpod = false;
          if (item.shipmentDetails.length === 0) {
            item.disableCompleteEpod = true;
          } else {
            for (const i of item.shipmentDetails) {
              for (const j of i.items) {
                if (!j.epod) {
                  item.disableCompleteEpod = true;
                  break;
                }
              }
            }
          }
          return item;
        });
        this.setState({
          tripDetailsDisable: updatedRes,
        });
      }
    });
  };

  componentDidMount = () => {
    //still to write

    this.setState(
      {
        // tripID: "65f976103ff141a4f49118d4",
        tripID: this.props.history?.location?.state?.tripDetailsForEPOD?._id,
      },
      () => {
        this.props.fetchShipmentTripById(this.state.tripID, (res) => {
          if (res.success === true) {
            NotificationManager.success(res["message"], "Success");
            const updatedRes = res?.data.map((item) => {
              item.disableCompleteEpod = false;
              if (item.shipmentDetails.length === 0) {
                item.disableCompleteEpod = true;
              } else {
                for (const i of item.shipmentDetails) {
                  for (const j of i.items) {
                    if (!j.epod) {
                      item.disableCompleteEpod = true;
                      break;
                    }
                  }
                }
              }
              return item;
            });
            this.setState(
              {
                tripDetailsDisable: updatedRes,
                tripDetails: updatedRes,
                shipmentData: updatedRes,
              },
              () => {
                this.props.storeShipmentData(this.state.shipmentData);
              }
            );
          } else if (res?.success === false) {
            NotificationManager.error(res["message"], "Error");
          } else {
            NotificationManager.error(res["message"], "Error");
          }
        });
      }
    );
  };
  acksubmit = (frontImages, backImages, unloadingImages) => {
    let epodCreate;
    let shipments = [];
    const shipmentData = this.props?.shipmentItemDataStoret;
    if (this.props?.shipmentItemDataStore !== undefined) {
      this.props.shipmentItemDataStore.forEach((ele, index) => {
        shipments.push({
          source: ele?.source,
          destination: ele?.destination,
          isActive: ele?.isActive,
          isDeleted: ele?.isDeleted,
          _id: ele?._id,
          tripId: ele?.tripId,
          shipmentId: ele?.shipmentId,
          items: ele?.items,
          createdAt: ele?.createdAt,
          updatedAt: ele?.updatedAt,
          updatedBy: ele?.updatedBy,
          __v: ele?.__v,
        });
      });
    }
    if (this.props.history?.location?.state?.tripDetailsForEPOD !== undefined) {
      epodCreate = {
        tripID: this.props.history?.location?.state?.tripDetailsForEPOD?._id,
        customerNumber:
          this.props.history?.location?.state?.tripDetailsForEPOD
            ?.customerDetails[this.state.indexAcknowledgeLR]?.customerNumber,
        customer_id:
          this.props.history?.location?.state?.tripDetailsForEPOD
            ?.customerDetails[this.state.indexAcknowledgeLR]?._id,
        shipToCode:
          this.props.history?.location?.state?.tripDetailsForEPOD
            ?.customerDetails[this.state.indexAcknowledgeLR]?.shipToCode,
        unloadingReceiptImages: unloadingImages,
        front: frontImages,
        back: backImages,
      };
    }

    const customerId =
      this.props.history?.location?.state?.tripDetailsForEPOD.customerDetails[
        this.state.indexAcknowledgeLR
      ]._id;
    this.setState(
      {
        frontImages: [...frontImages],
        backImages: [...backImages],
        unloadingImages: [...unloadingImages],
      },
      () => {
        // console.log(customerId);
        // const values = {
        //   tripID: this.props.history?.location?.state?.tripDetailsForEPOD?._id,
        //   customerNumber:
        //     this.props.history?.location?.state?.tripDetailsForEPOD
        //       ?.customerDetails[this.state.indexAcknowledgeLR]?.customerNumber,
        //   customer_id:
        //     this.props.history?.location?.state?.tripDetailsForEPOD
        //       ?.customerDetails[this.state.indexAcknowledgeLR]?._id,
        //   shipToCode:
        //     this.props.history?.location?.state?.tripDetailsForEPOD
        //       ?.customerDetails[this.state.indexAcknowledgeLR]?.shipToCode,
        //   // tripID: this.props.history?.location?.state?.tripDetailsForEPOD?._id,
        //   // customer_id: customerId,
        //   unloadingReceiptImages: unloadingImages,
        //   front: frontImages,
        //   back: backImages,
        // };
        // console.log(epodCreate);
        this.props.acknowledgeLRSecondary(epodCreate, (res) => {
          if (res.success) {
            NotificationManager.success(res["message"], "Success");
          } else {
            NotificationManager.error(res["message"], "Error");
          }
        });
      }
    );
  };
  handleSubmitDamged = (itemDetails) => {
    this.setState(
      {
        itemDetailsData: itemDetails,
      },
      () => {
        const damageSubmitPayload = {
          tripID: this.state.tripID,
          customerNumber: itemDetails.customerNumber,
          itemId: itemDetails.itemDetails._id,
          shipToCode: itemDetails.shipToCode,
          damagedData: {
            damagedQuantity: itemDetails.itemDetails.epod.damagedQuantity,
            shortageQuantity: itemDetails.itemDetails.epod.shortageQuantity,
            damageImages: itemDetails.itemDetails.epod.damageImages,
            shortageImages: itemDetails.itemDetails.epod.shortageImages,
            damageComment: itemDetails.itemDetails.epod.damageComment,
            shortageComment: itemDetails.itemDetails.epod.shortageComment,
          },
        };

        const shipmentData = this.state.shipmentData;
        if (this.state.shipmentData !== undefined) {
          this.state.shipmentData.forEach((ele, indexship) => {
            if (ele?.shipmentId === this.state.itemDetailsData?.shipmentId) {
              const test1 = ele?.items.forEach((eleItem, index) => {
                if (
                  eleItem?.itemId ===
                  this.state.itemDetailsData?.itemDetails?.itemId
                )
                  this.state.shipmentData[indexship].items[index] =
                    this.state.itemDetailsData?.itemDetails;
              });
            }
          });
          this.props.storeShipmentData(this.state.shipmentData);
          this.props.tripEpodSecondary(damageSubmitPayload, (res) => {
            if (res.success) {
              NotificationManager.success(
                "EPOD data successfully submitted",
                "Success"
              );
              this.disableEpodCompleteFunction();
            } else {
              NotificationManager.error(res["message"], "Error");
            }
          });
        }
        // this.handleSubmitTrip();
      }
    );
  };
  handleSubmitTrip = (index) => {
    let epodCreate;
    let shipments = [];
    const shipmentData = this.props?.shipmentItemDataStoret;
    if (this.props?.shipmentItemDataStore !== undefined) {
      this.props.shipmentItemDataStore.forEach((ele, index) => {
        shipments.push({
          source: ele?.source,
          destination: ele?.destination,
          isActive: ele?.isActive,
          isDeleted: ele?.isDeleted,
          _id: ele?._id,
          tripId: ele?.tripId,
          shipmentId: ele?.shipmentId,
          items: ele?.items,
          createdAt: ele?.createdAt,
          updatedAt: ele?.updatedAt,
          updatedBy: ele?.updatedBy,
          __v: ele?.__v,
        });
      });
    }
    if (this.props.history?.location?.state?.tripDetailsForEPOD !== undefined) {
      epodCreate = {
        tripID: this.props.history?.location?.state?.tripDetailsForEPOD?._id,
        customerNumber:
          this.props.history?.location?.state?.tripDetailsForEPOD
            ?.customerDetails[index]?.customerNumber,
        customer_id:
          this.props.history?.location?.state?.tripDetailsForEPOD
            ?.customerDetails[index]?._id,
        shipToCode:
          this.props.history?.location?.state?.tripDetailsForEPOD
            ?.customerDetails[index]?.shipToCode,
      };
    }
    this.props.tripEpodCompleteSecondary(epodCreate, (res) => {
      if (res.success) {
        NotificationManager.success("EPOD completed successfully", "Success");
        // this.props.getAllData();
        // this.handleReset();
        // this.props.toggleModel();
        this.props.history.push({
          pathname: "/secondary-trips",
        });
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };

  handleSubmitTripComplete = () => {
    const tripID = {
      tripID: this.props.history?.location?.state?.tripDetailsForEPOD?._id,
    };
    this.props.tripCompleteSecondary(tripID, (res) => {
      if (res.success) {
        // this.props.getAllData();
        // this.handleReset();
        if (res.message === "Update failed") {
          // NotificationManager.error(
          //   "All customers have not completed EPOD",
          //   "Error"
          // );
          this.setState({
            tripCompleteError: "All customers have not completed EPOD",
          });
        } else {
          NotificationManager.success("Trip completed successfully", "Success");
          this.props.toggleModel();
          this.props.history.push({
            pathname: "/secondary-trips",
          });
        }
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
  };

  render() {
    let TripData = this.props.history?.location?.state?.tripDetailsForEPOD;
    let current = TripData?.tracking?.current;
    let epodFlag = false;
    let DestinationNextID =
      this.props.history?.location?.state?.tripDetailsForEPOD?.tracking?.next;
    let tripdummy = TripData?.tracking?.path?.forEach((ele) => {
      if (ele?._id === current) {
        ele.operations.loading === null
          ? (epodFlag = true)
          : (epodFlag = false);
      }
    });

    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    const tripDetailsForEPODData =
      this.props.history?.location?.state?.tripDetailsForEPOD;
    return (
      <React.Fragment>
        <SecondaryEPODModal
          isOpen={this.state.showEPODModal}
          toggle={this.EPODModalToggle}
          tripDetailsForEPOD={tripDetailsForEPODData}
          mode={darkMode}
          acksubmit={this.acksubmit}
          epodDetails={this.state.epodDetails}
        />
        <SecondaryDamageShortageDetails
          id={this.state.tripID}
          isOpen={this.state.damageShortageModalToggle}
          toggle={this.openDamageShortageModal}
          closeModal={this.openDamageShortageModalClose}
          // lrStatus={this.props.lrStatus}
          tripDetailsForEPOD={tripDetailsForEPODData}
          darkTheme={darkMode}
          tripDetails={this.state.tripDetails}
          shipmentData={this.state.shipmentData}
          shipmentItemData={this.state.shipmentItemData}
          shipmentDataDamaged={this.state.shipmentDataDamaged}
          itemIndex={this.state.itemIndex}
          handleSubmitDamged={this.handleSubmitDamged}
          mode={darkMode}
        />
        <Container fluid>
          <Card
            style={{ width: "100%", padding: "1.5%" }}
            className="darkCardStyle mt-3"
          >
            <Row style={{ width: "100%" }}>
              <Col xs={11}>
                <div>
                  <span class="fontRoboto15 light__span">Trip Id :</span>{" "}
                  <span class="fontRoboto154 ml-2 dark__span">
                    {
                      this.props.history?.location?.state?.tripDetailsForEPOD
                        ?.tripId
                    }
                  </span>
                </div>
              </Col>
              <Col xs={1}>
                <img
                  src={playBtn}
                  alt="exp"
                  style={{ marginLeft: "75px" }}
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/secondary-trips",
                    })
                  }
                />
              </Col>
            </Row>
          </Card>
          <Row>
            <Col xl="12" className="p-1">
              <Card
                style={{ width: "100%", padding: "2%" }}
                className="darkCardStyle"
              >
                {this.props.shipmentItemDataStore?.length ? (
                  this.props.shipmentItemDataStore?.map((itemdata, index) => {
                    return (
                      <>
                        <div className="accordion">
                          <div className="accordion-item barDropDark">
                            <>
                              <div
                                className="accordion-item-header"
                                key={index}
                                onClick={() => {
                                  if (this.state.currentIndex === index) {
                                    this.setState(
                                      {
                                        currentIndex: "",
                                      },
                                      () => {}
                                    );
                                  } else {
                                    this.setState(
                                      {
                                        currentIndex: index,
                                      },
                                      () => {}
                                    );
                                  }
                                }}
                              >
                                <Row style={{ width: "100%" }}>
                                  <Col xs={10}>
                                    <Row>
                                      <span class="fontRoboto15  ml-4 light__span">
                                        Customer:
                                      </span>{" "}
                                      <span class="fontRoboto154 ml-2 dark__span">
                                        {" "}
                                        {itemdata?.customerName}
                                      </span>
                                      <span class="fontRoboto15  ml-4 light__span">
                                        Ship To Code:
                                      </span>{" "}
                                      <span class="fontRoboto154 ml-2 dark__span">
                                        {" "}
                                        {itemdata?.shipToCode}
                                      </span>
                                      <span class="fontRoboto15  ml-4 light__span">
                                        EPOD Status:
                                      </span>{" "}
                                      <span class="fontRoboto154 ml-2 dark__span">
                                        {" "}
                                        {itemdata.status === "epod_completed"
                                          ? "Completed"
                                          : itemdata.status ===
                                            "epod_in_progress"
                                          ? "EPOD in progress"
                                          : "EPOD not started"}
                                      </span>
                                    </Row>
                                  </Col>
                                  <Col xs={2}>
                                    <div className="">
                                      {this.state.currentIndex === index ? (
                                        <Button
                                          outline
                                          color="primary"
                                          className="accordionIcon"
                                          onClick={() => {
                                            this.setState({
                                              currentIndex: "",
                                            });
                                          }}
                                        >
                                          <img
                                            style={{
                                              margin: "-4px",
                                            }}
                                            src={
                                              darkMode ? minusIcon : minusIcon
                                            }
                                            alt="exp"
                                          />
                                        </Button>
                                      ) : (
                                        <Button
                                          outline
                                          color="primary"
                                          className="accordionIcon"
                                          onClick={() => {
                                            this.setState({
                                              currentIndex: index,
                                            });
                                          }}
                                        >
                                          <img
                                            style={{
                                              margin: "-4px",
                                            }}
                                            src={
                                              darkMode ? plusDarkIcon : plusIcon
                                            }
                                            alt="exp"
                                          />
                                        </Button>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div class="accordion-item-body">
                                <>
                                  {this.state.currentIndex === index && (
                                    <>
                                      <div class="accordion-item-body-content darkCardStyle">
                                        {this.state.currentIndex === index &&
                                          itemdata?.shipmentDetails.map(
                                            (shipNo, indexShip) => (
                                              <section class="column-container">
                                                <div className="accordion">
                                                  <div className="accordion-item barDropDark">
                                                    <>
                                                      <div
                                                        className="accordion-item-header"
                                                        key={indexShip}
                                                        onClick={() => {
                                                          if (
                                                            this.state
                                                              .currentShipIndex ===
                                                            indexShip
                                                          ) {
                                                            this.setState(
                                                              {
                                                                currentShipIndex:
                                                                  "",
                                                              },
                                                              () => {}
                                                            );
                                                          } else {
                                                            this.setState(
                                                              {
                                                                currentShipIndex:
                                                                  indexShip,
                                                              },
                                                              () => {}
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        <Row
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <Col xs={10}>
                                                            <Row>
                                                              <span class="fontRoboto15  ml-4 light__span">
                                                                Shipment:
                                                              </span>{" "}
                                                              <span class="fontRoboto154  ml-2 dark__span">
                                                                {" "}
                                                                {
                                                                  shipNo?.shipmentId
                                                                }
                                                              </span>
                                                              <span class="fontRoboto15  ml-4 light__span">
                                                                SF Number:
                                                              </span>{" "}
                                                              <span class="fontRoboto154  ml-2 dark__span">
                                                                {" "}
                                                                {
                                                                  shipNo?.sfNumber
                                                                }
                                                              </span>
                                                            </Row>
                                                          </Col>

                                                          <Col xs={2}>
                                                            <div className="">
                                                              {this.state
                                                                .currentShipIndex ===
                                                              indexShip ? (
                                                                <Button
                                                                  outline
                                                                  color="primary"
                                                                  className="accordionIcon"
                                                                  onClick={() => {
                                                                    this.setState(
                                                                      {
                                                                        currentShipIndex:
                                                                          "",
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      margin:
                                                                        "-4px",
                                                                    }}
                                                                    src={
                                                                      darkMode
                                                                        ? minusIcon
                                                                        : minusIcon
                                                                    }
                                                                    alt="exp"
                                                                  />
                                                                </Button>
                                                              ) : (
                                                                <Button
                                                                  outline
                                                                  color="primary"
                                                                  className="accordionIcon"
                                                                  onClick={() => {
                                                                    this.setState(
                                                                      {
                                                                        currentShipIndex:
                                                                          indexShip,
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      margin:
                                                                        "-4px",
                                                                    }}
                                                                    src={
                                                                      darkMode
                                                                        ? plusDarkIcon
                                                                        : plusIcon
                                                                    }
                                                                    alt="exp"
                                                                  />
                                                                </Button>
                                                              )}
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                      <div class="accordion-item-body">
                                                        {this.state
                                                          .currentShipIndex ===
                                                          indexShip &&
                                                          shipNo.items.map(
                                                            (ele, i) => (
                                                              <div class="accordion-item-body-content darkCardStyle">
                                                                <section class="column-container">
                                                                  <Row
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                  >
                                                                    <Col
                                                                      xs={11}
                                                                    >
                                                                      <div>
                                                                        <span class="fontRoboto15  ml-4 light__span">
                                                                          Item
                                                                          Id:&nbsp;
                                                                        </span>{" "}
                                                                        <span class="fontRoboto154  ml-2 dark__span">
                                                                          {" "}
                                                                          {
                                                                            ele.itemId
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                      <div>
                                                                        <span class="fontRoboto15  ml-4 light__span">
                                                                          Item
                                                                          Description:&nbsp;
                                                                        </span>{" "}
                                                                        <span class="fontRoboto154  ml-2 dark__span">
                                                                          {" "}
                                                                          {
                                                                            ele.description
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                    </Col>
                                                                    <Col xs={1}>
                                                                      <img
                                                                        src={
                                                                          playBtn
                                                                        }
                                                                        alt="exp"
                                                                        style={{
                                                                          marginLeft:
                                                                            "75px",
                                                                        }}
                                                                        onClick={() =>
                                                                          this.itemData(
                                                                            index,
                                                                            ele,
                                                                            this
                                                                              .state
                                                                              .shipmentData[
                                                                              index
                                                                            ]
                                                                          )
                                                                        }
                                                                      />
                                                                    </Col>
                                                                  </Row>
                                                                </section>
                                                              </div>
                                                            )
                                                          )}
                                                      </div>
                                                    </>
                                                  </div>
                                                </div>
                                              </section>
                                            )
                                          )}
                                        <section class="column-container">
                                          {this.state.currentIndex ===
                                            index && (
                                            // <div className="m-0">
                                            <>
                                              <Button
                                                style={{
                                                  width: "140px",
                                                  backgroundColor: "#0AB38C",
                                                  border: "none",
                                                  float: "right",
                                                }}
                                                disabled={
                                                  itemdata.status ===
                                                  "epod_completed"
                                                    ? true
                                                    : itemdata.status ===
                                                      "epod_in_progress"
                                                    ? false
                                                    : true
                                                }
                                                className=""
                                                onClick={() =>
                                                  // this.handleSubmitTrip(index)
                                                  this.EPODModalToggle(index)
                                                }
                                              >
                                                {/* {epodFlag
                                          ? "Trip Complete"
                                          : "EPOD Complete"} */}
                                                Complete EPOD
                                              </Button>
                                              {/* <Button
                                                style={{
                                                  width: "140px",
                                                  backgroundColor: "#0AB38C",
                                                  border: "none",
                                                  float: "right",
                                                  marginRight: "12px",
                                                }}
                                                // disabled={
                                                //   this.state.tripDetailsDisable[
                                                //     index
                                                //   ]?.disableCompleteEpod
                                                // }
                                                className=""
                                                onClick={() =>
                                                  this.EPODModalToggle(index)
                                                }
                                              >
                                                Acknowledge LR */}
                                              {/* {epodFlag
                                          ? "Trip Complete"
                                          : "EPOD Complete"} */}
                                              {/* </Button> */}
                                              {/* <div
                                                className="m-2"
                                                style={{
                                                  width: "151px",
                                                }}
                                              >
                                                <Button
                                                  type="button"
                                                  // color="secondary"
                                                  className="small align-middle mb-1 rounded-black  buttomClr"
                                                  onClick={() =>
                                                    this.EPODModalToggle()
                                                  }
                                                >
                                                  Acknowledge LR
                                                </Button>
                                              </div> */}
                                            </>
                                            // </div>
                                          )}
                                        </section>
                                      </div>
                                    </>
                                  )}
                                </>
                              </div>
                            </>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <Card
                    className="darkBgQuickFilter"
                    style={{
                      height: "600px",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          position: "relative",
                          top: "300px",
                        }}
                        className="d-flex justify-content-center fontRoboto154 light__span"
                      >
                        No Shipment Available{" "}
                      </span>
                    </div>
                  </Card>
                )}
              </Card>
            </Col>
          </Row>

          {this.state.shipmentData?.length >= 0 ? (
            <>
              <Row
                className="float-bottom"
                style={{
                  paddingTop: "0px",
                }}
              >
                <Col xs={12}>
                  <Card
                    className="addAgreementCardBottom indentExpiry"
                    style={{
                      height: this.state.tripCompleteError ? "90px" : "69px",
                    }}
                  >
                    <Row
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Col xs={4} md={7}>
                        <div className="d-flex ml-4">
                          <div className="m-2">
                            <Button
                              style={{
                                width: "150px",
                              }}
                              color="primary"
                              className="darkAgreementAddToList button-wide buttonClrS "
                              onClick={() => this.handleSubmitTripComplete()}
                            >
                              Trip Complete
                            </Button>
                          </div>
                        </div>
                        <div
                          style={{
                            color: "red",
                            marginLeft: "28px",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          {this.state.tripCompleteError}
                        </div>
                      </Col>
                      {/* <Col xs={6} md={4}>
                        <div className="float-right">
                          <div className=" d-flex flex-row">
                            <div>
                              <span
                                className="fontRoboto15 light__span pr-2"
                                style={{
                                  position: "relative",
                                  top: "10px",
                                }}
                              >
                                Add UnLoading Charges
                              </span>
                            </div>
                            <div>
                              <span>
                                <input
                                  style={{
                                    height: "30px",
                                    backgroundColor: darkMode ? "#2e2e46" : "",
                                    border: darkMode ? "1px solid #6e6969" : "",
                                  }}
                                  type="number"
                                  className=" mt-2 CardInputFields darkTexBox dark__span"
                                  value={this.state.unloadingCharge}
                                  onChange={(e) => {
                                    this.handleChangeCharge(e);
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col> */}
                      {/* <Col xs={2} md={1}>
                         <div className="d-flex justify-content-end">
                          <div className="">
                            <Button
                              color="primary"
                              className="darkAgreementAddToList button-wide buttonClrS "
                            >
                              save
                            </Button>
                          </div>
                        </div>
                      </Col> */}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.Trip.trips.trips,
    fetchId: state.Trip.trips.fetchId,
    vehicleType: state.VehicleType.vehicleType,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
    shipmentItemDataStore: state?.Trip?.trips?.shipmentPayloadData,
  };
};

export default connect(mapStateToProps, {
  fetchSignedURL_LRFront,
  fetchShipmentTripById,
  tripEpodSecondary,
  storeShipmentData,
  tripEpodCompleteSecondary,
  tripCompleteSecondary,
  acknowledgeLRSecondary,
  fetchTripById,
})(SecondaryEPODIndex);
