import React, { Component } from "react";
//import from libraries
import { Row, Col, Card, Button } from "reactstrap";
import { Grid } from "@material-ui/core";
import { InputGroup } from "rsuite";
import Select, { components } from "react-select";
import Datepicker from "../../components/Common/DatePicker/DatePicker";
import download from "./assets/download.png";
import downloadDark from "./assets/downloadDark.png";
import refreshIcon from "./assets/refresh-cw.png";
// import { padding } from "@mui/system";

// import "rsuite/dist/rsuite.min.css";
// import "../Dashboard/OperationsDashboard/Styles/dashboard.css";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
// const MyIcon = React.forwardRef((props, ref) => {
//   return (
//     <img  className="reportsListStyle" src={darkMode?calendarDarkIcon:calendarIcon} alt="" />
//   );
// });

const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
      fromDate: null,
      toDate: null,
    };
  }
  handleClickAway = () => {
    if (this.state.showCalender === true) {
      this.setState({ showCalender: false });
    }
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  onChangeDatePicker = (value, label) => {
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
  };
  render() {
    darkMode = this.props?.mode;
    const customStyles1 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
        zIndex: 99999,
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: darkMode ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div
            className="quickStylesBar reportQuickStyleBar"
            style={{
              width: "105vw",
            }}
          >
            <Row className="vehiclAnalysisRow">
              <Col xs={0} md={2} lg={2}></Col>
              <Col xs={12} md={10} lg={10} className="">
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <div className="">
                      <Select
                        className="transpoter-react_selector"
                        classNamePrefix="react-select"
                        placeholder="Select Transporters..."
                        // autoFocus={true}
                        isSearchable
                        styles={customStyles1}
                        components={{ DropdownIndicator }}
                        selectProps={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                          >
                            <path
                              d="M12 12.906V11.583C12 10.8812 11.7212 10.2082 11.225 9.71201C10.7288 9.21579 10.0558 8.93701 9.35399 8.93701H4.06299C3.36123 8.93701 2.68821 9.21579 2.19199 9.71201C1.69577 10.2082 1.41699 10.8812 1.41699 11.583V12.906"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.5 6C7.88071 6 9 4.88071 9 3.5C9 2.11929 7.88071 1 6.5 1C5.11929 1 4 2.11929 4 3.5C4 4.88071 5.11929 6 6.5 6Z"
                              stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        style={{ width: "82%" }}
                        value={this.props.selectedTranspoterValues}
                        options={this.props.transpoterOptions}
                        onChange={this.props.onChangeTransporter}
                        onInputChange={(e) => this.props.filtersTranspoter(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Select
                      className="hopes-react_selector"
                      classNamePrefix="react-select"
                      placeholder="Select Source..."
                      // isMulti
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      isSearchable
                      styles={customStyles1}
                      // width="150px"
                      value={this.props.selectedSourceHopValues}
                      options={this.props.hopOptions}
                      onChange={this.props.onChangeSourceHop}
                      onInputChange={(e) => {
                        this.props.filtersHops(e);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Select
                      className="hopes-react_selector"
                      classNamePrefix="react-select"
                      placeholder="Select Customer..."
                      // isMulti
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      isSearchable
                      styles={customStyles1}
                      // width="150px"
                      value={this.props.selectedCustomerValues}
                      options={this.props.customerOptions}
                      onChange={this.props.onChangeCustomer}
                      onInputChange={(e) => this.props.filtersCustomer(e)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                    className="datePickerGrid"
                  >
                    <div
                      className="date-picker-div RsuiteDateRange dashboardDateRange"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className="datepicker-selector d-flex">
                        <InputGroup>
                          <Datepicker
                            mode={darkMode}
                            value={this.state.fromDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            placeholder={"Start Date"}
                            dashboard={true}
                          />
                          <Datepicker
                            mode={darkMode}
                            value={this.state.toDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            placeholder={"End Date"}
                            dashboard={true}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={1} lg={1}>
                    <Button color=""
                      onClick={() => {
                        this.setState(
                          {
                            dateValue: [null, null],
                            fromDate: null,
                            toDate: null,
                          },
                          () => {
                            this.props.reset();
                          }
                        );
                      }}
                      className="reset"
                    >
                      {this.state.userLangs?.common?.RESET}
                    </Button>
                  </Grid>
                  <Grid item xs="1">
                    <div className="tooltipIcon">
                      <Button
                      color=""
                        disabled={!navigator.onLine}
                        onClick={() => {
                          this.props.getAllExportData();
                        }}
                        className={`iconButtons ${
                          darkMode ? "actionButtonsDark" : ""
                        }`}
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={darkMode ? downloadDark : download}
                          alt="exp"
                        />
                      </Button>
                      <span
                        className="tooltipIcontext"
                        style={{ width: "55px" }}
                      >
                        &nbsp;
                        {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                          "Download Excel"}
                        &nbsp;
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
