import React, { Component } from "react";
import { Card, Row, Col, CardBody } from "reactstrap";
import { StatusIndicator } from "./StatusIndicator";
import moment from "moment";
export default class TripInfo extends Component {
  render() {
    const {
      tripDetails,
      driverPopulate,
      route,
      shareLink,
      formatDateLine,
      currentMenu,
      selectMenu,
      generatePdf,
    } = this.props;
    return (
      <div>
        <Card className="darkCardStyle p-2">
          <CardBody>
            <div>
              <Row>
                <Col md={8} sm={6} xs={12}>
                  <h4>
                    <span className="ml-2 dark__span">
                      {tripDetails?.tripId ? tripDetails?.tripId : "N/A"} Trip
                      Detail
                    </span>
                  </h4>
                </Col>

                <Col
                  md={4}
                  sm={6}
                  xs={12}
                  className="d-flex flex-column align-items-end"
                >
                  <div className="fontRoboto154 menu-span">
                    {" "}
                    {tripDetails?.subStatus ? (
                      <StatusIndicator status={tripDetails?.subStatus} />
                    ) : (
                      "N/A"
                    )}
                  </div>
                  <div className="p-1 light__span">
                    {tripDetails?.subStatusUpdatedAt
                      ? moment(tripDetails?.subStatusUpdatedAt).format(
                          "MMM-DD-YYYY ,  HH:mm A"
                        )
                      : "N/A"}
                  </div>
                </Col>
              </Row>
              <Row className="menu-element ml-0">
                <Col xs={12} md={1} className="mb-1 menu-child-element ">
                  <div
                    className="menu-span"
                    onClick={() => {
                      selectMenu("tripInfo");
                    }}
                  >
                    Trip Info
                  </div>
                  {currentMenu === "tripInfo" ? (
                    <div className="activeMenu"></div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xs={12} md={2} className="mb-1 menu-child-element ">
                  <div
                    className="menu-span"
                    onClick={() => {
                      selectMenu("transporterInfo");
                    }}
                  >
                    Transporter Info
                  </div>
                  {currentMenu === "transporterInfo" ? (
                    <div className="activeMenu"></div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xs={12} md={2} className="mb-1 menu-child-element ">
                  <div
                    className="menu-span"
                    onClick={() => {
                      generatePdf("Transporter Copy");
                    }}
                  >
                    Transporter Copy
                  </div>
                  {currentMenu === "transporterCopy" ? (
                    <div className="activeMenu"></div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xs={12} md={2} className="mb-1 menu-child-element ">
                  <div
                    className="menu-span"
                    onClick={() => {
                      generatePdf("Consignor Copy");
                    }}
                  >
                    Consignor Copy
                  </div>
                  {currentMenu === "consignorCopy" ? (
                    <div className="activeMenu"></div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xs={12} md={2} className="mb-1 menu-child-element ">
                  <div
                    className="menu-span"
                    onClick={() => {
                      generatePdf("Consignee Copy");
                    }}
                  >
                    Consignee Copy
                  </div>
                  {currentMenu === "consigneeCopy" ? (
                    <div className="activeMenu"></div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <hr className="mt-2"></hr>
              {currentMenu === "tripInfo" ? (
                <div>
                  <Row className="p-2 mt-4">
                    <Col md={12}>
                      <span className="d-flex align-items-center">
                        <i
                          className="faa fa fa-file-image mr-2"
                          style={{
                            color: "darkcyan",
                            fontSize: "18px",
                          }}
                        ></i>
                        <h5 className="m-0 light__span">Details</h5>
                      </span>
                    </Col>
                    <Col md={12}>
                      <Row className="p-2 mt-3 ml-3">
                        <Col xs={12} md={2} className="mb-1">
                          <div className="filter">
                            <div className="m-0 fontRoboto1541 mr-2 light__span">
                              Trip ID
                            </div>
                            <span className="d-flex align-items-center">
                              <span className="m-0 fontRoboto618  dark__span mr-2 mt-2">
                                {tripDetails?.tripId
                                  ? tripDetails?.tripId
                                  : "N/A"}
                              </span>

                              <i
                                className="faa fa fa-copy  mt-2 "
                                style={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Copy to clipboard"
                                onClick={() => {
                                  shareLink(tripDetails?.tripId, "Trip Id");
                                }}
                              ></i>
                            </span>
                          </div>
                        </Col>
                        <Col xs={12} md={2} className="mb-1">
                          <div className="filter">
                            <div className="m-0 fontRoboto1541 mr-2 light__span">
                              Indent ID
                            </div>
                            <span className="d-flex align-items-center">
                              <span className="m-0 fontRoboto618 dark__span  mr-2 mt-2">
                                {tripDetails?.indentIdInOrder
                                  ? tripDetails?.indentIdInOrder
                                  : "N/A"}
                              </span>
                              <i
                                className="faa fa fa-copy  mt-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Copy to clipboard"
                                style={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  shareLink(
                                    tripDetails?.indentIdInOrder,
                                    "Indent Id"
                                  );
                                }}
                              ></i>
                            </span>
                          </div>
                        </Col>
                        <Col xs={12} md={8} className="mb-1">
                          <div className="filter">
                            <div className="m-0 fontRoboto1541 light__span mr-2">
                              Route
                            </div>
                            <span className="d-flex align-items-center">
                              <span className="m-0 mr-2 mt-2 dark__span">
                                {tripDetails?.via?.length ? (
                                  <>{route(tripDetails?.via)}</>
                                ) : (
                                  "DIRECT"
                                )}
                              </span>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="mt-4"></hr>

                  <Row style={{ marginTop: "25px" }}>
                    <Col md={12}>
                      <div className="col-full m-b">
                        <span className="d-flex align-items-center">
                          <i
                            className="faa fa fa-circle mr-2"
                            style={{
                              color: "darkcyan",
                              fontSize: "18px",
                            }}
                          ></i>
                          <p className="m-0 fontRoboto17 light__span">
                            Source:
                          </p>
                        </span>
                      </div>
                      <div
                        className="col-full m-b"
                        style={{ position: "relative" }}
                      >
                        <span className="d-flex align-items-center">
                          <div className="vertical-line ">
                            <p className="fontRoboto618 sorcVal dark__span">
                              {tripDetails?.source
                                ? tripDetails?.source?.label
                                : "N/A"}
                            </p>
                          </div>
                        </span>
                      </div>
                      <div className="col-full m-b">
                        <span className="d-flex align-items-center">
                          <i
                            className="fa fa-map-marker mr-2"
                            style={{
                              color: "darkcyan",
                              fontSize: "19px",
                            }}
                          ></i>
                          <p className="m-0 fontRoboto17 light__span">
                            Destination:
                          </p>
                        </span>
                      </div>
                      <div className="col-full m-b">
                        <span className="d-flex align-items-center">
                          <p className="fontRoboto618 sorcDestVal dark__span">
                            {tripDetails?.destination
                              ? tripDetails?.destination?.label
                              : "N/A"}
                          </p>
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "65px" }}>
                    <Col xs={12} md={6} className="mb-1">
                      <span className="d-flex align-items-center">
                        <i
                          className="fa fa-calendar mr-2"
                          style={{
                            color: "darkcyan",
                            fontSize: "19px",
                          }}
                        ></i>
                        <p className="m-0 fontRoboto17 light__span">
                          Trip Date
                        </p>
                      </span>
                      <div className="m-0 fontRoboto618 mt-4 ml-4 dark__span">
                        {tripDetails?.startTime
                          ? formatDateLine(tripDetails.startTime)
                          : "N/A"}
                      </div>
                    </Col>
                    {/* <Col xs={12} md={6} className="mb-1">
                      <div className="">
                        <div className="m-0 fontRoboto17 mr-2">
                          <p className="m-0 light__span">End Date</p>
                        </div>
                        <span className="d-flex align-items-center ">
                          <span className="m-0 fontRoboto618 mt-4 dark__span">
                            {tripDetails?.expiryTime
                              ? formatDateLine(tripDetails?.expiryTime)
                              : "N/A"}
                          </span>
                        </span>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              ) : currentMenu === "transporterInfo" ? (
                <div>
                  <Row className="p-2 mt-4">
                    <Col md={12}>
                      <span className="d-flex align-items-center">
                        <i
                          className="faa fa fa-file-image mr-2"
                          style={{
                            color: "darkcyan",
                            fontSize: "18px",
                          }}
                        ></i>
                        <h5 className="m-0 light__span">
                          {" "}
                          Transporter Details
                        </h5>
                      </span>
                    </Col>
                    <Col md={12}>
                      <Row className="p-2 mt-3 ml-3">
                        <Col xs={12} md={4} className="mb-1">
                          <div className="filter">
                            <p className="m-0 fontRoboto1541 font-weight-bold mr-2 light__span">
                              Transporter
                            </p>
                            <span className="d-flex align-items-center">
                              <span className="m-0  dark__span  mr-2 mt-2">
                                {tripDetails?.transporter
                                  ? tripDetails?.transporter?.name
                                  : "N/A"}
                              </span>
                            </span>
                          </div>
                        </Col>
                        <Col xs={12} md={4} className="mb-1">
                          <div className="filter">
                            <p className="m-0 fontRoboto1541 font-weight-bold mr-2 light__span">
                              Vehicle
                            </p>
                            <span className="d-flex align-items-center dark__span">
                              <span className="m-0    mr-2 mt-2">
                                <p
                                  style={{
                                    padding: "0px",
                                    margin: "0px",
                                  }}
                                >
                                  {tripDetails?.vehicle?.regNo}
                                </p>
                                <p
                                  style={{
                                    padding: "0px",
                                    margin: "0px",
                                  }}
                                >
                                  {tripDetails?.vehicle?.type?.label}
                                </p>
                              </span>
                            </span>
                          </div>
                        </Col>
                        <Col xs={12} md={4} className="mb-1">
                          <div className="filter">
                            <p className="m-0 fontRoboto1541 font-weight-bold mr-2 light__span">
                              Driver
                            </p>
                            <span className="d-flex align-items-center">
                              <span className="m-0 mr-2 mt-2 dark__span">
                                {tripDetails?.driver
                                  ? driverPopulate(tripDetails?.driver)
                                  : "N/A"}
                              </span>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}
