import { types } from "../actionTypes";
const initialState = {
  fetchId: "",
  creatorIndents: [],
  assignedIndents: [
    {
      Source: "asd",
      Customers: "ahgvs",
      vehicleType: "truck",
      transporter: "uhf",
      route: "abc",
      status: "Assigned",
    },
    {
      Source: "asd",
      Customers: "ahgvs",
      vehicleType: "truck",
      transporter: "uhf",
      route: "abc",
      status: "Accepted",
    },
  ],
  fetchIdSecondary: "",
  fetchIdMasterSecondary: "",
  FreightsMasterDataSecondary: [],
  CustomersMasterDataSecondary: [],
  fetchIdCustomerMasterSecondary: "",
  vehicleDetailPlacementDataSecondary: {},
  vehicleDetailPlacementDataForExcelSecondary: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SOURCE_SECONDARY_SUCCESS:
      return { ...state };

    case types.SET_CUSTOMER_DROPDOWN_SUCCESS:
      const indents = state.creatorIndents.data.docs.map((e) => {
        return { ...e, isDropdownOpen: false };
      });
      indents[action.payload].isDropdownOpen =
        !state.creatorIndents.data.docs[action.payload].isDropdownOpen;
      return {
        ...state,
        creatorIndents: {
          ...state.creatorIndents,
          data: {
            ...state.creatorIndents.data,
            docs: indents,
          },
        },
      };

    case types.SET_CUSTOMER_DROPDOWN_FALSE_SUCCESS:
      const indentsFalse = state.creatorIndents.data.docs.map((e) => {
        return { ...e, isDropdownOpen: false };
      });

      return {
        ...state,
        creatorIndents: {
          ...state.creatorIndents,
          data: {
            ...state.creatorIndents.data,
            docs: indentsFalse,
          },
        },
      };

    case types.SET_ASSIGNED_CUSTOMER_DROPDOWN_SUCCESS:
      const assignedIndent = state.assignedIndents.data.docs.map((e) => {
        return { ...e, isDropdownOpen: false };
      });

      assignedIndent[action.payload].isDropdownOpen =
        !state.assignedIndents.data.docs[action.payload].isDropdownOpen;
      return {
        ...state,
        assignedIndents: {
          ...state.assignedIndents,
          data: {
            ...state.assignedIndents.data,
            docs: assignedIndent,
          },
        },
      };

    case types.SET_ASSIGNED_CUSTOMER_DROPDOWN_FALSE_SUCCESS:
      const assignedIndentFalse = state.assignedIndents.data.docs.map((e) => {
        return { ...e, isDropdownOpen: false };
      });
      return {
        ...state,
        assignedIndents: {
          ...state.assignedIndents,
          data: {
            ...state.assignedIndents.data,
            docs: assignedIndentFalse,
          },
        },
      };

    case types.FETCH_INDENTS_SECONDARY_SUCCESS:
      const payload = action.payload;
      payload.data.docs = action.payload.data.docs.map((e) => {
        return { ...e, isDropdownOpen: false };
      });
      return {
        ...state,
        creatorIndents: payload,
        fetchId: "id" + new Date().getTime(),
      };

    case types.FETCH_ASSIGNED_INDENTS_SECONDARY_SUCCESS:
      const assignedPayload = action.payload;
      assignedPayload.data.docs = action.payload.data.docs.map((e) => {
        return { ...e, isDropdownOpen: false };
      });

      return {
        ...state,
        assignedIndents: assignedPayload,
        fetchIdSecondary: "id" + new Date().getTime(),
      };

    case types.SECONDARY_INDENT_STATUS_ACCEPT_SUCCESS:
      let newstate = { ...state };
      let assignedIndents = newstate.assignedIndents.data.docs;
      for (let indent of assignedIndents) {
        if (indent._id === action.payload.indentID) {
          indent.status = "Accepted";
          break;
        }
      }
      return newstate;

    case types.FREIGHTS_MASTER_SECONDARY_SUCCESS:
      return {
        ...state,
        FreightsMasterDataSecondary: action.payload,
        fetchIdMasterSecondary: "id" + new Date().getTime(),
      };

    case types.FETCH_CUSTOMERS_MASTER_SECONDARY_SUCCESS:
      return {
        ...state,
        CustomersMasterDataSecondary: action.payload,
        fetchIdCustomerMasterSecondary: "id" + new Date().getTime(),
      };

    case types.VEHICLE_PLACEMENT_DETAIL_REPORT_SECONDARY_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        vehicleDetailPlacementDataSecondary: action.payload,
        fetchId: "id" + new Date().getTime(),
      };

    case types.VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL_SECONDARY_SUCCESS:
      return {
        ...state,
        vehicleDetailPlacementDataForExcelSecondary: action.payload,
        fetchId: "id" + new Date().getTime(),
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};
