import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBDataTable,
} from "mdbreact";
import { Button } from "reactstrap";

var data = {};
// var rows = [];
class ModalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
    };
  }

  toggle = () => () => {
    this.setState({
      modal: !this.state.modal13,
    });
    this.props.showToggle();
  };

  componentDidMount = () => {
    //pass props to populate data
  };

  componentWillUnmount = () => {
    this.props.clearUpload();
  };

  render() {
    let cols = this.props.heads.map((ele) => {
      // console.log("eleeee", ele);
      return {
        label: ele,
        field: ele,
        sort: "asc",
        width: 270,
        align: "center",
      };
    });
    data = {
      columns: cols,
      rows: this.props.rows,
    };

    return (
      <MDBContainer className="text-capitalize">
        <MDBModal
          size={"xl"}
          isOpen={this.state.modal}
          toggle={this.toggle()}
          centered
        >
          <MDBModalHeader toggle={this.toggle()}>Indent List</MDBModalHeader>
          <MDBModalBody>
            {this.props.resolved ? (
              <MDBDataTable
                className="text-center"
                responsive
                striped
                bordered
                data={data}
                paging={false}
                searching={false}
                noBottomColumns
              />
            ) : (
              <h4 className="text-center">Validating Data...</h4>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <span style={{ color: "red" }}>
              *Failed Indents will not be uploaded.
            </span>
            <Button color="secondary" onClick={this.toggle()}>
              Close/Try Again
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.props.insertData();
              }}
            >
              Procees With Successfull Indents
            </Button>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default ModalPage;
