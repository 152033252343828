let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(localStorage.getItem("userLangDetail"));

  HeadersData = {
    columns: [
      {
        label: "Indent Id",
        field: "indentId",
        sort: "asc",
        width: 2,
      },
      {
        label: "Source Code",
        field: "sourceCode",
        sort: "asc",
        width: 3,
      },
      {
        label: "Source Name",
        field: "sourceName",
        sort: "asc",
        width: 3,
      },
      {
        label: "Destination Code",
        field: "destinationCode",
        sort: "asc",
        width: 2,
      },
      {
        label: "Destination Name",
        field: "destinationName",
        sort: "asc",
        width: 4,
      },
      {
        label: "Route Name",
        field: "routeName",
        sort: "asc",
        width: 3,
      },

      {
        label: "Vehicle Type",
        field: "vehicleType",
        sort: "asc",
        width: 3,
      },
      {
        label: "Transporter Name",
        field: "transporterName",
        sort: "asc",
        width: 4,
      },

      {
        label: "Lane Name",
        field: "laneName",
        sort: "asc",
        width: 2,
      },
      {
        label: "Lot Name",
        field: "lotName",
        sort: "asc",
        width: 2,
      },
      {
        label: "Indent Creation Date",
        field: "indentCreationDate",
        sort: "asc",
        width: 4,
      },

      {
        label: "Transporter Acceptance Expiry Date Time",
        field: "transporterAcceptanceExpiryDateTime",
        sort: "asc",
        width: 4,
      },

      {
        label: "Transporters Actual Accept Rej Date",
        field: "trransportersActualAcceptRejDateTime",
        sort: "asc",
        width: 4,
      },
      {
        label: "Transporters Acceptance Event Action taken",
        field: "transportersAcceptanceEventActiontaken",
        sort: "asc",
        width: 4,
      },
      {
        label: "Vehicle Reporting Time At Factory",
        field: "vehicleReportingTimeAtFactory",
        sort: "asc",
        width: 4,
      },
      {
        label: "Trip ID",
        field: "tripID",
        sort: "asc",
        width: 2,
      },
      {
        label: "Trip Creation Date",
        field: "tripCreationDate",
        sort: "asc",
        width: 4,
      },
      {
        label: "Arrival Date At Source",
        field: "markasArrivalDateAtSource",
        sort: "asc",
        width: 4,
      },
      {
        label: "Arrival Date At Source",
        field: "markasArrivalDateAtSource",
        sort: "asc",
        width: 4,
      },
      {
        label: "Quality Event Action Date",
        field: "qualityEventActionDate",
        sort: "asc",
        width: 4,
      },
      {
        label: "Quality Event Action Taken",
        field: "qualityEventActiontaken",
        sort: "asc",
        width: 4,
      },
      {
        label: "Trip Shipment Verification Start Date From Source",
        field: "tripShipmentVerificationStartdateTimefromsource",
        sort: "asc",
        width: 4,
      },
      {
        label: "Reporting Date At Via Location",
        field: "reportingDateTimeatvialocation",
        sort: "asc",
        width: 5,
      },
      {
        label: "Via Verification Date",
        field: "vialocationVerificationdate",
        sort: "asc",
        width: 5,
      },
      {
        label: "Arrival At Destination",
        field: "markasArrivalAtDestination",
        sort: "asc",
        width: 3,
      },
      {
        label: "EPOD Completed Date",
        field: "EPODcompleteddate",
        sort: "asc",
        width: 3,
      },

      {
        label: "Damage Count",
        field: "damageCount",
        sort: "asc",
        width: 2,
      },
      {
        label: "Shortage Count",
        field: "shortageCount",
        sort: "asc",
        width: 2,
      },
      {
        label: "Damage and Shortage Comment",
        field: "damageShortageComment",
        sort: "asc",
        width: 3,
      },
      {
        label: "Shipment Number",
        field: "shipmentNumbersText",
        sort: "asc",
        width: 2,
      },
      {
        label: "LR Number",
        field: "lrNumbers",
        sort: "asc",
        width: 2,
      },
      {
        label: "CFC",
        field: "cfc",
        sort: "asc",
        width: 1,
      },
      {
        label: "Quality Check Comment",
        field: "qualityCheckComment",
        sort: "asc",
        width: 3,
      },
      {
        label: "Trip Status",
        field: "tripStatus",
        sort: "asc",
        width: 4,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeadersData = {
  columns: [
    {
      label: "Indent Id",
      field: "indentId",
      sort: "asc",
      width: 2,
    },
    {
      label: "Source Code",
      field: "sourceCode",
      sort: "asc",
      width: 3,
    },
    {
      label: "Source Name",
      field: "sourceName",
      sort: "asc",
      width: 3,
    },
    {
      label: "Destination Code",
      field: "destinationCode",
      sort: "asc",
      width: 2,
    },
    {
      label: "Destination Name",
      field: "destinationName",
      sort: "asc",
      width: 4,
    },
    {
      label: "Route Name",
      field: "routeName",
      sort: "asc",
      width: 3,
    },

    {
      label: "Vehicle Type",
      field: "vehicleType",
      sort: "asc",
      width: 3,
    },
    {
      label: "Transporter Name",
      field: "transporterName",
      sort: "asc",
      width: 4,
    },

    {
      label: "Lane Name",
      field: "laneName",
      sort: "asc",
      width: 2,
    },
    {
      label: "Lot Name",
      field: "lotName",
      sort: "asc",
      width: 2,
    },
    {
      label: "Indent Creation Date",
      field: "indentCreationDate",
      sort: "asc",
      width: 4,
    },

    {
      label: "Transporter Acceptance Expiry Date Time",
      field: "transporterAcceptanceExpiryDateTime",
      sort: "asc",
      width: 4,
    },

    {
      label: "Transporters Actual Accept Rej Date",
      field: "trransportersActualAcceptRejDateTime",
      sort: "asc",
      width: 4,
    },
    {
      label: "Transporters Acceptance Event Action taken",
      field: "transportersAcceptanceEventActiontaken",
      sort: "asc",
      width: 4,
    },
    {
      label: "Vehicle Reporting Time At Factory",
      field: "vehicleReportingTimeAtFactory",
      sort: "asc",
      width: 4,
    },
    {
      label: "Trip ID",
      field: "tripID",
      sort: "asc",
      width: 2,
    },
    {
      label: "Trip Creation Date",
      field: "tripCreationDate",
      sort: "asc",
      width: 4,
    },
    {
      label: "Arrival Date At Source",
      field: "markasArrivalDateAtSource",
      sort: "asc",
      width: 4,
    },
    {
      label: "Arrival Date At Source",
      field: "markasArrivalDateAtSource",
      sort: "asc",
      width: 4,
    },
    {
      label: "Quality Event Action Date",
      field: "qualityEventActionDate",
      sort: "asc",
      width: 4,
    },
    {
      label: "Quality Event Action Taken",
      field: "qualityEventActiontaken",
      sort: "asc",
      width: 4,
    },
    {
      label: "Trip Shipment Verification Start Date From Source",
      field: "tripShipmentVerificationStartdateTimefromsource",
      sort: "asc",
      width: 4,
    },
    {
      label: "Reporting Date At Via Location",
      field: "reportingDateTimeatvialocation",
      sort: "asc",
      width: 5,
    },
    {
      label: "Via Verification Date",
      field: "vialocationVerificationdate",
      sort: "asc",
      width: 5,
    },
    {
      label: "Arrival At Destination",
      field: "markasArrivalAtDestination",
      sort: "asc",
      width: 3,
    },
    {
      label: "EPOD Completed Date",
      field: "EPODcompleteddate",
      sort: "asc",
      width: 3,
    },

    {
      label: "Damage Count",
      field: "damageCount",
      sort: "asc",
      width: 2,
    },
    {
      label: "Shortage Count",
      field: "shortageCount",
      sort: "asc",
      width: 2,
    },
    {
      label: "Damage and Shortage Comment",
      field: "damageShortageComment",
      sort: "asc",
      width: 3,
    },
    {
      label: "Shipment Number",
      field: "shipmentNumbersText",
      sort: "asc",
      width: 2,
    },
    {
      label: "LR Number",
      field: "lrNumbers",
      sort: "asc",
      width: 2,
    },
    {
      label: "CFC",
      field: "cfc",
      sort: "asc",
      width: 1,
    },
    {
      label: "Quality Check Comment",
      field: "qualityCheckComment",
      sort: "asc",
      width: 3,
    },
    {
      label: "Trip Status",
      field: "tripStatus",
      sort: "asc",
      width: 4,
    },
  ],
};

export const searchBar = {
  searchBarData: [],
};
