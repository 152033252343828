import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { Row, Col, FormGroup } from "reactstrap";
import { Button } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { IndentStatusCancel } from "../store/indent/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class CancelIndentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remark: "",
      confirm: false,
      isSubmitting: false,
      buttonContent: "",
      cancelled: false,
      submitDisable: true,
    };
  }
  handleSubmit = () => {
    this.setState({ confirm: !this.state.confirm }, () => {});
  };

  handleConfirm = () => {
    // console.log("this.props.indentIndex", this.props.indentIndex);
    this.setState({ submitDisable: true, buttonContent: "Processing..." });
    let values = {
      indentID: this.props.indentIndex,
      remark: this.state.remark,
    };
    this.props.IndentStatusCancel({
      values,
      callback: (res) => {
        this.setState({ submitDisable: false, buttonContent: "Ok" });
        if (res && res.message === "Successfully cancelled") {
          // this.setState({
          //   isSubmitting: !this.state.isSubmitting,
          //   buttonContent: "Ok",
          // });

          NotificationManager.success("Cancelled Successfully", "Success");
          this.props.refreshData();
          this.props.toggle();
          this.setState({ cancelled: true }, () => {
            if (this.state.cancelled) {
              this.setState({
                confirm: false,
                cancelled: false,
                buttonContent: "",
                remark: "",
                submitDisable: true,
              });
            }
          });
        } else {
          NotificationManager.error("Please Check the Data Again", "Error");
          this.setState({
            confirm: false,
            cancelled: false,
            buttonContent: "",
            remark: "",
            submitDisable: true,
          });
          this.props.toggle();
        }
      },
    });
  };
  handleChangeRemark = (e) => {
    this.setState({ remark: e.target.value }, () => {
      if (this.state.remark.length > 0) {
        this.setState({ submitDisable: false });
      } else {
        this.setState({ submitDisable: true });
      }
    });
  };
  handleReset = () => {
    this.setState({
      submitDisable: true,
      remark: "",
    });
  };
  componentDidUpdate = () => {
    let pathname = this.props.location;
    if (
      this.state.cancelled &&
      pathname.pathname == "/secondary-indent-planning"
    ) {
      this.props.history.push("/secondary-indent-planning");
    } else if (
      this.state.cancelled &&
      pathname.pathname !== "/indent-planning"
    ) {
      this.props.history.push("/indent-planning");
    }
  };

  render() {
    // console.log("IndentId ", this.props.IndentId);
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    var darkMode = this.props?.mode;
    return (
      <React.Fragment>
        <div className="cancelIndent">
          <MDBContainer className="">
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={() => this.props.toggle()}
              centered
              className="CanelIndentModal"
            >
              <MDBModalHeader
                toggle={this.toggle}
                style={{
                  borderBottom: "0 none",
                }}
              >
                <i
                  className="CancelIndentCloseIcon p-3 close"
                  onClick={() => this.props.toggle()}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L1 13"
                      stroke={darkMode ? "#b7a9ff" : "#2800FC"}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L13 13"
                      stroke={darkMode ? "#b7a9ff" : "#2800FC"}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                <span className="modalTitle fontRoboto">
                  {userLang?.planning?.CANCEL_INDENT}
                </span>
                <div>
                  <div
                    className="fontRoboto1541 pt-2 dark__span"
                    style={{
                      color: "#05284E",
                    }}
                  >
                    <div>
                      {userLang?.planning?.INDENT_ID} :{"  "}
                      {this.props.IndentId}
                    </div>
                  </div>
                </div>
              </MDBModalHeader>
              <MDBModalBody>
                {this.state.confirm === true ? (
                  <span className="fontRoboto15">
                    <b className="text-danger">
                      {userLang?.planning?.INDENT_CANCEL_WARNING}
                    </b>
                  </span>
                ) : (
                  <Row>
                    <Col lg="12">
                      <FormGroup className="select2-container">
                        <label htmlFor="exampleFormControlTextarea1">
                          <span className="fontRoboto15">
                            <b className="light__span">
                              {userLang?.planning?.CANCEL_REASONS}{" "}
                            </b>
                          </span>
                        </label>
                        <textarea
                          className="form-control fontRoboto1541 darkTexBox"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          placeholder="Type Reason Here"
                          value={this.state.remark ? this.state.remark : ""}
                          onChange={this.handleChangeRemark}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </MDBModalBody>
              {this.state.confirm === true ? (
                <MDBModalFooter className="modalFooterStyle">
                  <Button
                    className="resetButtonStyle"
                    style={{ backgroundColor: "#0AB38C", color: "white" }}
                    onClick={() => this.handleSubmit()}
                  >
                    {userLang?.common?.BACK}
                  </Button>
                  <Button
                    className="submitButtonStyle"
                    style={{
                      backgroundColor: this.props.mode ? "#5E40FF" : "#05284E",
                      color: "white",
                    }}
                    onClick={this.handleConfirm}
                    disabled={this.state.isSubmitting}
                  >
                    {this.state.buttonContent.length > 0
                      ? this.state.buttonContent
                      : userLang?.common?.OK}
                  </Button>
                </MDBModalFooter>
              ) : (
                <MDBModalFooter className="modalFooterStyle">
                  <Button
                    className="resetButtonStyle"
                    style={{ backgroundColor: "#0AB38C", color: "white" }}
                    onClick={() => this.handleReset()}
                  >
                    {userLang?.common?.RESET}
                  </Button>
                  <Button
                    className="submitButtonStyle"
                    style={{
                      backgroundColor: this.props.mode ? "#5E40FF" : "#05284E",
                      color: "white",
                    }}
                    onClick={this.handleSubmit}
                    disabled={this.state.submitDisable}
                  >
                    {userLang?.common?.SUBMIT}
                  </Button>
                </MDBModalFooter>
              )}
            </MDBModal>
          </MDBContainer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    indents: state.Indent.indents.assignedIndents,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    IndentStatusCancel,
  })
)(CancelIndentModal);
