import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import exportIcon from "../Trips/assets/external-link.png";
import exportDarkIcon from "../Trips/assets/external-linkDark.png";
import refreshIcon from "./assets/refresh-cw.png";
import plusIcon from "./assets/plus.png";
import plusDarkIcon from "../Trips/assets/plusDark.png";
import Select from "react-select";
import { Grid } from "@material-ui/core";
import { InputGroup } from "rsuite";
import Datepicker from "../../components/Common/DatePicker/DatePicker";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
const consentData = [
  { label: "Untracked Trips", value: "Untracked Trips" },
  {
    label: "Untracked with Consent",
    value: "Untracked with Consent",
  },
  {
    label: "Untracked without Consent",
    value: "Untracked without Consent",
  },
];
class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
      fromDate: null,
      toDate: null,
    };
  }
  handleClickAway = () => {
    if (this.state.showCalender === true) {
      this.setState({ showCalender: false });
    }
  };
  handleOpen = () => {
    this.setState({ showCalender: true });
  };
  handleClose = () => {
    this.setState({ showCalender: false });
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  onChangeDatePicker = (value, label) => {
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.quickFilters("date", [
            this.state.fromDate,
            this.state.toDate,
          ]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.quickFilters("date", [
            this.state.fromDate,
            this.state.toDate,
          ]);
        }
      });
    }
  };
  render() {
    darkMode = this.props?.mode;
    const customStyles1 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: darkMode ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };

    return (
      <React.Fragment>
        <Card className={`${darkMode ? "darkBgQuickFilter mb-3" : "mb-3"}`}>
          <div
            className={`quickStylesBar trip-quick-filters ${
              darkMode ? "darkBgQuickFilter" : ""
            }`}
          >
            <Row>
              <Col xs={9} md={9}>
                <div className="d-flex flex-row">
                  <Button
                    // variant="primary"
                    color=""
                    onClick={() => this.props.quickFilters("delayed")}
                    // className="delayed mr-2"
                    className={
                      darkMode
                        ? `${
                            this.props.activeTripDelayed === true
                              ? "activeDarkStyle mr-2"
                              : "delayDarkStyle mr-2"
                          }`
                        : `${
                            this.props.activeTripDelayed === true
                              ? "activeTripDelayed mr-2"
                              : "delayed  mr-2"
                          }`
                    }
                  >
                    {this.state.userLangs?.common?.DELAYED}
                  </Button>
                  {/* <Col xs={12} md={4} lg={2} className=""> */}
                  <Select
                    className="consent-react_selector pr-2"
                    classNamePrefix="react-select"
                    placeholder="Select ..."
                    isMulti={false}
                    // autoFocus={true}
                    styles={customStyles1}
                    value={this.props.consent}
                    isSearchable={true}
                    options={consentData}
                    onChange={(e) => {
                      this.props.quickFilters(e?.value);
                    }}
                  />
                  {/* </Col> */}
                  <Button
                    className="desktop-reset reset"
                    onClick={() => {
                      this.setState(
                        {
                          dateValue: [null, null],
                          fromDate: null,
                          toDate: null,
                        },
                        () => {
                          this.props.reset();
                        }
                      );
                    }}
                  >
                    {this.state.userLangs?.common?.RESET}
                  </Button>
                  <Button
                    className="mobile-reset reset"
                    onClick={() =>
                      this.setState(
                        {
                          dateValue: [null, null],
                          fromDate: null,
                          toDate: null,
                        },
                        () => {
                          this.props.reset();
                        }
                      )
                    }
                  >
                    <img
                      className="mobile-rest-img"
                      src={refreshIcon}
                      alt=""
                    ></img>
                  </Button>
                </div>
              </Col>
              <Col
                xs={3}
                md={3}
                // style={{ textAlignLast: "end" }}
                className="tripMobileHeader"
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    className="datePickerGrid"
                  >
                    <div
                      className="date-picker-div RsuiteDateRange dashboardDateRange"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className="datepicker-selector">
                        <InputGroup>
                          <Datepicker
                            mode={darkMode}
                            value={this.state.fromDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={true}
                            toDate={false}
                            placeholder={"Start Date"}
                            dashboard={true}
                          />
                          <Datepicker
                            mode={darkMode}
                            value={this.state.toDate}
                            onChangeDatePicker={this.onChangeDatePicker}
                            fromDate={false}
                            toDate={true}
                            placeholder={"End Date"}
                            dashboard={true}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <div style={{ marginLeft: "22px" }}>
                      <div className="tooltipIcon">
                        <Button
                          outline
                          color=""
                          className={`iconButtons ${
                            darkMode ? "iconDarkButtons" : ""
                          }`}
                          onClick={() => this.props.toggleTransporterForm()}
                        >
                          <img
                            style={{ margin: "-4px" }}
                            src={darkMode ? plusDarkIcon : plusIcon}
                            alt="exp"
                          />
                        </Button>
                        <span
                          className="tooltipIcontext"
                          style={{ width: "80px" }}
                        >
                          &nbsp;{this.state.userLangs?.planning?.ADD_INDENT}
                          &nbsp;
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs>
                    <div>
                      <div className="tooltipIcon">
                        <Button
                          color=""
                          disabled={!navigator.onLine}
                          onClick={() => this.props.toggleExcel()}
                          className={`iconButtons ${
                            darkMode ? "iconDarkButtons" : ""
                          }`}
                        >
                          <img
                            style={{ margin: "-4px" }}
                            src={darkMode ? exportDarkIcon : exportIcon}
                            alt="exp"
                          />
                        </Button>
                        <span
                          className="tooltipIcontext"
                          style={{ left: "10%" }}
                        >
                          {" "}
                          &nbsp;{" "}
                          {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                            "Download Excel"}
                          &nbsp;
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
