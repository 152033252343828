import React, { Component } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import {
  fetchSignedURL_LRFront,
  fetchSignedURL_LRBack,
  fetchSignedURL_UL_Receipts,
} from "../../../store/actions";
import {
  uploadAgreement,
  uploadImagesAws,
  fetchTripById,
} from "../../../store/actions";
// import files
import SecondaryACLRFrontCopy from "./SecondaryACLRFrontCopy";
import SecondaryACLRBackCopy from "./SecondaryACLRBackCopy";
import SecondaryACUnloadingReceipt from "./SecondaryACUnloadingReceipt";

// global variables
var darkMode;
var fileObj = [];
var fileArray = [];
var fileObjBackCopy = [];
var fileArrayBackCopy = [];
var fileObjUnloading = [];
var fileArrayUnloading = [];
class SecondaryEPODModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: [],
      selectedFileBackCopy: [],
      selectedFileUnloading: [],
      baseImage: [],
      baseImageBackCopy: [],
      baseImageUnloading: [],
      imagesFront: [],
      imagesBack: [],
      imagesUnloading: [],

      loading: false,
      signedURL: "",

      uploadData: "",
      fileFlag: false,
      lastUploadLength: 0,
      urlArrayValues: [],
      userLangs: undefined,
      imageArray: [],
      errors: {},
      LRFrontImages: [],
      LRBackImages: [],
      UnloadingImages: [],
      handleReset: false,
    };
    this.fromRef = React.createRef();
    this.fileInput = React.createRef();
  }

  fileUploadTrigger = () => {
    this.fileInput.current.click();
  };

  documentAssigner = (value) => {
    this.setState({ imageArray: value }, () => {
      // this.invoiceSubmit();
    });
  };
  validateValAdd = () => {
    const errorsAdd = {};
    let { imagesFront, imagesBack, imagesUnloading } = this.state;
    console.log(this.props.epodDetails[0]?.lrImages?.front?.length);
    // let image = this.state.urlArrayValues;
    if (
      imagesFront?.length === 0 &&
      this.props.epodDetails[0]?.lrImages?.front?.length === 0
    ) {
      errorsAdd.imagesFront = "Front Image is Required!";
    }
    if (
      imagesBack?.length === 0 &&
      this.props.epodDetails[0]?.lrImages?.back?.length === 0
    ) {
      errorsAdd.imagesBack = "Back Image Is Required!";
    }

    if (
      imagesUnloading?.length === 0 &&
      this.props.epodDetails[0]?.unloadingReceiptImages?.length === 0
    ) {
      errorsAdd.imagesUnloading = "Unloading Image Is Required!";
    }
    return errorsAdd;
  };
  uploadImage = async (e) => {
    const name = e.target.name;
    const file = e.target.files[0];
    if (name === "FrontCopy") {
      const base64 = await this.convertBase64(file);
      this.setState({
        baseImage: [...this.state.baseImage, base64],
      });
    } else if (name === "BackCopy") {
      const base64 = await this.convertBase64(file);
      this.setState({
        baseImageBackCopy: [...this.state.baseImageBackCopy, base64],
      });
    } else if (name === "UnloadingCopy") {
      const base64 = await this.convertBase64(file);
      this.setState({
        baseImageUnloading: [...this.state.baseImageUnloading, base64],
      });
    }
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  S3Upload = async (type) => {
    if (type.name === "front") {
      this.props.fetchSignedURL_LRFront(
        this.props.tripDetailsForEPOD._id,
        (res) => {
          for (
            let i = this.state.selectedFile.length - 1;
            i < this.state.selectedFile.length;
            i++
          ) {
            let softCopy = "";
            softCopy = res.presignedS3Url.split("?")[0];
            const formData = new FormData();
            formData.append(
              this.state.selectedFile[i],
              this.state.selectedFile[i].name
            );
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", this.state.selectedFile[i].type);
            this.props.uploadImagesAws(
              res.presignedS3Url,
              this.state.selectedFile[i],
              myHeaders,
              (resData) => {
                if (resData.status == 200) {
                  this.setState({
                    imagesFront: [...this.state.imagesFront, softCopy],
                  });
                } else {
                  NotificationManager.error("Failed to upload images", "Error");
                }
              }
            );
          }
        }
      );
    } else if (type.name === "back") {
      this.props.fetchSignedURL_LRBack(
        this.props.tripDetailsForEPOD._id,
        (res) => {
          for (
            let i = this.state.selectedFileBackCopy.length - 1;
            i < this.state.selectedFileBackCopy.length;
            i++
          ) {
            let softCopy = "";
            softCopy = res.presignedS3Url.split("?")[0];
            const formData = new FormData();
            formData.append(
              this.state.selectedFileBackCopy[i],
              this.state.selectedFileBackCopy[i].name
            );
            const myHeaders = new Headers();
            myHeaders.append(
              "Content-Type",
              this.state.selectedFileBackCopy[i].type
            );
            this.props.uploadImagesAws(
              res.presignedS3Url,
              this.state.selectedFileBackCopy[i],
              myHeaders,
              (resData) => {
                if (resData.status == 200) {
                  this.setState({
                    imagesBack: [...this.state.imagesBack, softCopy],
                  });
                } else {
                  NotificationManager.error("Failed to upload images", "Error");
                }
              }
            );
          }
        }
      );
    } else if (type.name === "unloading") {
      this.props.fetchSignedURL_UL_Receipts(
        this.props.tripDetailsForEPOD._id,
        (res) => {
          for (
            let i = this.state.selectedFileUnloading.length - 1;
            i < this.state.selectedFileUnloading.length;
            i++
          ) {
            let softCopy = "";
            softCopy = res.presignedS3Url.split("?")[0];
            const formData = new FormData();
            formData.append(
              this.state.selectedFileUnloading[i],
              this.state.selectedFileUnloading[i].name
            );
            const myHeaders = new Headers();
            myHeaders.append(
              "Content-Type",
              this.state.selectedFileUnloading[i].type
            );
            this.props.uploadImagesAws(
              res.presignedS3Url,
              this.state.selectedFileUnloading[i],
              myHeaders,
              (resData) => {
                if (resData.status == 200) {
                  this.setState({
                    imagesUnloading: [...this.state.imagesUnloading, softCopy],
                  });
                } else {
                  NotificationManager.error("Failed to upload images", "Error");
                }
              }
            );
          }
        }
      );
    }
  };
  fileUpload = (event) => {
    let name = event.target.name;
    if (name === "FrontCopy") {
      if (event.target.files.length) {
        this.uploadImage(event);
        fileObj.push(event.target.files);
        for (let i = 0; i < fileObj[0].length; i++) {
          fileArray.push(event.target.files["0"]);
        }
        this.setState({ selectedFile: fileArray, fileFlag: true }, () => {
          this.S3Upload({ name: "front" });
        });
      }
    } else if (name === "BackCopy") {
      if (event.target.files.length) {
        this.uploadImage(event);
        fileObjBackCopy.push(event.target.files);
        for (let i = 0; i < fileObjBackCopy[0].length; i++) {
          fileArrayBackCopy.push(event.target.files["0"]);
        }
        this.setState({ selectedFileBackCopy: fileArrayBackCopy }, () => {
          this.S3Upload({ name: "back" });
        });
      }
    } else if (name === "UnloadingCopy") {
      if (event.target.files.length) {
        this.uploadImage(event);
        fileObjUnloading.push(event.target.files);
        for (let i = 0; i < fileObjUnloading[0].length; i++) {
          fileArrayUnloading.push(event.target.files["0"]);
        }
        this.setState({ selectedFileUnloading: fileArrayUnloading }, () => {
          this.S3Upload({ name: "unloading" });
        });
      }
    }
  };
  deleteImage = (item, index, type) => {
    if (type?.name === "front") {
      let deleteImage = this.state.baseImage.filter((image, i) => index !== i);
      let deleteImageSelectedFiles = this.state.selectedFile.filter(
        (image, i) => index !== i
      );
      let deleteSignedUrl = this.state.imagesFront.filter(
        (image, i) => index !== i
      );
      this.setState({
        baseImage: deleteImage,
        selectedFile: deleteImageSelectedFiles,
        imagesFront: deleteSignedUrl,
      });
    } else if (type?.name === "back") {
      let deleteImage = this.state.baseImageBackCopy.filter(
        (image, i) => index !== i
      );
      let deleteImageSelectedFiles = this.state.selectedFileBackCopy.filter(
        (image, i) => index !== i
      );
      let deleteSignedUrl = this.state.imagesBack.filter(
        (image, i) => index !== i
      );
      this.setState({
        baseImageBackCopy: deleteImage,
        selectedFileBackCopy: deleteImageSelectedFiles,
        imagesBack: deleteSignedUrl,
      });
    } else if (type?.name === "unloading") {
      let deleteImage = this.state.baseImageUnloading.filter(
        (image, i) => index !== i
      );
      let deleteImageSelectedFiles = this.state.selectedFileUnloading.filter(
        (image, i) => index !== i
      );
      let deleteSignedUrl = this.state.imagesUnloading.filter(
        (image, i) => index !== i
      );
      this.setState({
        baseImageUnloading: deleteImage,
        selectedFileUnloading: deleteImageSelectedFiles,
        imagesUnloading: deleteSignedUrl,
      });
    }
  };

  handleReset = () => {
    this.setState({
      baseImage: [],
      baseImageBackCopy: [],
      baseImageUnloading: [],
      imagesFront: [],
      imagesBack: [],
      imagesUnloading: [],
      selectedFile: [],
      selectedFileBackCopy: [],
      selectedFileUnloading: [],
      errors: {},
    });
  };
  ackHandleSubmit = () => {
    this.setState({ errors: this.validateValAdd() }, () => {
      if (Object.keys(this.state.errors).length === 0) {
        this.props.acksubmit(
          this.state.imagesFront,
          this.state.imagesBack,
          this.state.imagesUnloading
        );
        this.props.toggle();
        this.handleReset();
      }
    });
  };
  render() {
    darkMode = this.props?.mode;
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: darkMode ? "#9D9D9D" : "#0C0C0C",
      left: "0px",
    };
    console.log(this.props.epodDetails[0]);
    return (
      <React.Fragment>
        <MDBContainer className="EpodAck">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggle()}
            size="lg"
            centered
            className=""
          >
            <MDBModalBody>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span style={headerStyle}>Acknowledge LR</span>
                </div>
                <svg
                  onClick={() => {
                    this.props.toggle();
                    // this.handleReset();
                    // this.props.getAllData();
                  }}
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1L1 13"
                    stroke="#2800FC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 1L13 13"
                    stroke="#2800FC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="mt-5">
                <span className="fontRoboto15 mb-2 light__span">
                  Attach LR Front Copy
                </span>
              </div>
              <SecondaryACLRFrontCopy
                fileUpload={this.fileUpload}
                baseImage={this.state.baseImage}
                deleteImage={this.deleteImage}
                errorFront={this.state.errors}
                frontSubmittedImage={this.props.epodDetails[0]?.lrImages?.front}
              ></SecondaryACLRFrontCopy>
              <div className="mt-3">
                <span className="fontRoboto15 mb-2 light__span light__span">
                  Attach LR Back Copy
                </span>
              </div>
              <SecondaryACLRBackCopy
                fileUpload={this.fileUpload}
                baseImage={this.state.baseImageBackCopy}
                deleteImage={this.deleteImage}
                errorBack={this.state.errors}
                backSubmittedImage={this.props.epodDetails[0]?.lrImages?.back}
              ></SecondaryACLRBackCopy>
              <div className="mt-3">
                <span className="fontRoboto15 mb-2 light__span">
                  Attach Unloading Receipt
                </span>
              </div>
              <SecondaryACUnloadingReceipt
                fileUpload={this.fileUpload}
                baseImage={this.state.baseImageUnloading}
                deleteImage={this.deleteImage}
                errorUnloading={this.state.errors}
                unloadingSubmittedImage={
                  this.props.epodDetails[0]?.unloadingReceiptImages
                }
              ></SecondaryACUnloadingReceipt>
              <div className=" mb-3 mt-3">
                <Button
                  className="resetButtonStyle fontRoboto15 dark__span"
                  style={{ backgroundColor: "#0AB38C", color: "white" }}
                  onClick={() => this.handleReset()}
                >
                  Reset
                </Button>
                <Button
                  className="submitButtonStyle fontRoboto15"
                  style={{
                    backgroundColor: "#05284E",
                    color: "white",
                  }}
                  onClick={() => {
                    this.ackHandleSubmit();

                    // this.props.toggleModel();
                    // this.props.getAllData();
                  }}
                >
                  Submit
                </Button>
              </div>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.Trip.trips.trips,
    fetchId: state.Trip.trips.fetchId,
    vehicleType: state.VehicleType.vehicleType,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
  };
};

export default connect(mapStateToProps, {
  fetchSignedURL_LRFront,
  fetchSignedURL_UL_Receipts,
  fetchSignedURL_LRBack,
  uploadAgreement,
  fetchTripById,
  uploadImagesAws,
})(SecondaryEPODModal);
