import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchTripById } from "../../store/trip/actions";
import { trackLocationTripById } from "../../store/trip/actions";
import { MDBContainer } from "mdbreact";
import SecondaryShipData from "./SecondaryShipData";

import { MDBModal, MDBModalBody } from "mdbreact";

class SecondaryShipmentDetailModel extends Component {
  constructor() {
    super();
    this.state = {
      tripId: "",
      id: "",
      shipment: [],
      collapse: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    // let tripId = this.props?.tripIds;
  };

  downloadImage = (image) => {
    fetch(image[0])
      .then((res) => res.blob())
      .then((blob) => {
        var element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = "image.jpg";
        element.click();
      });
  };
  toggle = (index) => {
    this.setState((prevState) => ({
      expandIndex: prevState.expandIndex !== index ? index : "",
    }));
  };

  render() {
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;
    return (
      <div className="shipModel">
        <React.Fragment>
          <MDBContainer className="text-capitalize">
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={() => this.props.toggleModel()}
              size="xl"
              position="top"
            >
              <MDBModalBody className="indentModal">
                <SecondaryShipData
                  id={this.props.tripIds}
                  closeModal={this.props.toggleModel}
                  lrStatus={this.props.lrStatus}
                  darkTheme={darkState}
                />
              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    tripById: state.Trip.trips.editTrip,
    locationtripById: state.Trip.trips.locationTrip,
  };
};
export default connect(mapStateToProps, {
  fetchTripById,
  trackLocationTripById,
})(SecondaryShipmentDetailModel);
