import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { fetchTripById, fetchLRCopy } from "../../store/trip/actions";
import { trackLocationTripById } from "../../store/trip/actions";
import formatDate from "../DateFormatter";
import { timeDiff } from "../../components/Common/utils";
import TripInfo from "./TripInfo";
import TripTransitionInfo from "./TripTransitionInfo";
import {
  DateIcon,
  QualityCheck,
  Completed,
  QualityCheckTick,
  ArrivedAtSource,
} from "../../components/Common/CommonIcons";
import { toPascalCase } from "../../components/Common/utils";
import { getLocalStorage } from "./RowHeaders/RowHeaders";
import "./tripDetails.css";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import QualityCheckModel from "./QualityCheckModel";
import TripDetailShipmentModel from "./ShipmentModel";
import TripHistoryModel from "./TripHistoryModel";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class TripDetails_newUI extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      tripTransitionLogs: [],

      showTripDetailModel: false,
      showShipDetailModel: false,
      showTripHistoryModel: false,
      tripDetailId: " ",
      lrStatus: " ",
      driverName: " ",
      transporterName: " ",
      tripIdHistory: " ",
      statusArray: [],
      currentMenu: "tripInfo",
      packagingType: [],
      transporterName: "",
      transporterAddress: "",
      transporterGSTNo: "",
      transporterCinNo: "",
      transporterPanNo: "",
      transporterPhNo: "",
      sourceName: "",
      sourceEwayBillNo: null,
      destinationName: "",
      destinationEwayBillNo: null,
      invoiceValue: null,
      bookingDate: "",
      expectedDeliveryDate: "",
      modeOfDispatch: "",
      consignorName: "",
      consignorAddress: " ",
      consignorGSTNo: "",
      consignorCity: "",
      consignorState: "",
      consignorZip: "",
      consignorPhoneNo: "",
      consigneeName: "",
      consigneeAddress: " ",
      consigneeGSTNo: "",
      consigneeCity: "",
      consigneeState: "",
      consigneeZip: "",
      consigneePhoneNo: "",
      noOfPkgs: null,
      packagingType: {},
      descriptionOfGoods: "",
      actualWeight: null,
      volumetricWeight: null,
      chargeableWeight: null,
      LDimension: null,
      BDimension: null,
      HDimension: null,
      invoiceNo: "",
      partNo: "",
      qty: null,
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    this.props.fetchTripById(
      this.props.history.location.state?.tripDetails._id,
      (res) => {
        this.setState(
          {
            tripTransitionLogs: res.data[0].tripStatusTransitionLogs,
            loading: false,
          },
          () => {
            this.organizer();
            this.props.fetchLRCopy(
              this.props.history.location.state?.tripDetails._id,
              (res) => {
                this.setState({
                  packagingType: [
                    {
                      label: "dummy packaging type",
                      value: { id: res?.data?.docs[0]?.packagingType },
                    },
                  ],
                  transporterName: res?.data?.docs[0]?.transporter?.name,
                  transporterAddress: res?.data?.docs[0]?.transporter?.address,
                  transporterGSTNo: res?.data?.docs[0]?.transporter?.gstNo,
                  transporterCinNo: res?.data?.docs[0]?.transporter?.cinNo,
                  transporterPanNo: res?.data?.docs[0]?.transporter?.panNo,
                  transporterPhNo: res?.data?.docs[0]?.transporter?.phone,
                  sourceName: res?.data?.docs[0]?.source?.name,
                  sourceEwayBillNo: Number(
                    res?.data?.docs[0]?.source?.ewayBillNo
                  ),
                  destinationName: res?.data?.docs[0]?.destination?.name,
                  destinationEwayBillNo: Number(
                    res?.data?.docs[0]?.destination?.ewayBillNo
                  ),
                  invoiceValue: res?.data?.docs[0]?.invoiceNo,
                  bookingDate: res?.data?.docs[0]?.bookingDate,
                  expectedDeliveryDate:
                    res?.data?.docs[0]?.expectedDeliveryDate,
                  modeOfDispatch: res?.data?.docs[0]?.modeOfDispatch,
                  consignorName: res?.data?.docs[0]?.consignor?.name,
                  consignorAddress: res?.data?.docs[0]?.consignor?.address,
                  consignorGSTNo: res?.data?.docs[0]?.consignor?.gstNo,
                  consignorCity: res?.data?.docs[0]?.consignor?.city,
                  consignorState: res?.data?.docs[0]?.consignor?.state,
                  consignorZip: res?.data?.docs[0]?.consignor?.zip,
                  consignorPhoneNo: res?.data?.docs[0]?.consignor?.phone,
                  consigneeName: res?.data?.docs[0]?.consignee?.name,
                  consigneeAddress: res?.data?.docs[0]?.consignee?.address,
                  consigneeGSTNo: res?.data?.docs[0]?.consignee?.gstNo,
                  consigneeCity: res?.data?.docs[0]?.consignee?.city,
                  consigneeState: res?.data?.docs[0]?.consignee?.state,
                  consigneeZip: res?.data?.docs[0]?.consignee?.zip,
                  consigneePhoneNo: res?.data?.docs[0]?.consignee?.phone,
                  noOfPkgs: res?.data?.docs[0]?.noOfPackages,
                  packagingType: res?.data?.docs[0]?.packagingType,
                  descriptionOfGoods: res?.data?.docs[0]?.description,
                  actualWeight: res?.data?.docs[0]?.actualWeight,
                  volumetricWeight: res?.data?.docs[0]?.volumetricWeight,
                  chargeableWeight: res?.data?.docs[0]?.chargeableWeight,
                  LDimension: res?.data?.docs[0]?.dimension?.length,
                  BDimension: res?.data?.docs[0]?.dimension?.width,
                  HDimension: res?.data?.docs[0]?.dimension?.height,
                  invoiceNo: res?.data?.docs[0]?.invoiceNo,
                  partNo: res?.data?.docs[0]?.partNo,
                  qty: res?.data?.docs[0]?.quantity,
                });
              }
            );
          }
        );
      }
    );
  };
  selectMenu = (menu) => {
    this.setState({ currentMenu: menu });
  };

  generatePdf = async (name) => {
    const htmlContent = ` <div class="pdf-main-container">
    <div class="logo-details-container">
      <div class="logo-container">
        <div class="logo-address-container">
          <div class="logo">LOGO</div>
          <div class="logo-address">
            PRICOL LOGISTICS PRIVATE LIMITED 965, HARITA CENTRE,
            COIMBATORE-641037
            <br />
            GST NO: 33AAJCP7495E1Z4
            <br />
            CIN NO: U63090TZ2018PTC030178
            <br />
            PAN NO: AAJCP7495E Mob: 91 9629558255
          </div>
        </div>
        <div class="booking-code-container">
          <div class="booking">Booking Code</div>
          <div class="booking-value"></div>
        </div>
      </div>
      <div class="details-container">
        <div class="origin-desti-main-container">
          <div class="origin-desti-container">
            <div class="origin">
              <div class="origin-label">ORIGIN</div>
              <div class="origin-value">${this.state.sourceName}</div>
            </div>
            <div class="desti">
              <div class="desti-label">DESTINATION</div>
              <div class="desti-value">${this.state.destinationName}</div>
            </div>
          </div>
          <div class="origin-desti-container">
            <div class="blank1">
              <div class="blank1-label">LR NUMBER</div>
              <div class="blank1-value">45625</div>
            </div>
            <div class="blank2">
              <div class="blank2-label"></div>
              <div class="blank2-value"></div>
            </div>
          </div>
        </div>
        <div class="origin-desti-main-container">
          <div class="origin-desti-container">
            <div class="desti">
              <div class="invoice-label">INVOICE VALUE</div>
              <div class="invoice-value">${this.state.invoiceValue}</div>
            </div>
            <div class="desti">
              <div class="desti-label">MODE OF DISPATCH</div>
              <div class="desti-value">${this.state.modeOfDispatch}</div>
            </div>
          </div>
          <div class="origin-desti-container">
            <div class="blank1">
              <div class="blank11"></div>
              <div class="blank12"></div>
            </div>
            <div class="blank2">
              <div class="blank21"></div>
              <div class="blank22"></div>
            </div>
          </div>
        </div>
        <div class="eway-pay-container">
          <div class="eway-label">E-WAYBILL NO</div>
          <div class="eway-value">
            ${this.state.destinationEwayBillNo}
          </div>
          <div class="pay-label">PAY / TO PAY / TBB</div>
        </div>
      </div>
    </div>
    <div class="consignor-consignee-container">
      <div class="consignor-details-container">
        <div class="consignor-container">
          <div class="consignor">
            <div class="consignor-label">CONSIGNOR :-</div>
            <div class="consignor-value">${this.state.consignorName}</div>
          </div>
          <div class="address">
            <div class="address-label">ADDRESS :</div>
            <div class="address-value">
              ${this.state.consignorAddress}
            </div>
          </div>
        </div>
        <div class="city-state">
          <div class="city">
            <div class="city-label">
              CITY :
              <span class="city-value">${this.state.consignorCity}</span>
            </div>
          </div>
          <div class="state">
            <div class="state-label">
              STATE :
              <span class="state-value">
                ${this.state.consignorState}
              </span>
            </div>
          </div>
        </div>
        <div class="city-state">
          <div class="city">
            <div class="city-label">
              CONTACT :
              <span class="city-value">
                ${this.state.consignorPhoneNo}
              </span>
            </div>
          </div>
          <div class="state">
            <div class="state-label">
              PINCODE :
              <span class="state-value">${this.state.consignorZip}</span>
            </div>
          </div>
        </div>
        <div class="city-state">
          <div class="city">
            <div class="city-label">
              PHONE NO :
              <span class="city-value">
                ${this.state.consignorPhoneNo}
              </span>
            </div>
          </div>
          <div class="state">
            <div class="state-label">
              GST NO :
              <span class="state-value">
                ${this.state.consignorGSTNo}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="consignor-details-container">
        <div class="consignee-container">
          <div class="consignor">
            <div class="consignor-label">CONSIGNEE :-</div>
            <div class="consignor-value">${this.state.consigneeName}</div>
          </div>
          <div class="address">
            <div class="address-label">ADDRESS :</div>
            <div class="address-value">
              ${this.state.consigneeAddress}
            </div>
          </div>
        </div>
        <div class="city-state">
          <div class="city-consignee">
            <div class="city-label">
              CITY :
              <span class="city-value">${this.state.consigneeCity}</span>
            </div>
          </div>
          <div class="state-consignee">
            <div class="state-label">
              STATE :
              <span class="state-value">
                ${this.state.consigneeState}
              </span>
            </div>
          </div>
        </div>
        <div class="city-state">
          <div class="city-consignee">
            <div class="city-label">
              CONTACT :
              <span class="city-value">
                ${this.state.consigneePhoneNo}
              </span>
            </div>
          </div>
          <div class="state-consignee">
            <div class="state-label">
              PINCODE :
              <span class="state-value">${this.state.consigneeZip}</span>
            </div>
          </div>
        </div>
        <div class="city-state">
          <div class="city-consignee">
            <div class="city-label">
              PHONE NO :
              <span class="city-value">
                ${this.state.consigneePhoneNo}
              </span>
            </div>
          </div>
          <div class="state-consignee">
            <div class="state-label">
              GST NO :
              <span class="state-value">
                ${this.state.consigneeGSTNo}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="parameters-container">
      <div class="parameters-1">
        <div class="packaging-container">
          <div class="no-pkgs">
            <div class="no-pkgs-label">No of Pkgs</div>
            <div class="no-pkgs-value">${this.state.noOfPkgs}</div>
          </div>
          <div class="pkg-type">
            <div class="pkg-type-label">Packing Type</div>
            <div class="pkg-type-value">
              ${this.state.packagingType?.label}
            </div>
          </div>
          <div class="disp-goods">
            <div class="disp-goods-label">Description of Goods</div>
            <div class="disp-goods-value">
              ${this.state.descriptionOfGoods}
            </div>
          </div>
          <div class="weight">
            <div class="weight-label">Actual Weight</div>
            <div class="weight-value">
              ${this.state.actualWeight}
            </div>
          </div>
        </div>
        <div class="part-no">
          <div class="part-no-label">Part No</div>
          <div class="part-no-value">${this.state.partNo}</div>
        </div>
      </div>
      <div class="parameters-2">
        <div class="weight-container">
          <div class="vol-weight">
            <div class="vol-weight-label">Volumetric Weight</div>
            <div class="vol-weight-value">
              ${this.state.volumetricWeight}
            </div>
          </div>
          <div class="charge-weight">
            <div class="charge-weight-label">Chargeable Weight</div>
            <div class="charge-weight-value">
              ${this.state.chargeableWeight}
            </div>
          </div>
        </div>
        <div class="qty">
          <div class="qty-label">Qty</div>
          <div class="qty-value">${this.state.qty}</div>
        </div>
      </div>
      <div class="parameters-3">
        <div class="dim">
          <div class="dim-label">
            Dimensions
            <br />L X B X H
          </div>
          <div class="dim-value">
            ${this.state.LDimension}
            <br />${this.state.BDimension}
            <br />${this.state.HDimension}
          </div>
        </div>
        <div class="invoice-no">
          <div class="invoice-no-label">Invoice No</div>
          <div class="invoice-no-value">${this.state.invoiceNo}</div>
        </div>
      </div>
    </div>
    <div class="para-1">
      I/We agree to terms & conditions set out in the reserve of this
      Consignor’s copy & declare that contents on this way bill are true
      and correct. The To-pay Freight has my / our consent and will be
      paid by the Consignee along with Service Charges as applicable at
      the time of Delivery.
    </div>
    <div class="para-1">
      Pricol Logistics Pvt Ltd will not be liable for insurance. the same
      to be arranged by consignor / consignee. Pricol Logistics Pvt Ltd
      liability will be limited to issuing certificate of facts (COF)
      only. Liability Limit Up to Rs. 5000/- Only
    </div>
    <div class="bottom-container">
      <div class="part-1-container">
        <div class="received-goods">
          Received above shipment in order and in Good <br />
          Condition
        </div>
        <div class="receiver-sign">
          Name Date Time <br />
          Receiver’s Signature With Seal
        </div>
      </div>
      <div class="part-2-container">
        <div class="received-goods-value"></div>
        <div class="receiver-sign-value"></div>
      </div>
      <div class="part-3-container">
        <div class="name-date-time">
          Name Date Time <br />
          For Pricol Logistics Private Limited
        </div>
        <div class="autho-sign">Authorized Signatory</div>
      </div>
    </div>
    <div class="pdf-for">${name}</div>
  </div>`;

    const element = document.createElement("div");
    element.innerHTML = htmlContent;
    element.style.position = "absolute";
    element.style.left = "0";
    element.style.top = "0";
    document.body.appendChild(element);

    await new Promise((resolve) => setTimeout(resolve, 200));

    // Generate canvas from the HTML content
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape");
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save(`${name}.pdf`);
      document.body.removeChild(element); // Clean up the temporary element
      // this.warningToggle();
      // this.props.history.push({
      //   pathname: "/trips",
      // });
    });
  };

  organizer = () => {
    const statusMap = {
      arrived_at_source: "Arrived At Source",
      loading_in_progress: "Loading In Progress",
      quality_check_completed: "Quality Check Completed",
      loading_completed: "In Transit",
      epod_completed: "Completed",
      shipment_verified: "Shipment Verified",
      epod_in_progress: "Epod in Progress",
      arrived_at_via: "Arrived at via",
      cancelled: "Cancelled",
      // Add more conversions as needed
    };
    // Define the class names based on the status name
    const classNameMap = {
      "Arrived At Source": "frist1",
      "Loading In Progress": "second1",
      "Quality Check Completed": "sixth1",
      "In Transit": "third1",
      Completed: "fourth1",
      "Shipment Verified": "seventh1",
      "Epod in Progress": "third1",
      "Arrived at via": "eighth1",
    };
    const iconNameMap = {
      "Arrived At Source": <ArrivedAtSource />,
      "Loading In Progress": <DateIcon color={"white"} />,
      "Quality Check Completed": <QualityCheck />,
      "In Transit": <ArrivedAtSource />,
      Completed: <Completed />,
      "Shipment Verified": <DateIcon color={"white"} />,
      "Epod in Progress": <DateIcon color={"white"} />,
      "Arrived at via": <DateIcon color={"white"} />,
      Cancelled: <DateIcon color={"white"} />,
    };
    const miniIconNameMap = {
      "Arrived At Source": "",
      "Loading In Progress": "",
      "Quality Check Completed": <QualityCheckTick />,
      "In Transit": "",
      Completed: "",
      "Shipment Verified": <QualityCheckTick />,
      "Epod in Progress": "",
      "Arrived at via": "",
      Cancelled: "",
    };
    const statusArray = [];
    this.state.tripTransitionLogs.forEach((ele, index) => {
      const formattedStatus = statusMap[ele.curStatus] || ele.curStatus;
      const className = classNameMap[formattedStatus] || "";
      const iconName = iconNameMap[formattedStatus] || "";
      const miniIconName = miniIconNameMap[formattedStatus] || "";
      statusArray.push({
        index: index,
        curStatus: formattedStatus,
        curData: ele,
        className,
        iconName,
        miniIconName,
      });
    });

    this.setState({ statusArray: statusArray });
  };

  driverPopulate = (value) => {
    let name;
    let phone;
    if (value.name !== "") {
      name = value.name;
    } else name = "----";
    if (value.phone !== "") phone = value.phone;
    else phone = "----";
    if (name === "----" && phone === "----") return "-----";
    else return name + " (" + phone + ")";
  };

  tripDetailToggleModel = async (id) => {
    this.setState({ showTripDetailModel: !this.state.showTripDetailModel });
    this.setState({ tripDetailId: id });
  };

  tripShipmentToggleModel = async (id, propsLr) => {
    this.setState({ showShipDetailModel: !this.state.showShipDetailModel });
    this.setState({ tripDetailId: id, lrStatus: propsLr });
  };

  tripHistoryToggleModel = async (id, name, driver, tripIdHistory) => {
    this.setState({ showTripHistoryModel: !this.state.showTripHistoryModel });
    this.setState({
      tripDetailId: id,
      transporterName: name,
      driverName: driver,
      tripIdHistory: tripIdHistory,
    });
  };

  route = (via) => {
    return via?.map((e, i) => (
      <div key={i}>
        {i ? (
          <div className="text-left pr-4" style={{ fontSize: "0.6em" }}>
            <i
              style={{ color: "#5664D2" }}
              className="fas fa-ellipsis-v ml-1"
            ></i>
          </div>
        ) : null}

        <div className="text-left d-flex align-items-center flex-nowrap">
          <i
            style={{ color: "#5664D2" }}
            className="fas fa-circle iconSize"
          ></i>
          <span className="ml-2">{e.label}</span>
        </div>
      </div>
    ));
  };
  shareLink = (shortLink1, type) => {
    navigator.clipboard.writeText(shortLink1);
    NotificationManager.success(`${shortLink1} Is  Copied`, "Success");
  };
  formatDateLine = (date) => {
    return (
      <span>
        {moment(date).format("MMM-DD-YYYY")} ,{moment(date).format("h:mm A")}
      </span>
    );
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;

    let tripDetailArray = [];
    let tripDetails = this.props.history.location.state?.tripDetails;
    tripDetailArray.push(tripDetails);
    let data = [];
    tripDetailArray.forEach((ele) => {
      data.push({
        id: ele?._id,
        tripId: ele?.tripId ? ele?.tripId : "N/A",
        indentId: ele?.indentId ? ele?.indentId?.indentId : null,
        driver: ele?.driver ? this.driverPopulate(ele.driver) : "N/A",
        source: ele?.source ? `${ele?.source?.label}` : "N/A",
        destination: ele?.destination ? `${ele.destination.label}` : "N/A",
        vehicle:
          (
            <>
              <p style={{ padding: "0px", margin: "0px" }}>
                {ele?.vehicle?.regNo}
              </p>
              <p style={{ padding: "0px", margin: "0px" }}>
                {ele?.vehicle?.type?.label}
              </p>
            </>
          ) || "N/A",
        transporter: ele?.transporter?.name || "N/A",
        route: ele?.via?.length ? <>{this.route(ele.via)}</> : "DIRECT",
        description: ele.description,
        startTime: ele?.startTime ? formatDate(ele.startTime) : "N/A",
        expiryTime: ele?.expiryTime ? formatDate(ele.expiryTime) : "N/A",
        // status: this.status(ele.status, ele.lastRejection, ele._id) || "---",
        subStatus: ele?.subStatus,
      });
    });
    const modalStyle = {
      backgroundColor: darkState ? "#1B1C31" : "#ffffff",
      border: darkState ? "border: 0.5px solid #616161" : "#ffffff",
      marginRight: "0vw",
      height: "724px",
      overflowY: "scroll",
      paddingBottom: "22px",
    };
    return (
      <React.Fragment>
        {getLocalStorage()}
        <Container fluid={true} className="mt-2">
          <Row>
            <Col xl="12" className="p-1">
              <div>
                <section class="column-container">
                  <div className="col-1-1 pad">
                    <>
                      <TripTransitionInfo
                        tripDetails={tripDetails}
                        data={data}
                        statusArray={this.state.statusArray}
                        modalStyle={modalStyle}
                        tripDetailToggleModel={this.tripDetailToggleModel}
                        tripHistoryToggleModel={this.tripHistoryToggleModel}
                        tripShipmentToggleModel={this.tripShipmentToggleModel}
                        toPascalCase={toPascalCase}
                        timeDiff={timeDiff}
                        tripTransitionLogs={this.state.tripTransitionLogs}
                      />
                    </>
                  </div>

                  <div className="col-full pad color3">
                    <TripInfo
                      tripDetails={tripDetails}
                      selectMenu={this.selectMenu}
                      currentMenu={this.state.currentMenu}
                      driverPopulate={this.driverPopulate}
                      route={this.route}
                      shareLink={this.shareLink}
                      formatDateLine={this.formatDateLine}
                      generatePdf={this.generatePdf}
                    />
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </Container>
        <QualityCheckModel
          isOpen={this.state.showTripDetailModel}
          toggleModel={this.tripDetailToggleModel}
          tripIds={this.state.tripDetailId}
        />

        <TripDetailShipmentModel
          isOpen={this.state.showShipDetailModel}
          toggleModel={this.tripShipmentToggleModel}
          tripIds={this.state.tripDetailId}
          lrStatus={this.state.lrStatus}
        />

        <TripHistoryModel
          isOpen={this.state.showTripHistoryModel}
          toggleModel={this.tripHistoryToggleModel}
          tripIds={this.state.tripDetailId}
          transporter={this.state.transporterName}
          driver={this.state.driverName}
          tripIdHistory={this.state.tripIdHistory}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    tripById: state.Trip.trips.editTrip,
    locationTripById: state.Trip.trips.locationTrip,
  };
};
export default connect(mapStateToProps, {
  fetchTripById,
  trackLocationTripById,
  fetchLRCopy,
})(TripDetails_newUI);
