import React, { Component } from "react";
import { Card } from "reactstrap";
import Select, { components } from "react-select";
import "../OperationsDashboard/Styles/dashboard.css";
import { Grid, Button } from "@material-ui/core";
import refreshIcon from "../assets/reset icon.jpg";
import { InputGroup } from "rsuite";
// import "rsuite/dist/rsuite.min.css";
import Datepicker from "../../../components/Common/DatePicker/DatePicker";

var darkMode;
var dashInvoiceBorder;
var dashTransBorder;
var dashBorder;
var invoiceBgClr;
var invoiceClr;
var transpoterBgClr;
var transpoterClr;
var OperationsBgClr;
var operationsClr;

// const MyIcon = React.forwardRef((props, ref) => {
//   return (
//     <span style={{ position: "absolute", top: "2px", right: "0px" }}>
//       <svg
//         width="16"
//         height="16"
//         viewBox="0 0 16 16"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M12.9985 2H2.99854C1.89397 2 0.998535 2.89543 0.998535 4V13C0.998535 14.1046 1.89397 15 2.99854 15H12.9985C14.1031 15 14.9985 14.1046 14.9985 13V4C14.9985 2.89543 14.1031 2 12.9985 2Z"
//           stroke={darkMode ? "#B7A9FF" : "#2800FC"}
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M10.9985 1V3"
//           stroke={darkMode ? "#B7A9FF" : "#2800FC"}
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M4.99854 1V3"
//           stroke={darkMode ? "#B7A9FF" : "#2800FC"}
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//         <path
//           d="M0.998535 6H14.9985"
//           stroke={darkMode ? "#B7A9FF" : "#2800FC"}
//           strokeWidth="1.5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//       </svg>
//     </span>
//   );
// });

const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};

class DashboardHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: null,
      toDate: null,
    };
  }
  onChangeDatePicker = (value, label) => {
    // console.log(value, label)
    // debugger
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        // console.log(this.state)

        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.onChangeDatePicker([
            this.state.fromDate,
            this.state.toDate,
          ]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        // console.log(this.state)

        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.onChangeDatePicker([
            this.state.fromDate,
            this.state.toDate,
          ]);
        }
      });
    }
  };
  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    const customStyles1 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "28px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "28px",
          flexWrap: "nowrap",
          backgroundColor: this.props?.opCardStyle ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "28px",
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontStyle: "normal",
          paddingLeft: "10px",
          paddingTop: "5px",
          color: this.props?.opCardStyle ? "#9D9D9D" : "#0C0C0C",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    const customStyles2 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        zIndex: 9999,
        width: "100%",
        minWidth: "100%",
        cursor: "pointer ",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        bordeRadius: darkMode ? "4px" : "4px",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "28px",
          width: this.props.selectedHopValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "28px",
          flexWrap: "nowrap",
          backgroundColor: this.props?.opCardStyle ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "28px",
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontStyle: "normal",
          paddingLeft: "10px",
          paddingTop: "5px",
          color: this.props?.opCardStyle ? "#9D9D9D" : "#0C0C0C",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    if (this.props.location?.pathname === "/operationsDashboard") {
      OperationsBgClr = "#0AB38C";
      operationsClr = "#FFFFFF";
      dashBorder = " ";
    } else {
      OperationsBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      operationsClr = `${darkMode ? "#ffffff" : "#05284E"}`;
      dashBorder = `${darkMode ? "0.5px solid #616161" : " "}`;
    }

    if (this.props.location?.pathname === "/invoice-dashboard") {
      invoiceBgClr = "#0AB38C";
      invoiceClr = "#FFFFFF";
      dashInvoiceBorder = " ";
    } else {
      invoiceBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      invoiceClr = `${darkMode ? "#ffffff" : "#05284E"}`;
      dashInvoiceBorder = `${darkMode ? "0.5px solid #616161" : " "}`;
    }
    if (this.props.location?.pathname === "/transporterDashboard") {
      transpoterBgClr = "#0AB38C";
      transpoterClr = "#FFFFFF";
      dashTransBorder = " ";
    } else {
      transpoterBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      transpoterClr = `${darkMode ? "#ffffff" : "#05284E"}`;
      dashTransBorder = `${darkMode ? "0.5px solid #616161" : " "}`;
    }
    return (
      <>
        <div className="dashboard-header-div darkBgQuickFilter">
          <Card
            className="dashboard-header"
            // fluid
            style={{
              backgroundColor: `${darkMode ? "#222238" : "#FFFFFF"}`,
              height: "auto",
              width: "100%",
            }}
          >
            <Grid
              className="grid-align pt-3 pb-3 pl-1 pr-1"
              container
              spacing={2}
              // justifyContent="center"
              // alignItems="center"
              style={{
                paddingBottom: "25px !important",
              }}
            >
              <Grid item xs={10} sm={10} md={1} lg={1} className="">
                <Button
                  className="operations-dashboard-btn-operations"
                  variant="outlined"
                  style={{
                    backgroundColor: `${OperationsBgClr}`,
                    color: `${operationsClr}`,
                    border: `${dashBorder}`,
                  }}
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/operationsDashboard",
                    });
                  }}
                >
                  {this.props?.userLang?.dashboard?.OPERATIONS}
                </Button>
              </Grid>
              <Grid item xs={10} sm={10} md={1} lg={1}>
                <Button
                  className="operations-dashboard-btn-transpoter"
                  variant="outlined"
                  style={{
                    backgroundColor: `${transpoterBgClr}`,
                    color: `${transpoterClr}`,
                    border: `${dashTransBorder}`,
                  }}
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/transporterDashboard",
                    });
                  }}
                >
                  {this.props?.userLang?.common?.TRANSPORTER}
                </Button>
              </Grid>
              <Grid item xs={10} sm={10} md={1} lg={1}>
                <Button
                  className="operations-dashboard-btn-invoice"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/invoice-dashboard",
                    });
                  }}
                  variant="outlined"
                  style={{
                    backgroundColor: `${invoiceBgClr}`,
                    color: `${invoiceClr}`,
                    border: `${dashInvoiceBorder}`,
                  }}
                >
                  {this.props?.userLang?.dashboard?.INVOICE}
                </Button>
              </Grid>
              <Grid item xs={10} sm={10} md={1} lg={2}></Grid>

              <Grid
                item
                xs={10}
                sm={10}
                md={2}
                lg={2}
                className="dashLeftInput"
              >
                {this.props.location?.pathname === "/operationsDashboard" ||
                  this.props.location?.pathname === "/invoice-dashboard" ? (
                  <Select
                    className="transpoter-react_selector"
                    classNamePrefix="react-select"
                    placeholder="Select Transporters..."
                    isMulti
                    // autoFocus={true}
                    isSearchable
                    styles={customStyles1}
                    components={{ DropdownIndicator }}
                    selectProps={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="14"
                        viewBox="0 0 13 14"
                        fill="none"
                      >
                        <path
                          d="M12 12.906V11.583C12 10.8812 11.7212 10.2082 11.225 9.71201C10.7288 9.21579 10.0558 8.93701 9.35399 8.93701H4.06299C3.36123 8.93701 2.68821 9.21579 2.19199 9.71201C1.69577 10.2082 1.41699 10.8812 1.41699 11.583V12.906"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.5 6C7.88071 6 9 4.88071 9 3.5C9 2.11929 7.88071 1 6.5 1C5.11929 1 4 2.11929 4 3.5C4 4.88071 5.11929 6 6.5 6Z"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                    style={{ width: "82%" }}
                    value={this.props.selectedTranspoterValues}
                    options={this.props.transporterOptions}
                    onChange={(e) => {
                      this.props.onChangeTranspoter(e);
                    }}
                    onInputChange={(e) => {
                      this.props.filtersTranspoter(e);
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={10} sm={10} md={2} lg={2}>
                <Select
                  className="hopes-react_selector"
                  classNamePrefix="react-select"
                  placeholder="Select Hops..."
                  isMulti
                  components={{ DropdownIndicator }}
                  selectProps={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                    >
                      <path
                        d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                        stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                        stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  // autoFocus={true}
                  isSearchable
                  styles={customStyles2}
                  // width="150px"
                  value={this.props.selectedHopValues}
                  options={this.props.hopOptions}
                  onChange={this.props.onChangeHop}
                  onInputChange={(e) => {
                    this.props.filtersHops(e);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={10}
                sm={10}
                md={3}
                lg={3}
                className="datePickerGrid"
              >
                <div className="d-flex flex-row">
                  <div
                    className="mr-2 date-picker-div RsuiteDateRange dashboardDateRange"
                    style={{
                      width: "100%",
                      backgroundColor: `${this.props?.opCardStyle === true ? "#2E2E46" : " "
                        }`,
                      color: `${this.props?.opCardStyle === true ? "#ffffff" : " "
                        }`,
                    }}
                  >
                    <div
                      className={`datepicker-selector ${this.props.opCardStyle ? "darkDatepicker-selector" : " "
                        }`}
                    >
                      <InputGroup>
                        {/* <DatePicker
                          format="dd-MMM-yy"
                          block
                          size="md"
                          placement="auto"
                          placeholder={"Start Date"}
                          caretAs={MyIcon}
                          value={this.state.fromDate}
                          onChange={(datevalues) => {
                            this.setState({ fromDate: datevalues }, () => {
                              if (
                                this.state.fromDate !== null &&
                                this.state.toDate !== null
                              ) {
                                this.props.onChangeDatePicker([
                                  this.state.fromDate,
                                  this.state.toDate,
                                ]);
                              }
                            });
                          }}
                        /> */}
                        <Datepicker
                          mode={darkMode}
                          value={this.state.fromDate}
                          onChangeDatePicker={this.onChangeDatePicker}
                          fromDate={true}
                          toDate={false}
                          placeholder={"Start Date"}
                          dashboard={true}
                        />
                        <Datepicker
                          mode={darkMode}
                          value={this.state.toDate}
                          onChangeDatePicker={this.onChangeDatePicker}
                          fromDate={false}
                          toDate={true}
                          placeholder={"End Date"}
                          dashboard={true}
                        />

                        {/* <DatePicker
                          format="dd-MMM-yy"
                          block
                          size="md"
                          placement="auto"
                          placeholder={"End Date"}
                          caretAs={MyIcon}
                          value={this.state.toDate}
                          onChange={(datevalues) => {
                            this.setState({ toDate: datevalues }, () => {
                              if (
                                this.state.fromDate !== null &&
                                this.state.toDate !== null
                              ) {
                                this.props.onChangeDatePicker([
                                  this.state.fromDate,
                                  this.state.toDate,
                                ]);
                              }
                            });
                          }}
                        /> */}
                      </InputGroup>
                    </div>
                  </div>
                  <div>
                    <Button
                      className="operations-dashboard-btn-reset ml-2"
                      variant="outlined"
                      style={{
                        height: "27px",
                        width: "77%",
                        border: "none"
                      }}
                      onClick={() => {
                        this.setState(
                          {
                            fromDate: null,
                            toDate: null,
                          },
                          () => {
                            this.props.onReset();
                          }
                        );
                      }}
                    >
                      {this.props?.userLang?.common?.RESET}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
            {/* mobile view */}
            <div>
              <Grid
                className="grid-align-mobile pt-3 pb-3 pl-1 pr-1"
                container
                spacing={2}
                // justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <div className="d-flex  flex-wrap justify-content-between align-items-center">
                    <ul className="d-flex flex-row mobile-dashboard-header-buttons">
                      <li>
                        <Button
                          className="operations-dashboard-btn-operations mr-2"
                          variant="outlined"
                          style={{
                            backgroundColor: `${OperationsBgClr}`,
                            color: `${operationsClr}`,
                            border: `${dashBorder}`,
                          }}
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/operationsDashboard",
                            });
                          }}
                        >
                          {this.props?.userLang?.dashboard?.OPERATIONS}
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="operations-dashboard-btn-transpoter"
                          variant="outlined"
                          style={{
                            backgroundColor: `${transpoterBgClr}`,
                            color: `${transpoterClr}`,
                            border: `${dashTransBorder}`,
                          }}
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/transporterDashboard",
                            });
                          }}
                        >
                          {this.props?.userLang?.common?.TRANSPORTER}
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="operations-dashboard-btn-invoice"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/invoice-dashboard",
                            });
                          }}
                          variant="outlined"
                          style={{
                            backgroundColor: `${invoiceBgClr}`,
                            color: `${invoiceClr}`,
                            border: `${dashInvoiceBorder}`,
                          }}
                        >
                          {this.props?.userLang?.dashboard?.INVOICE}
                        </Button>
                      </li>
                    </ul>
                    <div>
                      <img
                        onClick={() => {
                          this.setState(
                            {
                              fromDate: null,
                              toDate: null,
                            },
                            () => {
                              this.props.onReset();
                            }
                          );
                        }}
                        style={{
                          width: "36px",
                          height: "36px",
                          cursor: "pointer",
                        }}
                        src={refreshIcon}
                        alt="refreshicon"
                      />
                    </div>
                  </div>
                </Grid>

                {this.props.location?.pathname === "/operationsDashboard" ||
                  this.props.location?.pathname === "/invoice-dashboard" ? (
                  <Grid item xs={12} className="ptd16">
                    <Select
                      className="transpoter-react_selector"
                      classNamePrefix="react-select"
                      placeholder="Select Transporters..."
                      isMulti
                      // autoFocus={true}
                      isSearchable
                      styles={customStyles1}
                      style={{ width: "82%" }}
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                        >
                          <path
                            d="M12 12.906V11.583C12 10.8812 11.7212 10.2082 11.225 9.71201C10.7288 9.21579 10.0558 8.93701 9.35399 8.93701H4.06299C3.36123 8.93701 2.68821 9.21579 2.19199 9.71201C1.69577 10.2082 1.41699 10.8812 1.41699 11.583V12.906"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.5 6C7.88071 6 9 4.88071 9 3.5C9 2.11929 7.88071 1 6.5 1C5.11929 1 4 2.11929 4 3.5C4 4.88071 5.11929 6 6.5 6Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      value={this.props.selectedTranspoterValues}
                      options={this.props.transporterOptions}
                      onChange={this.props.onChangeTranspoter}
                      onInputChange={(e) => {
                        this.props.filtersTranspoter(e);
                      }}
                    />
                  </Grid>
                ) : (
                  ""
                )}

                <Grid item xs={12} className="ptd16">
                  <Select
                    className="hopes-react_selector"
                    classNamePrefix="react-select"
                    placeholder="Select Hops..."
                    isMulti
                    // autoFocus={true}
                    isSearchable
                    styles={customStyles2}
                    components={{ DropdownIndicator }}
                    selectProps={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                        fill="none"
                      >
                        <path
                          d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                    value={this.props.selectedHopValues}
                    options={this.props.hopOptions}
                    onChange={this.props.onChangeHop}
                    onInputChange={(e) => {
                      this.props.filtersHops(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="ptd16">
                  <div className="date-picker-div">
                    <div className="datepicker-selector-mobile RsuiteDateRange dashboardDateRange">
                      {/* <DateRangePicker
                        size="md"
                        placeholder="Select Date Range..."
                        block
                        format="dd MMM yy"
                        showOneCalendar
                        placement="bottom"
                        preventOverflow
                        value={this.props.datevalues || [null, null]}
                        onChange={(datevalues) => {
                          this.props.onChangeDatePicker(datevalues);
                        }}
                        caretAs={MyIcon}
                        onClean={(event) => {
                          // console.log("Event", event);
                          this.props.resetDateValues();
                        }}
                      /> */}
                      <InputGroup>
                        <Datepicker
                          mode={darkMode}
                          value={this.state.fromDate}
                          onChangeDatePicker={this.onChangeDatePicker}
                          fromDate={true}
                          toDate={false}
                          placeholder={"Start Date"}
                          dashboard={true}
                        />
                        <Datepicker
                          mode={darkMode}
                          value={this.state.toDate}
                          onChangeDatePicker={this.onChangeDatePicker}
                          fromDate={false}
                          toDate={true}
                          placeholder={"End Date"}
                          dashboard={true}
                        />
                        {/* <DatePicker
                          format="dd-MMM-yy"
                          block
                          size="md"
                          placement="auto"
                          placeholder={"Start Date"}
                          caretAs={MyIcon}
                          value={this.state.fromDate}
                          onChange={(datevalues) => {
                            this.setState({ fromDate: datevalues }, () => {
                              if (
                                this.state.fromDate !== null &&
                                this.state.toDate !== null
                              ) {
                                this.props.onChangeDatePicker([
                                  this.state.fromDate,
                                  this.state.toDate,
                                ]);
                              }
                            });
                          }}
                        />

                        <DatePicker
                          format="dd-MMM-yy"
                          block
                          size="md"
                          placement="auto"
                          placeholder={"End Date"}
                          caretAs={MyIcon}
                          value={this.state.toDate}
                          onChange={(datevalues) => {
                            this.setState({ toDate: datevalues }, () => {
                              if (
                                this.state.fromDate !== null &&
                                this.state.toDate !== null
                              ) {
                                this.props.onChangeDatePicker([
                                  this.state.fromDate,
                                  this.state.toDate,
                                ]);
                              }
                            });
                          }}
                        /> */}
                      </InputGroup>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </>
    );
  }
}
export default DashboardHeader;
