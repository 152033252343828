import React from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const DriverDetails = ({ ...props }) => {
  return (
    <Col lg="12" style={{ paddingBottom: "2px" }} className="jumbo addNewBg">
      <Row>
        <Col lg="5" className="addNewBg">
          <FormGroup>
            <Label className="control-label light__span ptL5">
              {userLang?.common?.PHONE} <span className="req">*</span>
            </Label>
            <Input
              name="phone"
              value={props?.driverForm?.phone ?? ""}
              placeholder="Type here..."
              onChange={(e) =>
                props.onDriverChange({
                  target: {
                    name: "phone",
                    value: e.target.value,
                  },
                })
              }
              maxLength="10"
            />
            {!props.isPhoneNoValid && (
              <span style={{ fontStyle: "12px", color: "red" }}>
                {userLang?.trip?.PLEASE_ENTER_FORMAT}: 9988776655
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="5">
          <FormGroup>
            <Label className="control-label light__span">
              {userLang?.common?.FULL_NAME}
            </Label>
            <Input
              value={props?.driverForm?.fullName ?? ""}
              name="fullName"
              placeholder="Type here..."
              onChange={(e) =>
                props.onDriverChange({
                  target: {
                    name: "fullName",
                    value: e.target.value,
                  },
                })
              }
            />
          </FormGroup>
        </Col>
        <Col xs="2">
          <Button
            style={{
              backgroundColor: props.mode ? "#5E40FF" : "#05284E",
            }}
            color="primary"
            className="jumboButton"
            onClick={() => {
              props.submitDriverForm();
            }}
            //   disabled={this.props.disabled}
          >
            {" "}
            {userLang?.common?.SAVE}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
export default DriverDetails;
