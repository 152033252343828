import React, { Component } from "react";
import { Row, Col, Card,Button } from "reactstrap";
import exportDarkIcon from "./assets/external-linkDark.png";
import exportIcon from "./assets/external-link.png";

class QuickSearchFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
    };
  }

  render() {
    let mode = localStorage.getItem("Theme");
    let darkMode = this.props?.mode;
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar driverStyle darkBgQuickFilter">
            <>
              <div className="d-flex justify-content-sm-end ">
                <Row>
                  <Col xs={12} md={4} lg={2} className="mobileDisplay">
                    <div className="d-flex justify-content-end">
                      
                     
                      <div className="">
                        <div className="tooltipIcon">
                          <Button
                           color=""
                            disabled={!navigator.onLine}
                            onClick={() => this.props.toggleExcel()}
                            className={`iconButtons ${
                              darkMode ? "iconDarkButtons" : ""
                            }`}
                          >
                            <img
                              style={{ margin: "-4px" }}
                              src={darkMode ? exportDarkIcon : exportIcon}
                              alt="exp"
                            />
                          </Button>
                          <span
                            className="tooltipIcontext"
                            style={{ left: "10%" }}
                          >
                            &nbsp;
                            {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                              "Download Excel"}
                            &nbsp;
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                 
                  <Col
                    xs={2}
                    md={2}
                    lg={2}
                    className="driverMobileCol desktopDisplay"
                  >
                    <div className="d-flex flex-row">
                      
                      
                      <div className="">
                        <div className="tooltipIcon">
                          <Button
                           color=""
                            disabled={!navigator.onLine}
                            onClick={() => this.props.toggleExcel()}
                            className={`iconButtons ${
                              darkMode ? "iconDarkButtons" : ""
                            }`}
                          >
                            <img
                              style={{ margin: "-4px" }}
                              src={darkMode ? exportDarkIcon : exportIcon}
                              alt="exp"
                            />
                          </Button>
                          <span
                            className="tooltipIcontext"
                            style={{ left: "10%" }}
                          >
                            &nbsp;
                            {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                              "Download Excel"}
                            &nbsp;
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xs={2} md={1} lg={1}>
                 
                  </Col> */}
                </Row>
              </div>
            </>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickSearchFilters;
