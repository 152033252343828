// import { mapKeys } from "lodash";
import { types, STORE_SHIPMENTDATA } from "../actionTypes";
const initialState = {
  trips: [],
  fetchId: "",
  tripsExcel: [],
  tripsTower: [],
  QcImages: [],
  shipmentsData: [],
  shipmentPayloadData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_SHIPMENTDATA:
      console.log(action.payload);
      return {
        ...state,
        shipmentPayloadData: action.payload,
      };
    case types.SPEC_FETCHSIGNEDURL_QUALITYCHECK_SUCCESS:
      return {
        ...state,
        QcImages: action.payload,
      };
    case types.FETCH_TRIPS_SUCCESS:
      return {
        ...state,
        trips: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
    case types.FETCH_TRIPS_PRICOL_SUCCESS:
      return {
        ...state,
        trips: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
    case types.FETCH_TRIP_TOWER_SUCCESS:
      return {
        ...state,
        tripsTower: action.payload,
      };
    case types.FETCH_TRIPS_EXCEL_SUCCESS:
      return { ...state, tripsExcel: action.payload };
    case types.START_LOADING_SUCCESS: {
      const { tripID } = action.payload;
      //consolelog(state.trips);
      const trips = [...state.trips?.data?.docs];
      const trip = trips.find((e) => e._id === tripID);
      trip.subStatus = "loading_in_progress";

      return {
        ...state,
        trips: { ...state.trips, data: { ...state.trips.data, docs: trips } },
      };
    }
    case types.FINISH_LOADING_SUCCESS: {
      const { tripID } = action.payload;
      //consolelog(state.trips);
      const trips = [...state.trips?.data?.docs];
      const trip = trips.find((e) => e._id === tripID);
      trip.subStatus = "in_transit";

      return {
        ...state,
        trips: { ...state.trips, data: { ...state.trips.data, docs: trips } },
      };
    }
    case types.FETCH_TRIP_BYID_SUCCESS:
      return {
        ...state,
        editTrip: action.payload,
      };
    case types.FETCH_SHIPMENT_TRIP_BYID_SUCCESS:
      return {
        ...state,
        shipmentsData: action.payload,
      };
    case types.TRACK_LOCATION_BYTRIPID_SUCCESS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return { ...state, locationTrip: action.payload };

    case types.TRACK_TRIP_BYID_SUCCESS:
      //consolelog("fetch by id", action.payload);
      return { ...state, trackTrip: action.payload[0] };
    case types.CREATE_TRIP_SUCCESS:
      ////consolelog('state', state);
      // Copy the current state and set a new property with a dynamic key value and the payload as the value
      return { ...state, [action.payload.id]: action.payload };
    case types.EDIT_TRIP_SUCCESS:
      return { ...state, editTripResponse: action.payload };
    case types.FETCH_HOPS_SUCCESS:
      return { ...state, hops: action.payload };
    case types.DELETE_POST_SUCCESS:
      // Copy the current state and delete the property with the specified key
      var newState = { ...state };
      delete newState[action.payload.id];
      return newState;
    case types.SHIPMENT_VERIFY_SUCCESS:
      return { ...state, verify: action.payload };
    case types.TRIP_QUALITY_CHECK_SUCCESS:
      return { ...state, quality: action.payload };
    case types.CREATE_REPORTINCIDENT_SUCCESS:
      return { ...state, reportIncident: action.payload };
    case types.UPDATE_DRIVER_SUCCESS:
      return { ...state, updateDriver: action.payload };
    case types.FETCH_TRIPS_BILLS_SUCCESS:
      return { ...state, tripBills: action.payload };
    case types.CHANGE_ARRIVEDATSOURCE_STATUS:
      // Copy the current state and delete the property with the specified key
      return { ...state, arrive: action.payload };
    case types.SKIP_VIA_POINT:
      return { ...state, skipApiResponse: action.payload };
    default:
      state = { ...state };
      break;
  }

  return state;
};
