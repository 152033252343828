import React, { Component } from "react";
import { Card } from "reactstrap";
import { MDBRow, MDBCol } from "mdbreact";
import { CloseIcon } from "../../components/Common/CommonIcons";
import TrackingMap from "../TrackingMap";

import TrackingPath from "./TrackingPath";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class TripHistory extends Component {
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    const modalStyle = {
      height: "100%",
      width: "100%",
      backgroundColor: this.props.darkTheme ? "#1B1C31" : "#ffffff",
      paddingTop: "25px",
      paddingLeft: "25px",
      paddingRight: "25px",
      paddingBottom: "auto",
      position: "relative",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: this.props.darkTheme ? "#9D9D9D" : "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      // color: this.props.darkTheme ? "#B7A9FF" : "#2800fc",
      width: "20px",
      marginRight: "0px",
    };
    return (
      <Card style={modalStyle}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={headerStyle}>
            {userLang?.tracking?.TRACKING_HISTORY}
          </span>

          {/* <CloseIcon
            style={closeStyle}
            onClick={() => this.props.closeModal()}
          /> */}
          <i
            style={closeStyle}
            onClick={() => {
              this.props.closeModal()
            }}
          >
            <CloseIcon color={this.props.darkTheme  ? "#B7A9FF" : "#2800fc"} />
          </i>
        </div>

        <MDBRow>
          <MDBCol
            md="6"
            sm="12"
            className={`col-example ${
              this.props.darkTheme ? "darkIntransitModalPopup" : ""
            }`}
          >
            <TrackingPath
              tripTrackId={this.props.id}
              nameTransporter={this.props.transporterName}
              nameDriver={this.props.driverName}
              tripIdHistory={this.props.tripIdHistory}
            />
          </MDBCol>

          <MDBCol
            md="6"
            className="col-example PaddingCol"
            style={{ overflow: "auto" }}
          >
            <Card className="googleMapCard">
              <TrackingMap id={this.props.id} />
            </Card>
          </MDBCol>
        </MDBRow>
      </Card>
    );
  }
}
export default TripHistory;
