import React, { Component } from "react";
import "./tripStyles.scss";
import { Input, Button } from "reactstrap";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

export class PopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      selectedOption: {},
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClickOutside);
  };

  getStatusFromPath = (index) => {
    return this.props.tripDataPopup.secondaryFreighttracking.path.find(
      (obj) =>
        obj?.customer_id === this.props.tripDataPopup.customerDetails[index]._id
    )?.status;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
      this.props.closePopup();
    }
  };

  handleCheckedChanged = (e, index) => {
    if (this.props.popupName === "MarkAsArrived") {
      if (this.getStatusFromPath(index) === "arrived") {
        // return e.stopPropagation();
        return;
      } else {
        this.setState({ selectedOption: e });
        this.props.checkboxClicked(
          index,
          this.props.tripDataPopup._id,
          this.props.tripDataPopup.customerDetails[index]._id
        );
        // return e.stopPropagation();
      }
    } else {
      if (this.getStatusFromPath(index) === "epod_in_progress") {
        // return e.stopPropagation();
        return;
      } else {
        this.setState({ selectedOption: e });
        this.props.checkboxClicked(
          index,
          this.props.tripDataPopup._id,
          this.props.tripDataPopup.customerDetails[index]._id
        );
      }
    }
  };

  render() {
    return (
      <>
        {this.props.isOpen && (
          <div className="popupContainer">
            <div className="popupContent" ref={this.wrapperRef}>
              <div className="crossPopup">
                <i
                  style={
                    {
                      // marginTop: "10px",
                    }
                  }
                  className="fas fa-times closeDark"
                  onClick={() => {
                    this.props.closePopup();
                  }}
                ></i>
              </div>
              <div style={{ marginTop: "24px", marginBottom: "90px" }}>
                {this.props.tripDataPopup?.customerDetails?.map(
                  (item, index) => {
                    return (
                      <>
                        <div
                          className="subDropdownMarkAsArrived"
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2px 10px",
                          }}
                        >
                          <Input
                            className=""
                            id={item.shipToCode}
                            type="checkbox"
                            defaultChecked={
                              this.props.popupName === "MarkAsArrived"
                                ? this.getStatusFromPath(index) === "arrived" ||
                                  this.getStatusFromPath(index) ===
                                    "epod_completed" ||
                                  this.getStatusFromPath(index) ===
                                    "epod_in_progress"
                                  ? true
                                  : false
                                : this.getStatusFromPath(index) ===
                                    "epod_in_progress" ||
                                  this.getStatusFromPath(index) ===
                                    "epod_completed"
                                ? true
                                : false
                            }
                            disabled={
                              this.props.popupName === "MarkAsArrived"
                                ? this.getStatusFromPath(index) === "arrived" ||
                                  this.getStatusFromPath(index) ===
                                    "epod_completed" ||
                                  this.getStatusFromPath(index) ===
                                    "epod_in_progress"
                                  ? true
                                  : false
                                : this.getStatusFromPath(index) ===
                                    "epod_in_progress" ||
                                  this.getStatusFromPath(index) ===
                                    "epod_completed"
                                ? true
                                : false
                            }
                            onChange={() =>
                              this.handleCheckedChanged(item, index)
                            }
                            checked={
                              this.props.popupName === "MarkAsArrived"
                                ? this.getStatusFromPath(index) === "arrived" ||
                                  this.getStatusFromPath(index) ===
                                    "epod_completed" ||
                                  this.getStatusFromPath(index) ===
                                    "epod_in_progress"
                                  ? true
                                  : this.state.selectedOption === item
                                : this.getStatusFromPath(index) ===
                                    "epod_in_progress" ||
                                  this.getStatusFromPath(index) ===
                                    "epod_completed"
                                ? true
                                : this.state.selectedOption === item
                            }
                          />
                          <div className="textSubDropdownMarkAsArrived">
                            {item?.customerName + " "} -
                            {item?.customerNumber + " "} -{item?.shipToCode}
                            {this.props.popupName === "MarkAsArrived" ? (
                              this.getStatusFromPath(index) === "arrived" ||
                              this.getStatusFromPath(index) ===
                                "epod_completed" ||
                              this.getStatusFromPath(index) ===
                                "epod_in_progress" ? (
                                <b>
                                  {" "}
                                  {" -"} {" Already Done"}
                                </b>
                              ) : (
                                false
                              )
                            ) : this.getStatusFromPath(index) ===
                                "epod_in_progress" ||
                              this.getStatusFromPath(index) ===
                                "epod_completed" ? (
                              <b>
                                {" "}
                                {" -"} {" Already Done"}
                              </b>
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
              <div style={{ position: "relative" }}>
                <Button
                  className="fontRoboto1541"
                  style={{
                    width: "100px",
                    height: "50px",
                    backgroundColor: "#05284E",
                    color: "#FFFFFF",
                    position: "absolute",
                    bottom: "24px",
                    right: "24px",
                  }}
                  color="primary"
                  //   onClick={this.handleSubmit}
                  //   disabled={this.state.isSubmitting}
                  onClick={() => {
                    if (this.props.popupName === "MarkAsArrived") {
                      this.props.tripDataPopupFunction();
                    } else {
                      this.props.tripDataPopupFunction(
                        this.props.tripDataPopup
                      );
                    }
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default PopUp;
