import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import { connect } from "react-redux";
import { fetchTripById } from "../../store/trip/actions";
import { trackLocationTripById } from "../../store/trip/actions";
import { Grid } from "@material-ui/core";
import { CloseIcon } from "../../components/Common/CommonIcons";
import { ENGLISH, HINDI } from "../../language/language";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class SecondaryQualityCheckData extends Component {
  constructor(props) {
    super();
    this.state = {
      tripDetail: [],
      tripId: "",
      trackTripById: [],
      shipment: [],
      qualityImage: [],
      qualities: {},
      tripQualityId: "",
    };
  }

  componentDidMount = () => {
    // console.log(this.props?.id, "tripdatapage");
    let tripId = this.props?.id;
    let trip = [];
    this.props.fetchTripById(tripId, (ele) => {});
    this.setState({ tripId: tripId }, () => {
      this.props.fetchTripById(tripId, (ele) => {
        this.setState({
          tripQualityId: ele?.data[0]?.tripId ? ele?.data[0]?.tripId : "",
        });
        if (ele.data[0].epodDetails.length) {
          this.setState(
            {
              qualities: ele.data[0].qualityCheck.answers,
              qualityImage: ele.data[0]?.qualityCheck?.images || [],
              shipment: ele.data[0].epodDetails
                ? ele.data[0].epodDetails[0].shipments
                : "---",
            },
            () => {
              // console.log(this.state.qualities, "callbackqualityState");
            }
          );
        } else {
          this.setState(
            {
              qualities: ele.data[0].qualityCheck.answers,
              qualityImage: ele.data[0]?.qualityCheck?.images || [],
            },
            () => {
              // console.log(ele.data[0].qualityCheck, "callbackqualityCheck");
            }
          );
        }

        trip.push({
          quality: ele.data[0].qualityCheck,
          shipment:
            ele.data[0].epodDetails.length > 0
              ? ele.data[0].epodDetails[0].shipments
              : [],
        });

        this.setState({ tripDetail: trip });
      });
    });
  };

  downloadImage = (image) => {
    fetch(image[0], { mode: "no-cors" })
      .then((res) => res.blob())
      .then((blob) => {
        var element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = "image.jpg";
        element.click();
      });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let darkState = this.props.darkTheme;

    var arr = [];
    let trucktypearr = [];
    let truckType = {};
    this.state.tripDetail.map((item) => {
      let key1 = Object.keys(item.quality.answers).map((key) => {
        if (item.quality.answers[key] !== null && key !== "vehicleDesign") {
          let obj = {
            key: key,
            value: JSON.stringify(item.quality.answers[key]).replace(/_/g, " "),
          };
          arr.push(obj);
          arr.unshift(arr.pop());
        }
        if (item.quality.answers[key] !== null && key) {
          let obj = {
            key: key,
            value: JSON.stringify(item.quality.answers[key]).replace(/_/g, " "),
          };
          trucktypearr.push(obj);
        }
        return true;
      });
      return key1;
    });

    if (this.state.tripDetail.length > 0) {
      trucktypearr.forEach(function (a) {
        if (a.key === "vehicleDesign") {
          truckType["type"] = JSON.parse(a.value);
        }
      });
    }

    const modalStyle = {
      height: "100%",
      width: "100%",
      backgroundColor: darkState ? "#1B1C31" : "#ffffff",
      padding: "25px",
      position: "relative",
      border: darkState ? "border: 0.5px solid #616161" : "#ffffff",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      // color: darkState ? "#B7A9FF" : "#2800fc",
      width: "20px",
      marginRight: "0px",
    };
    const rowsTopSpaceStyle = { marginTop: "0px" };
    const dataBoxStyle = {
      height: "360px",
      overflowY: "scroll",
      overflowX: "clip",
    };
    const oddRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#24243C" : "#ffffff",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "3px",
      //position:'relative',
    };
    const evenRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "3px",
      //position:'relative',
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "100%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };

    var action = (ele) => {
      let getLanguage = userLang;
      let lan = window.localStorage.getItem("userPreferredLang");
      const { Q11, Q12, Q13, Q14, Q15 } = userLang?.trip;
      if (!Q11 || !Q12 || !Q13 || !Q14 || !Q15) {
        getLanguage = lan === "EN" ? ENGLISH : HINDI;
      }
      switch (ele.key) {
        case "evenAndDustFreePlatform":
          return <span>{userLang?.trip?.Q8}?</span>;
        case "holesInPlatform":
          return <span>{userLang?.trip?.Q7}?</span>;
        case "goodTarpaulins":
          return <span>{userLang?.trip?.Q6}?</span>;
        case "objectionableOdour":
          return <span>{userLang?.trip?.Q5}?</span>;
        case "residualContent":
          return <span>{userLang?.trip?.Q4}</span>;
        case "usingWoodenCorners":
          return <span>{userLang?.trip?.Q9}?</span>;
        case "doorsLockingClosingProperly":
          return <span>{userLang?.trip?.Q2}?</span>;
        case "vehicleMatched":
          return <span> {userLang?.trip?.Q1}?</span>;
        case "vehicleDesign":
          return <span> {userLang?.trip?.Q10}?</span>;
        case "protrudingNails":
          return <span>{userLang?.trip?.Q3}?</span>;
        case "isFitnessCertificateValid":
          return <span>{getLanguage.trip.Q11}?</span>;
        case "isInsuranceCertificateValid":
          return <span>{getLanguage?.trip?.Q12}?</span>;
        case "isPollutionCertificateValid":
          return <span>{getLanguage?.trip?.Q13}?</span>;
        case "isVehicleRegistrationCertificateValid":
          return <span>{getLanguage?.trip?.Q14}?</span>;
        case "isDriverLicenseValid":
          return <span>{getLanguage?.trip?.Q15}?</span>;
        default:
          break;
      }
    };
    return (
      <React.Fragment>
        <div>
          <Card style={modalStyle}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <span style={headerStyle}>
                  {userLang?.trip?.QUALITY_CHECKS}
                </span>
              </div>
              <i
                style={closeStyle}
                onClick={() => {
                  this.props.closeModal();
                }}
              >
                <CloseIcon color={darkState ? "#B7A9FF" : "#2800fc"} />
              </i>
            </div>
            <Row width="100%" className="qualityCheckHeader">
              <Col xs={12} className="pb-2">
                <div
                  className="fontRoboto1541"
                  style={{
                    color: "#05284E",
                  }}
                >
                  <div className="dark__span">
                    {userLang?.trip?.TRIP_ID} :{" "}
                    {this.state?.tripQualityId ? this.state?.tripQualityId : ""}
                  </div>
                </div>
              </Col>
              <Col sm="4">
                <h6>
                  <span
                    className={`qualtyTruckTypeText ${
                      darkState ? "dark__span" : ""
                    }`}
                  >
                    {userLang?.invoice?.TRUCK_TYPE}: {truckType.type}
                  </span>{" "}
                </h6>
                <br></br>
              </Col>
            </Row>
            <Grid style={rowsTopSpaceStyle}></Grid>
            <div style={dataBoxStyle}>
              <div style={{ marginRight: "2vw" }}>
                {arr.map((data, index) => (
                  <Grid
                    className="shadow indentData-hover"
                    container
                    style={index % 2 === 0 ? oddRowStyle : evenRowStyle}
                    key={index}
                  >
                    <Grid
                      className=""
                      item
                      xs={7}
                      md={9}
                      style={rowHeaderStyle}
                    >
                      {action(data)}
                    </Grid>
                    <Grid
                      item
                      // xs={1}
                      style={{
                        padding: "2vh 0px",
                        marginLeft: "-20px",
                      }}
                    >
                      <div style={rowSeparaterStyle}></div>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={2}
                      style={rowValueStyle}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <>
                        <ul style={{ marginTop: "1rem", marginLeft: "-13px" }}>
                          <div className="form-check form-check-inline">
                            <Row>
                              <Col xs="12" sm={6}>
                                {data.value === "true" ? (
                                  <>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={data.key}
                                      id={data.key}
                                      value="option1"
                                      defaultChecked
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={data.key}
                                    >
                                      {userLang?.trip?.YES}
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={data.key}
                                      id={data.key}
                                      value="option2"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={data.key}
                                    >
                                      {userLang?.trip?.YES}
                                    </label>
                                  </>
                                )}
                              </Col>
                              <Col xs="12" sm={6}>
                                {data.value === "false" ? (
                                  <>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={data.key}
                                      id={data.key}
                                      value="option1"
                                      defaultChecked
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={data.key}
                                    >
                                      {userLang?.trip?.NO}
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={data.key}
                                      id={data.key}
                                      value="option2"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={data.key}
                                    >
                                      {userLang?.trip?.NO}
                                    </label>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </ul>
                      </>
                    </Grid>
                  </Grid>
                ))}
              </div>
            </div>
            <Grid
              container
              spacing={2}
              className="qualityImageBox ml-2 mt-2 mr-4"
              justifyContent="start"
              alignItems="center"
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                justifyContent="center"
              >
                <Grid item xs={3}>
                  <span className="textHeaderColor">
                    {" "}
                    {userLang?.trip?.QUALITY_CHECK_PHOTO}
                  </span>
                </Grid>
              </Grid>

              {this.state.qualityImage.map((option, index) => (
                <Grid item xs={1} md={2} lg={2} key={index}>
                  <a
                    href={option}
                    target="_blank"
                    download
                    onClick={() => {
                      this.downloadImage([option]);
                    }}
                  >
                    <img
                      src={option}
                      className="q-vector shadow-1-strong rounded mb-4"
                      alt=""
                      id="dbtn"
                    />
                  </a>
                </Grid>
              ))}

              <Grid item xs={6}></Grid>
            </Grid>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    tripById: state.Trip.trips.editTrip,
    locationtripById: state.Trip.trips.locationTrip,
  };
};
export default connect(mapStateToProps, {
  fetchTripById,
  trackLocationTripById,
})(SecondaryQualityCheckData);
